// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Form from "../../bundles/Form/Form.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Group from "../../resources/layout-and-structure/Group.bs.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as $$Request from "../../core/Request.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as AsyncResult from "../../primitives/AsyncResult.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Form__Status from "../../bundles/Form/Form__Status.bs.js";
import * as ApolloHelpers from "../../helpers/ApolloHelpers.bs.js";
import * as Client from "@apollo/client";
import * as SupplierCivility from "../../modules/Supplier/SupplierCivility.bs.js";
import * as FieldsetLayoutPanel from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as ResourceDetailsPage from "../../core/ResourceDetailsPage.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

function stripEmptyString(string) {
  switch (string) {
    case "" :
    case " " :
        return ;
    default:
      return string;
  }
}

function flatMapWithDefault(value, $$default, map) {
  return Belt_Option.getWithDefault(Belt_Option.flatMap(value, map), $$default);
}

function flatMapOrEmptyString(value, map) {
  return flatMapWithDefault(value, "", map);
}

var Raw = {};

var query = Client.gql(["fragment SupplierFragment on Supplier   {\n__typename  \nid  \nupdatedAt  \narchivedAt  \ncompanyName  \nintraCommunityVat  \nphoneNumber  \nmobileNumber  \nsiretNumber  \ninternalCode  \nemail  \nnote  \nshop  {\n__typename  \nid  \n}\n\ncontacts  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nfirstName  \nlastName  \ncivility  \nposition  \nemail  \nphoneNumber  \nmobileNumber  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.archivedAt;
  var value$2 = value.intraCommunityVat;
  var value$3 = value.phoneNumber;
  var value$4 = value.mobileNumber;
  var value$5 = value.siretNumber;
  var value$6 = value.internalCode;
  var value$7 = value.email;
  var value$8 = value.note;
  var value$9 = value.shop;
  var value$10 = value.contacts;
  var value$11 = value$10.edges;
  return {
          __typename: value.__typename,
          id: value.id,
          updatedAt: Scalar.Datetime.parse(value.updatedAt),
          archivedAt: !(value$1 == null) ? Caml_option.some(Scalar.Datetime.parse(value$1)) : undefined,
          companyName: value.companyName,
          intraCommunityVat: !(value$2 == null) ? value$2 : undefined,
          phoneNumber: !(value$3 == null) ? value$3 : undefined,
          mobileNumber: !(value$4 == null) ? value$4 : undefined,
          siretNumber: !(value$5 == null) ? value$5 : undefined,
          internalCode: !(value$6 == null) ? value$6 : undefined,
          email: !(value$7 == null) ? value$7 : undefined,
          note: !(value$8 == null) ? Scalar.$$Text.parse(value$8) : undefined,
          shop: {
            __typename: value$9.__typename,
            id: value$9.id
          },
          contacts: {
            __typename: value$10.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.firstName;
                    var value$3 = value$1.civility;
                    var tmp;
                    if (value$3 == null) {
                      tmp = undefined;
                    } else {
                      var tmp$1;
                      switch (value$3) {
                        case "MR" :
                            tmp$1 = "MR";
                            break;
                        case "MRS" :
                            tmp$1 = "MRS";
                            break;
                        case "NEUTRAL" :
                            tmp$1 = "NEUTRAL";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp = tmp$1;
                    }
                    var value$4 = value$1.position;
                    var value$5 = value$1.email;
                    var value$6 = value$1.phoneNumber;
                    var value$7 = value$1.mobileNumber;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              firstName: !(value$2 == null) ? value$2 : undefined,
                              lastName: value$1.lastName,
                              civility: tmp,
                              position: !(value$4 == null) ? value$4 : undefined,
                              email: !(value$5 == null) ? value$5 : undefined,
                              phoneNumber: !(value$6 == null) ? value$6 : undefined,
                              mobileNumber: !(value$7 == null) ? value$7 : undefined
                            }
                          };
                  }), value$11)
          }
        };
}

function serialize(value) {
  var value$1 = value.contacts;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.mobileNumber;
          var mobileNumber = value$2 !== undefined ? value$2 : null;
          var value$3 = value$1.phoneNumber;
          var phoneNumber = value$3 !== undefined ? value$3 : null;
          var value$4 = value$1.email;
          var email = value$4 !== undefined ? value$4 : null;
          var value$5 = value$1.position;
          var position = value$5 !== undefined ? value$5 : null;
          var value$6 = value$1.civility;
          var civility = value$6 !== undefined ? (
              value$6 === "MR" ? "MR" : (
                  value$6 === "MRS" ? "MRS" : "NEUTRAL"
                )
            ) : null;
          var value$7 = value$1.lastName;
          var value$8 = value$1.firstName;
          var firstName = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.id;
          var value$10 = value$1.__typename;
          var node = {
            __typename: value$10,
            id: value$9,
            firstName: firstName,
            lastName: value$7,
            civility: civility,
            position: position,
            email: email,
            phoneNumber: phoneNumber,
            mobileNumber: mobileNumber
          };
          var value$11 = value.__typename;
          return {
                  __typename: value$11,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var contacts = {
    __typename: value$3,
    edges: edges
  };
  var value$4 = value.shop;
  var value$5 = value$4.id;
  var value$6 = value$4.__typename;
  var shop = {
    __typename: value$6,
    id: value$5
  };
  var value$7 = value.note;
  var note = value$7 !== undefined ? Scalar.$$Text.serialize(value$7) : null;
  var value$8 = value.email;
  var email = value$8 !== undefined ? value$8 : null;
  var value$9 = value.internalCode;
  var internalCode = value$9 !== undefined ? value$9 : null;
  var value$10 = value.siretNumber;
  var siretNumber = value$10 !== undefined ? value$10 : null;
  var value$11 = value.mobileNumber;
  var mobileNumber = value$11 !== undefined ? value$11 : null;
  var value$12 = value.phoneNumber;
  var phoneNumber = value$12 !== undefined ? value$12 : null;
  var value$13 = value.intraCommunityVat;
  var intraCommunityVat = value$13 !== undefined ? value$13 : null;
  var value$14 = value.companyName;
  var value$15 = value.archivedAt;
  var archivedAt = value$15 !== undefined ? Scalar.Datetime.serialize(Caml_option.valFromOption(value$15)) : null;
  var value$16 = value.updatedAt;
  var value$17 = Scalar.Datetime.serialize(value$16);
  var value$18 = value.id;
  var value$19 = value.__typename;
  return {
          __typename: value$19,
          id: value$18,
          updatedAt: value$17,
          archivedAt: archivedAt,
          companyName: value$14,
          intraCommunityVat: intraCommunityVat,
          phoneNumber: phoneNumber,
          mobileNumber: mobileNumber,
          siretNumber: siretNumber,
          internalCode: internalCode,
          email: email,
          note: note,
          shop: shop,
          contacts: contacts
        };
}

function verifyArgsAndParse(_SupplierFragment, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var SupplierFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = Client.gql([
      "query SupplierQuery($id: ID!)  {\nsupplier(id: $id)  {\n...SupplierFragment   \n}\n\n}\n",
      ""
    ], query);

function parse$1(value) {
  var value$1 = value.supplier;
  return {
          supplier: !(value$1 == null) ? parse(value$1) : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.supplier;
  var supplier = value$1 !== undefined ? serialize(value$1) : null;
  return {
          supplier: supplier
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var SupplierQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables
    });

var use = include.use;

var SupplierQuery_refetchQueryDescription = include.refetchQueryDescription;

var SupplierQuery_useLazy = include.useLazy;

var SupplierQuery_useLazyWithVariables = include.useLazyWithVariables;

var SupplierQuery = {
  SupplierQuery_inner: SupplierQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: SupplierQuery_refetchQueryDescription,
  use: use,
  useLazy: SupplierQuery_useLazy,
  useLazyWithVariables: SupplierQuery_useLazyWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql([
      "mutation updateSupplier($id: ID!, $supplierInput: InputUpdateSupplier!, $contactsInput: [InputUpdateOrCreateSupplierContact!])  {\nupdateSupplier(id: $id, supplierInput: $supplierInput, contactsInput: $contactsInput)  {\n...SupplierFragment   \n}\n\n}\n",
      ""
    ], query);

function parse$2(value) {
  return {
          updateSupplier: parse(value.updateSupplier)
        };
}

function serialize$2(value) {
  var value$1 = value.updateSupplier;
  var updateSupplier = serialize(value$1);
  return {
          updateSupplier: updateSupplier
        };
}

function serializeInputObjectInputUpdateSupplier(inp) {
  var a = inp.companyName;
  var a$1 = inp.intraCommunityVat;
  var a$2 = inp.siretNumber;
  var a$3 = inp.phoneNumber;
  var a$4 = inp.mobileNumber;
  var a$5 = inp.email;
  var a$6 = inp.note;
  var a$7 = inp.internalCode;
  return {
          companyName: a !== undefined ? a : undefined,
          intraCommunityVat: a$1 !== undefined ? a$1 : undefined,
          siretNumber: a$2 !== undefined ? a$2 : undefined,
          phoneNumber: a$3 !== undefined ? a$3 : undefined,
          mobileNumber: a$4 !== undefined ? a$4 : undefined,
          email: a$5 !== undefined ? a$5 : undefined,
          note: a$6 !== undefined ? a$6 : undefined,
          internalCode: a$7 !== undefined ? a$7 : undefined
        };
}

function serializeInputObjectInputUpdateOrCreateSupplierContact(inp) {
  var a = inp.id;
  var a$1 = inp.firstName;
  var a$2 = inp.position;
  var a$3 = inp.email;
  var a$4 = inp.phoneNumber;
  var a$5 = inp.mobileNumber;
  var a$6 = inp.civility;
  return {
          id: a !== undefined ? a : undefined,
          lastName: inp.lastName,
          firstName: a$1 !== undefined ? a$1 : undefined,
          position: a$2 !== undefined ? a$2 : undefined,
          email: a$3 !== undefined ? a$3 : undefined,
          phoneNumber: a$4 !== undefined ? a$4 : undefined,
          mobileNumber: a$5 !== undefined ? a$5 : undefined,
          isDefault: inp.isDefault,
          civility: a$6 !== undefined ? (
              a$6 === "MR" ? "MR" : (
                  a$6 === "MRS" ? "MRS" : "NEUTRAL"
                )
            ) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.contactsInput;
  return {
          id: inp.id,
          supplierInput: serializeInputObjectInputUpdateSupplier(inp.supplierInput),
          contactsInput: a !== undefined ? Js_array.map(serializeInputObjectInputUpdateOrCreateSupplierContact, a) : undefined
        };
}

function makeVariables$1(id, supplierInput, contactsInput, param) {
  return {
          id: id,
          supplierInput: supplierInput,
          contactsInput: contactsInput
        };
}

function makeInputObjectInputUpdateSupplier(companyName, intraCommunityVat, siretNumber, phoneNumber, mobileNumber, email, note, internalCode, param) {
  return {
          companyName: companyName,
          intraCommunityVat: intraCommunityVat,
          siretNumber: siretNumber,
          phoneNumber: phoneNumber,
          mobileNumber: mobileNumber,
          email: email,
          note: note,
          internalCode: internalCode
        };
}

function makeInputObjectInputUpdateOrCreateSupplierContact(id, lastName, firstName, position, email, phoneNumber, mobileNumber, isDefault, civility, param) {
  return {
          id: id,
          lastName: lastName,
          firstName: firstName,
          position: position,
          email: email,
          phoneNumber: phoneNumber,
          mobileNumber: mobileNumber,
          isDefault: isDefault,
          civility: civility
        };
}

var SupplierUpdateMutation_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateSupplier: serializeInputObjectInputUpdateSupplier,
  serializeInputObjectInputUpdateOrCreateSupplierContact: serializeInputObjectInputUpdateOrCreateSupplierContact,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateSupplier: makeInputObjectInputUpdateSupplier,
  makeInputObjectInputUpdateOrCreateSupplierContact: makeInputObjectInputUpdateOrCreateSupplierContact
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var SupplierUpdateMutation_useWithVariables = include$1.useWithVariables;

var SupplierUpdateMutation = {
  SupplierUpdateMutation_inner: SupplierUpdateMutation_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateSupplier: serializeInputObjectInputUpdateSupplier,
  serializeInputObjectInputUpdateOrCreateSupplierContact: serializeInputObjectInputUpdateOrCreateSupplierContact,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateSupplier: makeInputObjectInputUpdateSupplier,
  makeInputObjectInputUpdateOrCreateSupplierContact: makeInputObjectInputUpdateOrCreateSupplierContact,
  use: use$1,
  useWithVariables: SupplierUpdateMutation_useWithVariables
};

function get(values, field) {
  switch (field) {
    case /* ContactId */0 :
        return values.contactId;
    case /* ContactFirstName */1 :
        return values.contactFirstName;
    case /* ContactLastName */2 :
        return values.contactLastName;
    case /* ContactCivility */3 :
        return values.contactCivility;
    case /* ContactPosition */4 :
        return values.contactPosition;
    case /* ContactEmail */5 :
        return values.contactEmail;
    case /* ContactPhoneNumber */6 :
        return values.contactPhoneNumber;
    case /* ContactMobileNumber */7 :
        return values.contactMobileNumber;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* ContactId */0 :
        return {
                contactId: value,
                contactFirstName: values.contactFirstName,
                contactLastName: values.contactLastName,
                contactCivility: values.contactCivility,
                contactPosition: values.contactPosition,
                contactEmail: values.contactEmail,
                contactPhoneNumber: values.contactPhoneNumber,
                contactMobileNumber: values.contactMobileNumber
              };
    case /* ContactFirstName */1 :
        return {
                contactId: values.contactId,
                contactFirstName: value,
                contactLastName: values.contactLastName,
                contactCivility: values.contactCivility,
                contactPosition: values.contactPosition,
                contactEmail: values.contactEmail,
                contactPhoneNumber: values.contactPhoneNumber,
                contactMobileNumber: values.contactMobileNumber
              };
    case /* ContactLastName */2 :
        return {
                contactId: values.contactId,
                contactFirstName: values.contactFirstName,
                contactLastName: value,
                contactCivility: values.contactCivility,
                contactPosition: values.contactPosition,
                contactEmail: values.contactEmail,
                contactPhoneNumber: values.contactPhoneNumber,
                contactMobileNumber: values.contactMobileNumber
              };
    case /* ContactCivility */3 :
        return {
                contactId: values.contactId,
                contactFirstName: values.contactFirstName,
                contactLastName: values.contactLastName,
                contactCivility: value,
                contactPosition: values.contactPosition,
                contactEmail: values.contactEmail,
                contactPhoneNumber: values.contactPhoneNumber,
                contactMobileNumber: values.contactMobileNumber
              };
    case /* ContactPosition */4 :
        return {
                contactId: values.contactId,
                contactFirstName: values.contactFirstName,
                contactLastName: values.contactLastName,
                contactCivility: values.contactCivility,
                contactPosition: value,
                contactEmail: values.contactEmail,
                contactPhoneNumber: values.contactPhoneNumber,
                contactMobileNumber: values.contactMobileNumber
              };
    case /* ContactEmail */5 :
        return {
                contactId: values.contactId,
                contactFirstName: values.contactFirstName,
                contactLastName: values.contactLastName,
                contactCivility: values.contactCivility,
                contactPosition: values.contactPosition,
                contactEmail: value,
                contactPhoneNumber: values.contactPhoneNumber,
                contactMobileNumber: values.contactMobileNumber
              };
    case /* ContactPhoneNumber */6 :
        return {
                contactId: values.contactId,
                contactFirstName: values.contactFirstName,
                contactLastName: values.contactLastName,
                contactCivility: values.contactCivility,
                contactPosition: values.contactPosition,
                contactEmail: values.contactEmail,
                contactPhoneNumber: value,
                contactMobileNumber: values.contactMobileNumber
              };
    case /* ContactMobileNumber */7 :
        return {
                contactId: values.contactId,
                contactFirstName: values.contactFirstName,
                contactLastName: values.contactLastName,
                contactCivility: values.contactCivility,
                contactPosition: values.contactPosition,
                contactEmail: values.contactEmail,
                contactPhoneNumber: values.contactPhoneNumber,
                contactMobileNumber: value
              };
    
  }
}

var SupplierEditFormLenses = {
  get: get,
  set: set
};

var SupplierEditForm = Form.Make(SupplierEditFormLenses);

var supplierEditFormInitialValues = {
  contactId: "",
  contactFirstName: "",
  contactLastName: "",
  contactCivility: "NEUTRAL",
  contactPosition: "",
  contactEmail: "",
  contactPhoneNumber: "",
  contactMobileNumber: ""
};

var supplierEditFormSchema = [{
    TAG: /* StringNotEmpty */1,
    _0: /* ContactLastName */2
  }];

function supplierEditFormInitialValuesFromQueryResult(queryResult) {
  var firstContact = Belt_Option.map(Belt_Array.get(queryResult.contacts.edges, 0), (function (param) {
          return param.node;
        }));
  return {
          contactId: Belt_Option.mapWithDefault(firstContact, "", (function (param) {
                  return param.id;
                })),
          contactFirstName: flatMapWithDefault(firstContact, "", (function (param) {
                  return param.firstName;
                })),
          contactLastName: Belt_Option.mapWithDefault(firstContact, "", (function (param) {
                  return param.lastName;
                })),
          contactCivility: flatMapWithDefault(firstContact, "NEUTRAL", (function (param) {
                  return param.civility;
                })),
          contactPosition: flatMapWithDefault(firstContact, "", (function (param) {
                  return param.position;
                })),
          contactEmail: flatMapWithDefault(firstContact, "", (function (param) {
                  return param.email;
                })),
          contactPhoneNumber: flatMapWithDefault(firstContact, "", (function (param) {
                  return param.phoneNumber;
                })),
          contactMobileNumber: flatMapWithDefault(firstContact, "", (function (param) {
                  return param.mobileNumber;
                }))
        };
}

function supplierUpdateMutationVariablesFromFormValuesAndQueryResult(formValues, queryResult) {
  return {
          id: queryResult.id,
          supplierInput: makeInputObjectInputUpdateSupplier(queryResult.companyName, queryResult.intraCommunityVat, queryResult.siretNumber, queryResult.phoneNumber, queryResult.mobileNumber, queryResult.email, queryResult.note, queryResult.internalCode, undefined),
          contactsInput: [makeInputObjectInputUpdateOrCreateSupplierContact(stripEmptyString(formValues.contactId), formValues.contactLastName, stripEmptyString(formValues.contactFirstName), stripEmptyString(formValues.contactPosition), stripEmptyString(formValues.contactEmail), stripEmptyString(formValues.contactPhoneNumber), stripEmptyString(formValues.contactMobileNumber), true, formValues.contactCivility, undefined)]
        };
}

function SupplierEditContactPage$SupplierEditFormContactFieldset(Props) {
  var civilityFieldItems = Belt_Array.map(SupplierCivility.options, (function (civility) {
          return {
                  key: SupplierCivility.toLongLabel(civility),
                  value: civility,
                  label: SupplierCivility.toLongLabel(civility)
                };
        }));
  var civilityFieldSections = [{
      items: civilityFieldItems
    }];
  return React.createElement(FieldsetLayoutPanel.make, {
              title: $$Intl.t("Company contact"),
              description: $$Intl.t("Specify the coordinates of your point of contact within the company to communicate effectively."),
              children: null
            }, React.createElement(Group.make, {
                  children: null
                }, React.createElement(SupplierEditForm.InputText.make, {
                      field: /* ContactLastName */2,
                      label: $$Intl.t("Last name"),
                      placeholder: "Dubois",
                      hideRequired: true
                    }), React.createElement(SupplierEditForm.InputText.make, {
                      field: /* ContactFirstName */1,
                      label: $$Intl.t("First name"),
                      placeholder: "Jean"
                    })), React.createElement(Group.make, {
                  children: null
                }, React.createElement(SupplierEditForm.InputSelect.make, {
                      field: /* ContactCivility */3,
                      label: $$Intl.t("Civility"),
                      sections: civilityFieldSections
                    }), React.createElement(SupplierEditForm.InputText.make, {
                      field: /* ContactPosition */4,
                      label: $$Intl.t("Employment"),
                      placeholder: "Commercial"
                    })), React.createElement(SupplierEditForm.InputText.make, {
                  field: /* ContactEmail */5,
                  label: $$Intl.t("Email"),
                  placeholder: $$Intl.t("mikehasford@company.com")
                }), React.createElement(Group.make, {
                  children: null
                }, React.createElement(SupplierEditForm.InputPhone.make, {
                      label: $$Intl.t("Home phone"),
                      field: /* ContactPhoneNumber */6,
                      placeholder: "02 11 22 33 44"
                    }), React.createElement(SupplierEditForm.InputPhone.make, {
                      label: $$Intl.t("Mobile phone"),
                      field: /* ContactMobileNumber */7,
                      placeholder: "06 11 22 33 44"
                    })));
}

var SupplierEditFormContactFieldset = {
  make: SupplierEditContactPage$SupplierEditFormContactFieldset
};

function SupplierEditContactPage$SuppplierEditFormActionsBar(Props) {
  var queryResult = Props.queryResult;
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  var formState = Curry._1(SupplierEditForm.useFormState, undefined);
  var match$1 = Navigation.useGoBack(undefined);
  var onGoBack = match$1[1];
  return React.createElement(ResourceDetailsPage.ActionsBar.make, {
              items: [
                Form__Status.isPristine(formState.status) && match$1[0] ? React.createElement(Button.make, Button.makeProps($$Intl.t("Discard"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                              Curry._1(onGoBack, undefined);
                            }), undefined, undefined, undefined)) : React.createElement(SupplierEditForm.CancelButton.make, {
                        text: $$Intl.t("Discard"),
                        size: "medium"
                      }),
                React.createElement(SupplierEditForm.SubmitButton.make, {
                      variation: "success",
                      size: "medium",
                      onSubmit: (function (param, values) {
                          return Future.mapOk(ApolloHelpers.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, supplierUpdateMutationVariablesFromFormValuesAndQueryResult(values, queryResult))), undefined, (function (param) {
                                        return param.updateSupplier.id;
                                      }));
                        }),
                      text: $$Intl.t("Save")
                    })
              ]
            });
}

var SuppplierEditFormActionsBar = {
  make: SupplierEditContactPage$SuppplierEditFormActionsBar
};

function SupplierEditContactPage(Props) {
  var id = Props.id;
  var supplierShowRoute = Props.supplierShowRoute;
  var navigate = Navigation.useNavigate(undefined);
  var asyncQueryResult = AsyncResult.map(ApolloHelpers.queryResultToAsyncResult(Curry.app(use, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                {
                  id: id
                }
              ])), (function (result) {
          if (result.TAG !== /* Ok */0) {
            return {
                    TAG: /* Error */1,
                    _0: undefined
                  };
          }
          var supplier = result._0.supplier;
          if (supplier !== undefined) {
            return {
                    TAG: /* Ok */0,
                    _0: supplier
                  };
          } else {
            return {
                    TAG: /* Error */1,
                    _0: undefined
                  };
          }
        }));
  var match = React.useState(function () {
        
      });
  var setNotificationError = match[1];
  var notificationError = match[0];
  var onSubmitFailure = function (error) {
    Curry._1(setNotificationError, (function (param) {
            return error;
          }));
  };
  var onSubmitSuccess = function (response) {
    if (response !== undefined) {
      return Curry._3(navigate, true, undefined, Curry._1(supplierShowRoute, response));
    } else {
      return Curry._1(setNotificationError, (function (param) {
                    return $$Request.serverErrorMessage;
                  }));
    }
  };
  var formId;
  if (typeof asyncQueryResult === "number") {
    formId = undefined;
  } else if (asyncQueryResult.TAG === /* Reloading */0) {
    var queryResult = asyncQueryResult._0;
    formId = queryResult.TAG === /* Ok */0 ? queryResult._0.id : undefined;
  } else {
    var queryResult$1 = asyncQueryResult._0;
    formId = queryResult$1.TAG === /* Ok */0 ? queryResult$1._0.id : undefined;
  }
  var formInitialValues;
  if (typeof asyncQueryResult === "number") {
    formInitialValues = supplierEditFormInitialValues;
  } else if (asyncQueryResult.TAG === /* Reloading */0) {
    var queryResult$2 = asyncQueryResult._0;
    formInitialValues = queryResult$2.TAG === /* Ok */0 ? supplierEditFormInitialValuesFromQueryResult(queryResult$2._0) : supplierEditFormInitialValues;
  } else {
    var queryResult$3 = asyncQueryResult._0;
    formInitialValues = queryResult$3.TAG === /* Ok */0 ? supplierEditFormInitialValuesFromQueryResult(queryResult$3._0) : supplierEditFormInitialValues;
  }
  var formPropState = Curry._1(SupplierEditForm.useFormPropState, {
        id: formId,
        initialValues: formInitialValues,
        schema: supplierEditFormSchema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var notificationBanner;
  if (notificationError !== undefined) {
    var onRequestClose = function (param) {
      Curry._1(setNotificationError, (function (param) {
              
            }));
    };
    notificationBanner = React.createElement(ResourceDetailsPage.NotificationBanner.make, {
          value: {
            TAG: /* Error */1,
            _0: notificationError
          },
          onRequestClose: onRequestClose
        });
  } else {
    notificationBanner = null;
  }
  if (typeof asyncQueryResult !== "number" && asyncQueryResult.TAG === /* Done */1) {
    var queryResult$4 = asyncQueryResult._0;
    if (queryResult$4.TAG !== /* Ok */0) {
      return React.createElement(Placeholder.make, {
                  status: /* Error */2
                });
    }
    var queryResult$5 = queryResult$4._0;
    var actionsBar = React.createElement(SupplierEditContactPage$SuppplierEditFormActionsBar, {
          queryResult: queryResult$5
        });
    return React.createElement(SupplierEditForm.Core.Provider.make, {
                children: React.createElement(ResourceDetailsPage.make, {
                      title: $$Intl.t("Edition supplier contact"),
                      subtitle: queryResult$5.companyName,
                      actionsBar: actionsBar,
                      notificationBanner: notificationBanner,
                      children: React.createElement(SupplierEditContactPage$SupplierEditFormContactFieldset, {})
                    }),
                propState: formPropState
              });
  }
  return React.createElement(Placeholder.make, {
              status: /* Loading */0
            });
}

var make = SupplierEditContactPage;

export {
  stripEmptyString ,
  flatMapWithDefault ,
  flatMapOrEmptyString ,
  SupplierFragment ,
  SupplierQuery ,
  SupplierUpdateMutation ,
  SupplierEditFormLenses ,
  SupplierEditForm ,
  supplierEditFormInitialValues ,
  supplierEditFormSchema ,
  supplierEditFormInitialValuesFromQueryResult ,
  supplierUpdateMutationVariablesFromFormValuesAndQueryResult ,
  SupplierEditFormContactFieldset ,
  SuppplierEditFormActionsBar ,
  make ,
}
/* query Not a pure module */
