// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CatalogCategoryPicker from "./CatalogCategoryPicker.bs.js";

function keyExtractor(row) {
  return row.shopId;
}

var Row = {
  keyExtractor: keyExtractor
};

function tableColumns(everyShopsDuplicable, hideCategory, onRequestCategoryUpdate) {
  return [
          {
            key: "shop-catalog",
            name: $$Intl.t("Shop catalog"),
            render: (function (param) {
                return React.createElement(TextStyle.make, {
                            children: param.data.shopName,
                            variation: param.disabled ? "subdued" : "neutral"
                          });
              })
          },
          {
            key: "product-category",
            name: $$Intl.t("Category"),
            layout: {
              hidden: hideCategory
            },
            render: (function (param) {
                var match = param.data;
                var categoryName = match.categoryName;
                var shopId = match.shopId;
                var onChange = function (category) {
                  if (!category) {
                    return Curry._1(onRequestCategoryUpdate, {
                                id: undefined,
                                name: $$Intl.t("Not classified"),
                                shopId: shopId
                              });
                  }
                  var match = category._0;
                  Curry._1(onRequestCategoryUpdate, {
                        id: match.id,
                        name: match.formattedName,
                        shopId: shopId
                      });
                };
                if (param.disabled) {
                  return React.createElement(TextStyle.make, {
                              children: Belt_Option.getWithDefault(categoryName, "-"),
                              variation: "subdued"
                            });
                } else {
                  return React.createElement(CatalogCategoryPicker.make, {
                              variation: Belt_Option.isSome(categoryName) ? "important" : "neutral",
                              value: Belt_Option.getWithDefault(categoryName, $$Intl.t("Add a category")),
                              shopId: shopId,
                              onChange: onChange
                            });
                }
              })
          },
          {
            key: "more-information",
            name: $$Intl.t("Additional information"),
            layout: {
              hidden: everyShopsDuplicable
            },
            render: (function (param) {
                var note = param.data.note;
                if (note !== undefined) {
                  return React.createElement(TextStyle.make, {
                              children: note,
                              variation: param.disabled ? "subdued" : "neutral"
                            });
                } else {
                  return null;
                }
              })
          }
        ];
}

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9, prim10, prim11, prim12, prim13, prim14, prim15, prim16, prim17, prim18, prim19, prim20, prim21, prim22, prim23, prim24, prim25, prim26, prim27) {
  var tmp = {
    data: prim0,
    columns: prim1,
    keyExtractor: prim2
  };
  if (prim3 !== undefined) {
    tmp.disabledRowsKeys = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.erroredRowsMap = Caml_option.valFromOption(prim4);
  }
  if (prim5 !== undefined) {
    tmp.selectionEnabled = prim5;
  }
  if (prim6 !== undefined) {
    tmp.selectAllEnabled = prim6;
  }
  if (prim7 !== undefined) {
    tmp.initialAllSelected = prim7;
  }
  if (prim8 !== undefined) {
    tmp.hideCard = prim8;
  }
  if (prim9 !== undefined) {
    tmp.hideReloadingPlaceholder = prim9;
  }
  if (prim10 !== undefined) {
    tmp.maxWidth = prim10;
  }
  if (prim11 !== undefined) {
    tmp.minHeight = prim11;
  }
  if (prim12 !== undefined) {
    tmp.maxHeight = prim12;
  }
  if (prim13 !== undefined) {
    tmp.compactRows = prim13;
  }
  if (prim14 !== undefined) {
    tmp.placeholderEmptyState = Caml_option.valFromOption(prim14);
  }
  if (prim15 !== undefined) {
    tmp.searchBar = Caml_option.valFromOption(prim15);
  }
  if (prim16 !== undefined) {
    tmp.searchPlaceholder = prim16;
  }
  if (prim17 !== undefined) {
    tmp.filters = Caml_option.valFromOption(prim17);
  }
  if (prim18 !== undefined) {
    tmp.typesDrop = Caml_option.valFromOption(prim18);
  }
  if (prim19 !== undefined) {
    tmp.sortDescriptor = Caml_option.valFromOption(prim19);
  }
  if (prim20 !== undefined) {
    tmp.onSortChange = Caml_option.valFromOption(prim20);
  }
  if (prim21 !== undefined) {
    tmp.onSearchQueryChange = Caml_option.valFromOption(prim21);
  }
  if (prim22 !== undefined) {
    tmp.onLoadMore = Caml_option.valFromOption(prim22);
  }
  if (prim23 !== undefined) {
    tmp.onSelectChange = Caml_option.valFromOption(prim23);
  }
  if (prim24 !== undefined) {
    tmp.onSuccessDrop = Caml_option.valFromOption(prim24);
  }
  if (prim25 !== undefined) {
    tmp.onErrorDrop = Caml_option.valFromOption(prim25);
  }
  if (prim26 !== undefined) {
    tmp.key = prim26;
  }
  return tmp;
}

var make = TableView.make;

export {
  Row ,
  tableColumns ,
  make ,
  makeProps ,
}
/* Intl Not a pure module */
