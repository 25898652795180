// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as InputDateField from "../../resources/selection-and-input/InputDateField.bs.js";
import * as InputTextField from "../../resources/selection-and-input/InputTextField.bs.js";
import * as InputNumberField from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as InputSelectField from "../../resources/selection-and-input/InputSelectField.bs.js";
import * as InputCheckboxField from "../../resources/selection-and-input/InputCheckboxField.bs.js";
import * as InputPasswordField from "../../resources/selection-and-input/InputPasswordField.bs.js";
import * as InputTextAreaField from "../../resources/selection-and-input/InputTextAreaField.bs.js";
import * as InputDateRangeField from "../../resources/selection-and-input/InputDateRangeField.bs.js";
import * as InputRadioGroupField from "../../resources/selection-and-input/InputRadioGroupField.bs.js";
import * as InputToggleSwitchField from "../../resources/selection-and-input/InputToggleSwitchField.bs.js";
import * as InputSegmentedControlsField from "../../resources/selection-and-input/InputSegmentedControlsField.bs.js";
import * as InputSuggestionComboBoxField from "../../resources/selection-and-input/InputSuggestionComboBoxField.bs.js";

function Make(Params) {
  var useField = Params.useField;
  var Form__Elements__Inputs$Make$InputText = function (Props) {
    var field = Props.field;
    var label = Props.label;
    var placeholder = Props.placeholder;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var autoTrimOpt = Props.autoTrim;
    var onInputFocusOpt = Props.onFocus;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var autoTrim = autoTrimOpt !== undefined ? autoTrimOpt : true;
    var onInputFocus = onInputFocusOpt !== undefined ? onInputFocusOpt : (function (param) {
          
        });
    var props = Curry._5(useField, field, hideError, hideRequired, undefined, undefined);
    var onFocus = props.onFocus;
    var tmp = {
      label: label,
      required: props.required,
      focused: props.focused,
      autoTrim: autoTrim,
      value: props.value,
      onChange: props.onChange,
      onFocus: (function (param) {
          Curry._1(onFocus, undefined);
          Curry._1(onInputFocus, undefined);
        }),
      onBlur: props.onBlur
    };
    if (placeholder !== undefined) {
      tmp.placeholder = placeholder;
    }
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    return React.createElement(InputTextField.make, tmp);
  };
  var InputText = {
    make: Form__Elements__Inputs$Make$InputText
  };
  var Form__Elements__Inputs$Make$InputSuggestionComboBox = function (Props) {
    var field = Props.field;
    var label = Props.label;
    var placeholder = Props.placeholder;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var items = Props.items;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, undefined, undefined);
    var tmp = {
      label: label,
      required: props.required,
      focused: props.focused,
      items: items,
      value: props.value,
      onChange: props.onChange,
      onFocus: props.onFocus,
      onBlur: props.onBlur
    };
    if (placeholder !== undefined) {
      tmp.placeholder = placeholder;
    }
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    return React.createElement(InputSuggestionComboBoxField.make, tmp);
  };
  var InputSuggestionComboBox = {
    make: Form__Elements__Inputs$Make$InputSuggestionComboBox
  };
  var Form__Elements__Inputs$Make$InputSelect = function (Props) {
    var field = Props.field;
    var label = Props.label;
    var placeholder = Props.placeholder;
    var disabled = Props.disabled;
    var infotip = Props.infotip;
    var searchable = Props.searchable;
    var renderItemContent = Props.renderItemContent;
    var sections = Props.sections;
    var renderTriggerView = Props.renderTriggerView;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, true, undefined);
    var tmp = {
      label: label,
      required: props.required,
      onChange: props.onChange,
      value: props.value,
      sections: sections
    };
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    if (placeholder !== undefined) {
      tmp.placeholder = placeholder;
    }
    if (disabled !== undefined) {
      tmp.disabled = disabled;
    }
    if (infotip !== undefined) {
      tmp.infotip = infotip;
    }
    if (searchable !== undefined) {
      tmp.searchable = searchable;
    }
    if (renderItemContent !== undefined) {
      tmp.renderItemContent = Caml_option.valFromOption(renderItemContent);
    }
    if (renderTriggerView !== undefined) {
      tmp.renderTriggerView = Caml_option.valFromOption(renderTriggerView);
    }
    return React.createElement(InputSelectField.make, tmp);
  };
  var InputSelect = {
    make: Form__Elements__Inputs$Make$InputSelect
  };
  var Form__Elements__Inputs$Make$InputTextArea = function (Props) {
    var field = Props.field;
    var label = Props.label;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, undefined, undefined);
    var tmp = {
      label: label,
      focused: props.focused,
      required: props.required,
      value: props.value,
      onChange: props.onChange,
      onFocus: props.onFocus,
      onBlur: props.onBlur
    };
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    return React.createElement(InputTextAreaField.make, tmp);
  };
  var InputTextArea = {
    make: Form__Elements__Inputs$Make$InputTextArea
  };
  var Form__Elements__Inputs$Make$InputPassword = function (Props) {
    var field = Props.field;
    var label = Props.label;
    var placeholder = Props.placeholder;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var showTypingValidation = Props.showTypingValidation;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, undefined, undefined);
    var tmp = {
      label: label,
      value: props.value,
      focused: props.focused,
      required: props.required,
      onChange: props.onChange,
      onFocus: props.onFocus,
      onBlur: props.onBlur
    };
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    if (placeholder !== undefined) {
      tmp.placeholder = placeholder;
    }
    if (showTypingValidation !== undefined) {
      tmp.showTypingValidation = showTypingValidation;
    }
    return React.createElement(InputPasswordField.make, tmp);
  };
  var InputPassword = {
    make: Form__Elements__Inputs$Make$InputPassword
  };
  var Form__Elements__Inputs$Make$InputNumber = function (Props) {
    var field = Props.field;
    var label = Props.label;
    var infotip = Props.infotip;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var hideStepperOpt = Props.hideStepper;
    var disabledOpt = Props.disabled;
    var placeholder = Props.placeholder;
    var useGroupingOpt = Props.useGrouping;
    var appender = Props.appender;
    var minValue = Props.minValue;
    var maxValue = Props.maxValue;
    var step = Props.step;
    var precisionOpt = Props.precision;
    var shrinkInputOpt = Props.shrinkInput;
    var autoFocusedOpt = Props.autoFocused;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var hideStepper = hideStepperOpt !== undefined ? hideStepperOpt : false;
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var useGrouping = useGroupingOpt !== undefined ? useGroupingOpt : true;
    var precision = precisionOpt !== undefined ? precisionOpt : 2;
    var shrinkInput = shrinkInputOpt !== undefined ? shrinkInputOpt : false;
    var autoFocused = autoFocusedOpt !== undefined ? autoFocusedOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, undefined, undefined);
    var tmp = {
      value: props.value,
      precision: precision,
      shrinkInput: shrinkInput,
      autoFocused: autoFocused,
      focused: props.focused,
      required: props.required,
      disabled: disabled,
      hideStepper: hideStepper,
      useGrouping: useGrouping,
      onChange: props.onChange,
      onFocus: props.onFocus,
      onBlur: props.onBlur
    };
    if (minValue !== undefined) {
      tmp.minValue = minValue;
    }
    if (maxValue !== undefined) {
      tmp.maxValue = maxValue;
    }
    if (step !== undefined) {
      tmp.step = step;
    }
    if (label !== undefined) {
      tmp.label = label;
    }
    if (infotip !== undefined) {
      tmp.infotip = infotip;
    }
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    if (placeholder !== undefined) {
      tmp.placeholder = placeholder;
    }
    if (appender !== undefined) {
      tmp.appender = Caml_option.valFromOption(appender);
    }
    return React.createElement(InputNumberField.make, tmp);
  };
  var InputNumber = {
    make: Form__Elements__Inputs$Make$InputNumber
  };
  var Form__Elements__Inputs$Make$InputOptionalNumber = function (Props) {
    var field = Props.field;
    var label = Props.label;
    var infotip = Props.infotip;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var hideStepperOpt = Props.hideStepper;
    var disabledOpt = Props.disabled;
    var placeholder = Props.placeholder;
    var useGroupingOpt = Props.useGrouping;
    var appender = Props.appender;
    var minValue = Props.minValue;
    var maxValue = Props.maxValue;
    var step = Props.step;
    var precisionOpt = Props.precision;
    var shrinkInputOpt = Props.shrinkInput;
    var autoFocusedOpt = Props.autoFocused;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var hideStepper = hideStepperOpt !== undefined ? hideStepperOpt : false;
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var useGrouping = useGroupingOpt !== undefined ? useGroupingOpt : true;
    var precision = precisionOpt !== undefined ? precisionOpt : 2;
    var shrinkInput = shrinkInputOpt !== undefined ? shrinkInputOpt : false;
    var autoFocused = autoFocusedOpt !== undefined ? autoFocusedOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, undefined, undefined);
    var tmp = {
      value: props.value,
      precision: precision,
      shrinkInput: shrinkInput,
      autoFocused: autoFocused,
      focused: props.focused,
      required: props.required,
      disabled: disabled,
      hideStepper: hideStepper,
      useGrouping: useGrouping,
      onChange: props.onChange,
      onFocus: props.onFocus,
      onBlur: props.onBlur
    };
    if (minValue !== undefined) {
      tmp.minValue = minValue;
    }
    if (maxValue !== undefined) {
      tmp.maxValue = maxValue;
    }
    if (step !== undefined) {
      tmp.step = step;
    }
    if (label !== undefined) {
      tmp.label = label;
    }
    if (infotip !== undefined) {
      tmp.infotip = infotip;
    }
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    if (placeholder !== undefined) {
      tmp.placeholder = placeholder;
    }
    if (appender !== undefined) {
      tmp.appender = Caml_option.valFromOption(appender);
    }
    return React.createElement(InputNumberField.OptionalValue.make, tmp);
  };
  var InputOptionalNumber = {
    make: Form__Elements__Inputs$Make$InputOptionalNumber
  };
  var Form__Elements__Inputs$Make$InputSegmentedControls = function (Props) {
    var field = Props.field;
    var label = Props.label;
    var options = Props.options;
    var optionToText = Props.optionToText;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, true, undefined);
    var tmp = {
      label: label,
      value: props.value,
      required: props.required,
      onChange: props.onChange,
      options: options,
      optionToText: optionToText
    };
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    return React.createElement(InputSegmentedControlsField.make, tmp);
  };
  var InputSegmentedControls = {
    make: Form__Elements__Inputs$Make$InputSegmentedControls
  };
  var Form__Elements__Inputs$Make$InputRadioGroup = function (Props) {
    var label = Props.label;
    var field = Props.field;
    var options = Props.options;
    var optionToText = Props.optionToText;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, true, undefined);
    var tmp = {
      label: label,
      value: props.value,
      required: props.required,
      onChange: props.onChange,
      options: options,
      optionToText: optionToText
    };
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    return React.createElement(InputRadioGroupField.make, tmp);
  };
  var InputRadioGroup = {
    make: Form__Elements__Inputs$Make$InputRadioGroup
  };
  var Form__Elements__Inputs$Make$InputPhone = function (Props) {
    var label = Props.label;
    var field = Props.field;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var placeholder = Props.placeholder;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, undefined, undefined);
    var tmp = {
      label: label,
      required: props.required,
      focused: props.focused,
      value: $$Intl.phoneNumberFormat(props.value),
      onChange: props.onChange,
      onFocus: props.onFocus,
      onBlur: props.onBlur
    };
    if (placeholder !== undefined) {
      tmp.placeholder = placeholder;
    }
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    return React.createElement(InputTextField.make, tmp);
  };
  var InputPhone = {
    make: Form__Elements__Inputs$Make$InputPhone
  };
  var Form__Elements__Inputs$Make$InputCheckbox = function (Props) {
    var label = Props.label;
    var text = Props.text;
    var field = Props.field;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var disabledOpt = Props.disabled;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, true, undefined);
    var tmp = {
      label: label,
      text: text,
      required: props.required,
      value: props.value,
      disabled: disabled,
      onChange: props.onChange
    };
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    return React.createElement(InputCheckboxField.make, tmp);
  };
  var InputCheckbox = {
    make: Form__Elements__Inputs$Make$InputCheckbox
  };
  var Form__Elements__Inputs$Make$InputToggleSwitch = function (Props) {
    var label = Props.label;
    var badge = Props.badge;
    var field = Props.field;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var disabledOpt = Props.disabled;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var disabled = disabledOpt !== undefined ? disabledOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, true, undefined);
    var tmp = {
      label: label,
      required: props.required,
      value: props.value,
      disabled: disabled,
      onChange: props.onChange
    };
    if (badge !== undefined) {
      tmp.badge = badge;
    }
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    return React.createElement(InputToggleSwitchField.make, tmp);
  };
  var InputToggleSwitch = {
    make: Form__Elements__Inputs$Make$InputToggleSwitch
  };
  var Form__Elements__Inputs$Make$InputDate = function (Props) {
    var label = Props.label;
    var field = Props.field;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, true, undefined);
    var tmp = {
      label: label,
      required: props.required,
      value: Caml_option.some(props.value),
      onChange: props.onChange
    };
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    return React.createElement(InputDateField.make, tmp);
  };
  var InputDate = {
    make: Form__Elements__Inputs$Make$InputDate
  };
  var Form__Elements__Inputs$Make$InputDateRange = function (Props) {
    var label = Props.label;
    var placeholder = Props.placeholder;
    var field = Props.field;
    var hideErrorOpt = Props.hideError;
    var hideRequiredOpt = Props.hideRequired;
    var hideError = hideErrorOpt !== undefined ? hideErrorOpt : false;
    var hideRequired = hideRequiredOpt !== undefined ? hideRequiredOpt : false;
    var props = Curry._5(useField, field, hideError, hideRequired, true, undefined);
    var tmp = {
      label: label,
      value: props.value,
      required: props.required,
      onChange: props.onChange
    };
    if (placeholder !== undefined) {
      tmp.placeholder = placeholder;
    }
    if (props.error !== undefined) {
      tmp.errorMessage = props.error;
    }
    return React.createElement(InputDateRangeField.make, tmp);
  };
  var InputDateRange = {
    make: Form__Elements__Inputs$Make$InputDateRange
  };
  return {
          useField: useField,
          InputText: InputText,
          InputSuggestionComboBox: InputSuggestionComboBox,
          InputSelect: InputSelect,
          InputTextArea: InputTextArea,
          InputPassword: InputPassword,
          InputNumber: InputNumber,
          InputOptionalNumber: InputOptionalNumber,
          InputSegmentedControls: InputSegmentedControls,
          InputRadioGroup: InputRadioGroup,
          InputPhone: InputPhone,
          InputCheckbox: InputCheckbox,
          InputToggleSwitch: InputToggleSwitch,
          InputDate: InputDate,
          InputDateRange: InputDateRange
        };
}

export {
  Make ,
}
/* Intl Not a pure module */
