// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Accounting from "@wino/accounting/src/Accounting.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TooltipIcon from "../../resources/overlays/TooltipIcon.bs.js";
import * as InputNumberField from "../../resources/selection-and-input/InputNumberField.bs.js";

var styles = $$StyleSheet.create({
      wrapper: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "row", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function CartProductQuantityTableCell(Props) {
  var product = Props.product;
  var editableOpt = Props.editable;
  var beforeOrderReceptionOpt = Props.beforeOrderReception;
  var dispatch = Props.onRequestDispatch;
  var editable = editableOpt !== undefined ? editableOpt : false;
  var beforeOrderReception = beforeOrderReceptionOpt !== undefined ? beforeOrderReceptionOpt : true;
  var match;
  if (product.TAG === /* Unit */0) {
    var product$1 = product._0;
    match = [
      product$1.id,
      Belt_Option.map(product$1.packaging, (function (prim) {
              return prim;
            })),
      product$1.quantity,
      product$1.expectedQuantity,
      product$1.expectedQuantityWarning
    ];
  } else {
    var product$2 = product._0;
    match = [
      product$2.id,
      Belt_Option.map(product$2.packaging, (function (prim) {
              return prim.toNumber();
            })),
      product$2.quantity.toNumber(),
      product$2.expectedQuantity.toNumber(),
      product$2.expectedQuantityWarning
    ];
  }
  var expectedQuantity = match[3];
  var quantity = match[2];
  var packaging = match[1];
  var productId = match[0];
  var bulk;
  if (product.TAG === /* Unit */0) {
    bulk = undefined;
  } else {
    var unit = product._0.capacityUnit;
    bulk = unit !== undefined ? [
        unit,
        product._1
      ] : undefined;
  }
  var onChange = React.useCallback((function (value) {
          var quantity = Accounting.isBulk(product) ? ({
                TAG: /* BulkQuantity */1,
                _0: value
              }) : ({
                TAG: /* UnitQuantity */0,
                _0: value | 0
              });
          if (beforeOrderReception) {
            return Curry._1(dispatch, {
                        TAG: /* ProductExpectedQuantityUpdated */4,
                        _0: productId,
                        _1: quantity
                      });
          } else {
            return Curry._1(dispatch, {
                        TAG: /* ProductQuantityUpdated */3,
                        _0: productId,
                        _1: quantity
                      });
          }
        }), [productId]);
  var inputPrecision = bulk !== undefined ? bulk[1] : 0;
  var inputAppender;
  if (bulk !== undefined) {
    var unit$1 = bulk[0];
    inputAppender = beforeOrderReception ? ({
          TAG: /* Custom */1,
          _0: " " + unit$1 + ""
        }) : ({
          TAG: /* Custom */1,
          _0: "/ " + String(expectedQuantity) + " " + unit$1 + ""
        });
  } else {
    inputAppender = beforeOrderReception ? undefined : ({
          TAG: /* Custom */1,
          _0: "/ " + String(expectedQuantity) + ""
        });
  }
  var formattedQuantity = String(quantity).replace(".", ",") + Belt_Option.mapWithDefault(bulk, "", (function (param) {
          return " " + param[0] + "";
        }));
  var tmp;
  if (editable) {
    var tmp$1 = {
      value: quantity,
      minValue: 0,
      precision: inputPrecision,
      shrinkInput: true,
      onChange: onChange
    };
    if (inputAppender !== undefined) {
      tmp$1.appender = Caml_option.valFromOption(inputAppender);
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(InputNumberField.make, tmp$1)), undefined, undefined, undefined, undefined, undefined, "xnormal", undefined, undefined, undefined, undefined, undefined)), packaging !== undefined && match[4].length !== 0 ? React.createElement(TooltipIcon.make, {
                children: $$Intl.template($$Intl.t("Packaging by {{packaging}}"), {
                      packaging: packaging
                    }, undefined),
                variation: "info",
                key: productId
              }) : null);
  } else {
    tmp = React.createElement(TextStyle.make, {
          children: formattedQuantity,
          weight: "semibold"
        });
  }
  return React.createElement(View.make, View.makeProps(Caml_option.some(tmp), Caml_option.some(styles.wrapper), undefined, undefined, undefined));
}

var make = React.memo(CartProductQuantityTableCell, (function (oldProps, newProps) {
        if (Caml_obj.equal(oldProps.product, newProps.product) && oldProps.editable === newProps.editable) {
          return oldProps.beforeOrderReception === newProps.beforeOrderReception;
        } else {
          return false;
        }
      }));

export {
  styles ,
  make ,
}
/* styles Not a pure module */
