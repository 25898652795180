// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Tooltip from "../../resources/overlays/Tooltip.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AsyncResult from "../../primitives/AsyncResult.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ApolloHelpers from "../../helpers/ApolloHelpers.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query ParentCategories($filterBy: InputParentCategoriesQueryFilter)  {\nparentCategories(filterBy: $filterBy)  {\n__typename  \nid  \nformattedName  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.parentCategories;
  return {
          parentCategories: Js_array.map((function (value) {
                  return {
                          __typename: value.__typename,
                          id: value.id,
                          formattedName: value.formattedName
                        };
                }), value$1)
        };
}

function serialize(value) {
  var value$1 = value.parentCategories;
  var parentCategories = Js_array.map((function (value) {
          var value$1 = value.formattedName;
          var value$2 = value.id;
          var value$3 = value.__typename;
          return {
                  __typename: value$3,
                  id: value$2,
                  formattedName: value$1
                };
        }), value$1);
  return {
          parentCategories: parentCategories
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputParentCategoriesQueryFilter(inp) {
  var a = inp.archived;
  var a$1 = inp.shopIds;
  return {
          archived: a !== undefined ? (
              a === "ONLY" ? "ONLY" : (
                  a === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          shopIds: a$1 !== undefined ? serializeInputObjectInFilter(a$1) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputParentCategoriesQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputParentCategoriesQueryFilter(archived, shopIds, param) {
  return {
          archived: archived,
          shopIds: shopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputParentCategoriesQueryFilter: serializeInputObjectInputParentCategoriesQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputParentCategoriesQueryFilter: makeInputObjectInputParentCategoriesQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputParentCategoriesQueryFilter: serializeInputObjectInputParentCategoriesQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputParentCategoriesQueryFilter: makeInputObjectInputParentCategoriesQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function useCategoriesQuery(variables) {
  return AsyncResult.mapError(ApolloHelpers.queryResultToAsyncResult(Curry.app(use, [
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      variables
                    ])), (function (param) {
                
              }));
}

function CatalogCategorySelectFilter(Props) {
  var shopId = Props.shopId;
  var value = Props.value;
  var useCategoriesQueryOpt = Props.useCategoriesQuery;
  var onChange = Props.onChange;
  var useCategoriesQuery$1 = useCategoriesQueryOpt !== undefined ? useCategoriesQueryOpt : useCategoriesQuery;
  var queryResult = Curry._1(useCategoriesQuery$1, {
        filterBy: {
          archived: "EXCLUDED",
          shopIds: shopId !== undefined ? ({
                _in: [shopId]
              }) : undefined
        }
      });
  var items;
  var exit = 0;
  var parentCategories;
  if (typeof queryResult === "number") {
    items = queryResult === /* NotAsked */0 ? [] : [];
  } else if (queryResult.TAG === /* Reloading */0) {
    var match = queryResult._0;
    if (match.TAG === /* Ok */0) {
      parentCategories = match._0.parentCategories;
      exit = 1;
    } else {
      items = [];
    }
  } else {
    var match$1 = queryResult._0;
    if (match$1.TAG === /* Ok */0) {
      parentCategories = match$1._0.parentCategories;
      exit = 1;
    } else {
      items = [];
    }
  }
  if (exit === 1) {
    items = Belt_Array.map(parentCategories, (function (parentCategory) {
            return {
                    key: parentCategory.id,
                    value: {
                      id: parentCategory.id,
                      name: parentCategory.formattedName
                    },
                    label: parentCategory.formattedName
                  };
          }));
  }
  var defaultItem_label = $$Intl.template($$Intl.t("All{{feminine}}"), undefined, undefined);
  var defaultItem_sticky = true;
  var defaultItem = {
    key: "default",
    label: defaultItem_label,
    sticky: defaultItem_sticky
  };
  var unclassifiedItem_value = {
    id: null,
    name: $$Intl.t("Not classified")
  };
  var unclassifiedItem_label = $$Intl.template($$Intl.t("Not classified"), undefined, undefined);
  var unclassifiedItem_sticky = true;
  var unclassifiedItem = {
    key: "not-classified",
    value: unclassifiedItem_value,
    label: unclassifiedItem_label,
    sticky: unclassifiedItem_sticky
  };
  var sections = [
    {
      items: [
        defaultItem,
        unclassifiedItem
      ]
    },
    {
      items: items,
      title: $$Intl.t("Categories")
    }
  ];
  var disabled = Belt_Option.isNone(shopId) || AsyncResult.isBusy(queryResult);
  return React.createElement(Tooltip.make, {
              children: React.createElement(Select.make, {
                    label: $$Intl.t("Category"),
                    disabled: disabled,
                    sections: sections,
                    preset: "filter",
                    size: "compact",
                    value: value,
                    onChange: onChange
                  }),
              placement: "top",
              content: {
                TAG: /* Text */0,
                _0: $$Intl.t("Please select a shop beforehand.")
              },
              disabled: !disabled,
              delay: 0
            });
}

var make = CatalogCategorySelectFilter;

export {
  Query ,
  useCategoriesQuery ,
  make ,
}
/* query Not a pure module */
