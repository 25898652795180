// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../images-and-icons/Icon.bs.js";
import * as $$Text from "../../primitives/Text.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Hover from "../../primitives/Hover.bs.js";
import * as Stack from "../layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Spaces from "../theme/Spaces.bs.js";
import * as Tooltip from "./Tooltip.bs.js";
import * as FontFaces from "../theme/FontFaces.bs.js";
import * as FontSizes from "../theme/FontSizes.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactUpdateDebounced from "../../primitives/ReactUpdateDebounced.bs.js";

var styles = $$StyleSheet.create({
      wrapperTooltip: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#35334b", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 5, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(350)), undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.normal)), undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(Spaces.normal)), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
      textTooltip: Style.merge([
            FontFaces.libreFranklinRegularStyle,
            Style.style(undefined, undefined, undefined, "#fefefe", undefined, FontSizes.xsmall, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
          ])
    });

function TooltipIcon(Props) {
  var children = Props.children;
  var variation = Props.variation;
  var arrowedOpt = Props.arrowed;
  var placementOpt = Props.placement;
  var altTriggerRef = Props.altTriggerRef;
  var offsetOpt = Props.offset;
  var crossOffsetOpt = Props.crossOffset;
  var arrowed = arrowedOpt !== undefined ? arrowedOpt : false;
  var placement = placementOpt !== undefined ? placementOpt : "bottom start";
  var offset = offsetOpt !== undefined ? offsetOpt : 6;
  var crossOffset = crossOffsetOpt !== undefined ? crossOffsetOpt : -3;
  var match = Hover.use(altTriggerRef, undefined);
  var debouncedHovered = ReactUpdateDebounced.use(match[1], 250);
  var opened = Belt_Option.map(altTriggerRef, (function (param) {
          return debouncedHovered;
        }));
  var overlayContent = React.createElement(View.make, View.makeProps(Caml_option.some(React.createElement(Stack.make, {
                    children: React.createElement($$Text.make, $$Text.makeProps(children, Caml_option.some(styles.textTooltip), undefined, undefined, undefined)),
                    space: "xsmall"
                  })), Caml_option.some(styles.wrapperTooltip), undefined, undefined, undefined));
  var tmp = {
    children: React.createElement(Icon.make, {
          name: variation === "alert" ? "alert_tip" : "info_tip",
          fill: variation === "alert" ? "#535165" : "#a2a1b0"
        }),
    arrowed: arrowed,
    placement: placement,
    offset: offset,
    crossOffset: crossOffset,
    content: {
      TAG: /* Component */1,
      _0: overlayContent
    },
    delay: 250,
    immediateClose: true
  };
  if (opened !== undefined) {
    tmp.opened = opened;
  }
  return React.createElement(Tooltip.make, tmp);
}

var make = TooltipIcon;

export {
  make ,
}
/* styles Not a pure module */
