// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";

function Make(Params) {
  var Form__Elements__Buttons$Make$SubmitButton = function (Props) {
    var variationOpt = Props.variation;
    var sizeOpt = Props.size;
    var onSubmit = Props.onSubmit;
    var text = Props.text;
    var variation = variationOpt !== undefined ? variationOpt : "primary";
    var size = sizeOpt !== undefined ? sizeOpt : "large";
    var match = Curry._1(Params.useSubmit, onSubmit);
    var onSubmit$1 = match.onSubmit;
    var loading = match.submission === /* Requested */1;
    var disabled = match.disabled && !loading;
    var appearance = disabled || loading ? "dark" : undefined;
    return React.createElement(Button.make, Button.makeProps(text, size, variation, appearance, undefined, loading, disabled, undefined, undefined, undefined, (function (param) {
                      Curry._1(onSubmit$1, undefined);
                    }), undefined, undefined, undefined));
  };
  var SubmitButton = {
    make: Form__Elements__Buttons$Make$SubmitButton
  };
  var Form__Elements__Buttons$Make$CancelButton = function (Props) {
    var text = Props.text;
    var sizeOpt = Props.size;
    var size = sizeOpt !== undefined ? sizeOpt : "large";
    var match = Curry._1(Params.useCancel, undefined);
    var onCancel = match[1];
    return React.createElement(Button.make, Button.makeProps(text, size, "neutral", undefined, undefined, undefined, match[0], undefined, undefined, undefined, (function (param) {
                      Curry._1(onCancel, undefined);
                    }), undefined, undefined, undefined));
  };
  var CancelButton = {
    make: Form__Elements__Buttons$Make$CancelButton
  };
  var Form__Elements__Buttons$Make$ResetButton = function (Props) {
    var variationOpt = Props.variation;
    var sizeOpt = Props.size;
    var onPress = Props.onPress;
    var text = Props.text;
    var variation = variationOpt !== undefined ? variationOpt : "secondary";
    var size = sizeOpt !== undefined ? sizeOpt : "large";
    var onReset = Curry._1(Params.useReset, undefined);
    return React.createElement(Button.make, Button.makeProps(text, size, variation, undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      if (onPress !== undefined) {
                        Curry._1(onPress, undefined);
                      }
                      Curry._1(onReset, undefined);
                    }), undefined, undefined, undefined));
  };
  var ResetButton = {
    make: Form__Elements__Buttons$Make$ResetButton
  };
  return {
          SubmitButton: SubmitButton,
          CancelButton: CancelButton,
          ResetButton: ResetButton
        };
}

var Submission;

export {
  Submission ,
  Make ,
}
/* react Not a pure module */
