// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "../../resources/overlays/Modal.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as AsyncResult from "../../primitives/AsyncResult.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateHelpers from "../../helpers/DateHelpers.bs.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Illustration from "../../resources/images-and-icons/Illustration.bs.js";
import * as ApolloHelpers from "../../helpers/ApolloHelpers.bs.js";
import * as Client from "@apollo/client";
import * as AnimatedRender from "../../primitives/AnimatedRender.bs.js";
import * as CatalogProduct from "../Catalog/CatalogProduct.bs.js";
import * as ProductPriceTableCell from "../Product/ProductPriceTableCell.bs.js";
import * as ProductStockTableCell from "../Product/ProductStockTableCell.bs.js";
import * as ProductReferenceTableCell from "../Product/ProductReferenceTableCell.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query VariantsQuery($search: String, $filterBy: InputVariantsDistinctOnCkuQueryFilter, $after: String, $first: Int)  {\nvariantsDistinctOnCku(search: $search, filterBy: $filterBy, after: $after, first: $first)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncku  \ncreatedAt  \nname  \nformattedName  \nproduct  {\n__typename  \nid  \nname  \nkind  \ncolor  \nwineType  \nwhiteWineType  \nbeerType  \nproducer  \ndesignation  \nfamily  \nregion  \ncountry  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\nstockKeepingUnit  \ninternalCode  \npriceLookUpCode  \nsupplier  {\n__typename  \ncompanyName  \n}\n\nformattedCategory  \nalcoholVolume  \nformattedPurchasedPrice  \nbulk  \nstock  {\n__typename  \nrawQuantity  \nformattedQuantity  \nstate  \n}\n\n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$2.hasNextPage;
  var value$6 = value$1.edges;
  return {
          variantsDistinctOnCku: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined,
              hasNextPage: !(value$5 == null) ? value$5 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.product;
                    var value$3 = value$2.kind;
                    var tmp;
                    switch (value$3) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$4 = value$2.color;
                    var tmp$1;
                    if (value$4 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$4) {
                        case "AMBER" :
                            tmp$2 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$2 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$2 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$2 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$2 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$2 = "RED";
                            break;
                        case "ROSE" :
                            tmp$2 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$2 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$5 = value$2.wineType;
                    var tmp$3;
                    if (value$5 == null) {
                      tmp$3 = undefined;
                    } else {
                      var tmp$4;
                      switch (value$5) {
                        case "EFFERVESCENT" :
                            tmp$4 = "EFFERVESCENT";
                            break;
                        case "STILL" :
                            tmp$4 = "STILL";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$3 = tmp$4;
                    }
                    var value$6 = value$2.whiteWineType;
                    var tmp$5;
                    if (value$6 == null) {
                      tmp$5 = undefined;
                    } else {
                      var tmp$6;
                      switch (value$6) {
                        case "DRY" :
                            tmp$6 = "DRY";
                            break;
                        case "SEMIDRY" :
                            tmp$6 = "SEMIDRY";
                            break;
                        case "SOFT" :
                            tmp$6 = "SOFT";
                            break;
                        case "SWEET" :
                            tmp$6 = "SWEET";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$5 = tmp$6;
                    }
                    var value$7 = value$2.beerType;
                    var value$8 = value$2.producer;
                    var value$9 = value$2.designation;
                    var value$10 = value$2.family;
                    var value$11 = value$2.region;
                    var value$12 = value$2.country;
                    var value$13 = value$2.tax;
                    var value$14 = value$1.stockKeepingUnit;
                    var value$15 = value$1.internalCode;
                    var value$16 = value$1.priceLookUpCode;
                    var value$17 = value$1.supplier;
                    var value$18 = value$1.formattedCategory;
                    var value$19 = value$1.alcoholVolume;
                    var value$20 = value$1.formattedPurchasedPrice;
                    var value$21 = value$1.bulk;
                    var value$22 = value$1.stock;
                    var value$23 = value$22.rawQuantity;
                    var value$24 = value$22.formattedQuantity;
                    var value$25 = value$22.state;
                    var tmp$7;
                    if (value$25 == null) {
                      tmp$7 = undefined;
                    } else {
                      var tmp$8;
                      switch (value$25) {
                        case "ALERT" :
                            tmp$8 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$8 = "DANGER";
                            break;
                        case "OK" :
                            tmp$8 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$7 = tmp$8;
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              cku: Scalar.CKU.parse(value$1.cku),
                              createdAt: Scalar.Datetime.parse(value$1.createdAt),
                              name: value$1.name,
                              formattedName: value$1.formattedName,
                              product: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name,
                                kind: tmp,
                                color: tmp$1,
                                wineType: tmp$3,
                                whiteWineType: tmp$5,
                                beerType: !(value$7 == null) ? value$7 : undefined,
                                producer: !(value$8 == null) ? value$8 : undefined,
                                designation: !(value$9 == null) ? value$9 : undefined,
                                family: !(value$10 == null) ? value$10 : undefined,
                                region: !(value$11 == null) ? value$11 : undefined,
                                country: !(value$12 == null) ? value$12 : undefined,
                                tax: {
                                  __typename: value$13.__typename,
                                  value: value$13.value
                                }
                              },
                              stockKeepingUnit: !(value$14 == null) ? value$14 : undefined,
                              internalCode: !(value$15 == null) ? value$15 : undefined,
                              priceLookUpCode: !(value$16 == null) ? value$16 : undefined,
                              supplier: !(value$17 == null) ? ({
                                    __typename: value$17.__typename,
                                    companyName: value$17.companyName
                                  }) : undefined,
                              formattedCategory: !(value$18 == null) ? value$18 : undefined,
                              alcoholVolume: !(value$19 == null) ? value$19 : undefined,
                              formattedPurchasedPrice: !(value$20 == null) ? value$20 : undefined,
                              bulk: !(value$21 == null) ? value$21 : undefined,
                              stock: {
                                __typename: value$22.__typename,
                                rawQuantity: !(value$23 == null) ? value$23 : undefined,
                                formattedQuantity: !(value$24 == null) ? value$24 : undefined,
                                state: tmp$7
                              }
                            }
                          };
                  }), value$6),
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.stock;
          var value$3 = value$2.state;
          var state = value$3 !== undefined ? (
              value$3 === "OK" ? "OK" : (
                  value$3 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$4 = value$2.formattedQuantity;
          var formattedQuantity = value$4 !== undefined ? value$4 : null;
          var value$5 = value$2.rawQuantity;
          var rawQuantity = value$5 !== undefined ? value$5 : null;
          var value$6 = value$2.__typename;
          var stock = {
            __typename: value$6,
            rawQuantity: rawQuantity,
            formattedQuantity: formattedQuantity,
            state: state
          };
          var value$7 = value$1.bulk;
          var bulk = value$7 !== undefined ? value$7 : null;
          var value$8 = value$1.formattedPurchasedPrice;
          var formattedPurchasedPrice = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.alcoholVolume;
          var alcoholVolume = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.formattedCategory;
          var formattedCategory = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.supplier;
          var supplier;
          if (value$11 !== undefined) {
            var value$12 = value$11.companyName;
            var value$13 = value$11.__typename;
            supplier = {
              __typename: value$13,
              companyName: value$12
            };
          } else {
            supplier = null;
          }
          var value$14 = value$1.priceLookUpCode;
          var priceLookUpCode = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.internalCode;
          var internalCode = value$15 !== undefined ? value$15 : null;
          var value$16 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$16 !== undefined ? value$16 : null;
          var value$17 = value$1.product;
          var value$18 = value$17.tax;
          var value$19 = value$18.value;
          var value$20 = value$18.__typename;
          var tax = {
            __typename: value$20,
            value: value$19
          };
          var value$21 = value$17.country;
          var country = value$21 !== undefined ? value$21 : null;
          var value$22 = value$17.region;
          var region = value$22 !== undefined ? value$22 : null;
          var value$23 = value$17.family;
          var family = value$23 !== undefined ? value$23 : null;
          var value$24 = value$17.designation;
          var designation = value$24 !== undefined ? value$24 : null;
          var value$25 = value$17.producer;
          var producer = value$25 !== undefined ? value$25 : null;
          var value$26 = value$17.beerType;
          var beerType = value$26 !== undefined ? value$26 : null;
          var value$27 = value$17.whiteWineType;
          var whiteWineType = value$27 !== undefined ? (
              value$27 === "SWEET" ? "SWEET" : (
                  value$27 === "SEMIDRY" ? "SEMIDRY" : (
                      value$27 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : null;
          var value$28 = value$17.wineType;
          var wineType = value$28 !== undefined ? (
              value$28 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : null;
          var value$29 = value$17.color;
          var color = value$29 !== undefined ? (
              value$29 === "ORANGE" ? "ORANGE" : (
                  value$29 === "RED" ? "RED" : (
                      value$29 === "AMBER" ? "AMBER" : (
                          value$29 === "DARK" ? "DARK" : (
                              value$29 === "BLACK" ? "BLACK" : (
                                  value$29 === "BLOND" ? "BLOND" : (
                                      value$29 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$30 = value$17.kind;
          var kind = value$30 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$30 === "BEER" ? "BEER" : (
                  value$30 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$31 = value$17.name;
          var value$32 = value$17.id;
          var value$33 = value$17.__typename;
          var product = {
            __typename: value$33,
            id: value$32,
            name: value$31,
            kind: kind,
            color: color,
            wineType: wineType,
            whiteWineType: whiteWineType,
            beerType: beerType,
            producer: producer,
            designation: designation,
            family: family,
            region: region,
            country: country,
            tax: tax
          };
          var value$34 = value$1.formattedName;
          var value$35 = value$1.name;
          var value$36 = value$1.createdAt;
          var value$37 = Scalar.Datetime.serialize(value$36);
          var value$38 = value$1.cku;
          var value$39 = Scalar.CKU.serialize(value$38);
          var value$40 = value$1.id;
          var value$41 = value$1.__typename;
          var node = {
            __typename: value$41,
            id: value$40,
            cku: value$39,
            createdAt: value$37,
            name: value$35,
            formattedName: value$34,
            product: product,
            stockKeepingUnit: stockKeepingUnit,
            internalCode: internalCode,
            priceLookUpCode: priceLookUpCode,
            supplier: supplier,
            formattedCategory: formattedCategory,
            alcoholVolume: alcoholVolume,
            formattedPurchasedPrice: formattedPurchasedPrice,
            bulk: bulk,
            stock: stock
          };
          var value$42 = value.__typename;
          return {
                  __typename: value$42,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.hasNextPage;
  var hasNextPage = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.startCursor;
  var startCursor = value$7 !== undefined ? value$7 : null;
  var value$8 = value$4.__typename;
  var pageInfo = {
    __typename: value$8,
    startCursor: startCursor,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$9 = value$1.__typename;
  var variantsDistinctOnCku = {
    __typename: value$9,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          variantsDistinctOnCku: variantsDistinctOnCku
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputVariantsDistinctOnCkuQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.active;
  var a$2 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          active: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          archived: a$2 !== undefined ? (
              a$2 === "ONLY" ? "ONLY" : (
                  a$2 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.after;
  var a$3 = inp.first;
  return {
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputVariantsDistinctOnCkuQueryFilter(a$1) : undefined,
          after: a$2 !== undefined ? a$2 : undefined,
          first: a$3 !== undefined ? a$3 : undefined
        };
}

function makeVariables(search, filterBy, after, first, param) {
  return {
          search: search,
          filterBy: filterBy,
          after: after,
          first: first
        };
}

function makeInputObjectInputVariantsDistinctOnCkuQueryFilter(shopIds, active, archived, param) {
  return {
          shopIds: shopIds,
          active: active,
          archived: archived
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          filterBy: undefined,
          after: undefined,
          first: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsDistinctOnCkuQueryFilter: serializeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsDistinctOnCkuQueryFilter: makeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsDistinctOnCkuQueryFilter: serializeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsDistinctOnCkuQueryFilter: makeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function rowsFromEdgesData(data) {
  return Belt_Array.map(data, (function (edge) {
                return {
                        id: edge.node.id,
                        cku: edge.node.cku,
                        createdAt: edge.node.createdAt,
                        formattedName: edge.node.formattedName,
                        productKind: edge.node.product.kind,
                        information: {
                          productName: edge.node.product.name,
                          variantName: edge.node.name,
                          sku: edge.node.stockKeepingUnit,
                          plu: Belt_Option.map(edge.node.priceLookUpCode, (function (prim) {
                                  return String(prim);
                                })),
                          internalCode: edge.node.internalCode,
                          color: edge.node.product.color,
                          producerName: edge.node.product.producer,
                          designation: edge.node.product.designation,
                          productFamily: edge.node.product.family,
                          wineType: edge.node.product.wineType,
                          whiteWineType: edge.node.product.whiteWineType,
                          beerType: edge.node.product.beerType,
                          region: edge.node.product.region,
                          country: Belt_Option.getWithDefault(edge.node.product.country, "—"),
                          categoryName: Belt_Option.getWithDefault(edge.node.formattedCategory, "—"),
                          supplierName: Belt_Option.map(edge.node.supplier, (function (supplier) {
                                  return supplier.companyName;
                                })),
                          alcoholVolume: Belt_Option.map(edge.node.alcoholVolume, (function (alcoholVolume) {
                                  return $$Intl.decimalFormat(undefined, 1, alcoholVolume) + "°";
                                }))
                        },
                        purchasePrice: edge.node.formattedPurchasedPrice,
                        stockRawQuantity: Belt_Option.getWithDefault(edge.node.stock.rawQuantity, 0),
                        stockFormattedQuantity: Belt_Option.getWithDefault(edge.node.stock.formattedQuantity, "-"),
                        stockState: edge.node.stock.state,
                        bulk: Belt_Option.getWithDefault(edge.node.bulk, false)
                      };
              }));
}

var columns = [
  {
    key: "reference",
    name: $$Intl.t("Product"),
    layout: {
      width: {
        NAME: "pct",
        VAL: 65
      }
    },
    render: (function (param) {
        var product = param.data;
        var badgeNew = DateHelpers.diffInHours(new Date(), product.createdAt) <= 24 ? ({
              variation: "information",
              text: $$Intl.t("New")
            }) : undefined;
        var tmp = {
          disabled: param.disabled,
          productKind: product.productKind,
          information: product.information
        };
        if (badgeNew !== undefined) {
          tmp.badge = Caml_option.valFromOption(badgeNew);
        }
        return React.createElement(ProductReferenceTableCell.make, tmp);
      })
  },
  {
    key: "stock",
    name: $$Intl.t("Stock exp."),
    render: (function (param) {
        var product = param.data;
        if (param.disabled) {
          return React.createElement(TextStyle.make, {
                      children: $$Intl.t("Already added"),
                      variation: "subdued",
                      size: "small"
                    });
        }
        var tmp = {
          value: product.stockFormattedQuantity,
          size: "xsmall"
        };
        if (product.stockState !== undefined) {
          tmp.state = Caml_option.valFromOption(product.stockState);
        }
        return React.createElement(ProductStockTableCell.make, tmp);
      })
  },
  {
    key: "purchasedPrice",
    name: $$Intl.t("Purchase price"),
    layout: {
      width: {
        NAME: "px",
        VAL: 120
      }
    },
    render: (function (param) {
        if (param.disabled) {
          return null;
        } else {
          return React.createElement(ProductPriceTableCell.make, {
                      value: param.data.purchasePrice,
                      size: "xsmall"
                    });
        }
      })
  }
];

function setTransferredQuantityValue(product) {
  return {
          cku: product.cku,
          name: product.name,
          description: product.description,
          purchasePrice: product.purchasePrice,
          stockKeepingUnit: product.stockKeepingUnit,
          senderVariantId: product.senderVariantId,
          senderStockQuantity: product.senderStockQuantity,
          senderStockState: product.senderStockState,
          recipientVariantId: product.recipientVariantId,
          recipientStockQuantity: product.recipientStockQuantity,
          recipientStockState: product.recipientStockState,
          transferredQuantity: 1
        };
}

var make = React.memo(function (Props) {
      var children = Props.children;
      var opened = Props.opened;
      var selectedProducts = Props.selectedProducts;
      var onCommit = Props.onCommit;
      var onRequestClose = Props.onRequestClose;
      var renderStartText = function (param) {
        var length = selectedProducts.length;
        var shouldBePrimaryView = length > 0;
        var formattedText = "" + String(length) + " " + $$Intl.t($$Intl.isPlural(length) ? "selected products" : "selected product");
        return React.createElement(Inline.make, {
                    children: React.createElement(TextStyle.make, {
                          children: formattedText,
                          variation: shouldBePrimaryView ? "primary" : "neutral",
                          weight: shouldBePrimaryView ? "strong" : "regular"
                        })
                  });
      };
      var onCommit$1 = function (param) {
        Curry._1(onCommit, Belt_Array.map(Belt_Array.keep(selectedProducts, (function (row) {
                        return !row.bulk;
                      })), (function (row) {
                    return setTransferredQuantityValue({
                                cku: row.cku,
                                name: row.formattedName,
                                description: CatalogProduct.Information.formateDescription(row.productKind, row.information, true, undefined),
                                purchasePrice: row.purchasePrice,
                                stockKeepingUnit: row.information.sku,
                                senderVariantId: row.id,
                                senderStockQuantity: row.stockRawQuantity,
                                senderStockState: row.stockState,
                                recipientVariantId: "",
                                recipientStockQuantity: undefined,
                                recipientStockState: undefined,
                                transferredQuantity: 0
                              });
                  })));
      };
      return React.createElement(Modal.make, {
                  children: children,
                  opened: opened,
                  title: $$Intl.t("Product append"),
                  variation: "secondary",
                  compact: false,
                  backgroundColor: "#fefefe",
                  renderStartText: renderStartText,
                  abortButtonText: $$Intl.t("Cancel"),
                  allowCommit: selectedProducts.length !== 0,
                  commitButtonVariation: "primary",
                  commitButtonText: $$Intl.t("Add products"),
                  commitButtonCallback: onCommit$1,
                  onRequestClose: onRequestClose
                });
    });

var PickerModal = {
  setTransferredQuantityValue: setTransferredQuantityValue,
  make: make
};

function makeVariables$1(search, shopId, after, param) {
  return makeVariables(search, {
              shopIds: {
                _in: [shopId]
              },
              active: undefined,
              archived: undefined
            }, after, 20, undefined);
}

function StockTransferFormProductPickerModalTable(Props) {
  var opened = Props.opened;
  var senderShopId = Props.senderShopId;
  var recipientShopId = Props.recipientShopId;
  var disabledIdsOpt = Props.disabledIds;
  var onCommit = Props.onCommit;
  var onRequestClose = Props.onRequestClose;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var match = React.useState(function () {
        return [];
      });
  var setSelectedProducts = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchQuery = match$1[1];
  var searchQuery = match$1[0];
  var query = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NetworkOnly */3,
        /* CacheFirst */1,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        senderShopId === "" || recipientShopId === "",
        undefined,
        makeVariables$1(searchQuery, senderShopId, undefined, undefined)
      ]);
  var asyncResult = ApolloHelpers.useQueryResultToAsyncResultWithVariablesReloading2(query, searchQuery, senderShopId);
  var data = AsyncResult.mapOk(asyncResult, (function (data) {
          return rowsFromEdgesData(data.variantsDistinctOnCku.edges);
        }));
  React.useEffect((function () {
          if (!opened && searchQuery !== "") {
            Curry._1(setSearchQuery, (function (param) {
                    return "";
                  }));
          }
          
        }), [opened]);
  var onLoadMore = React.useCallback((function (param) {
          if (typeof asyncResult === "number") {
            return ;
          }
          if (asyncResult.TAG !== /* Done */1) {
            return ;
          }
          var data = asyncResult._0;
          if (data.TAG !== /* Ok */0) {
            return ;
          }
          var data$1 = data._0;
          if (data$1.variantsDistinctOnCku.pageInfo.hasNextPage === true) {
            Curry._5(query.fetchMore, undefined, undefined, makeVariables$1(searchQuery, senderShopId, data$1.variantsDistinctOnCku.pageInfo.endCursor, undefined), (function (prevResult, param) {
                    var fetchMoreResult = param.fetchMoreResult;
                    if (fetchMoreResult === undefined) {
                      return prevResult;
                    }
                    var newVariants = fetchMoreResult.variantsDistinctOnCku;
                    return {
                            variantsDistinctOnCku: {
                              __typename: newVariants.__typename,
                              pageInfo: newVariants.pageInfo,
                              edges: Belt_Array.concat(prevResult.variantsDistinctOnCku.edges, newVariants.edges),
                              totalCount: newVariants.totalCount
                            }
                          };
                  }), undefined);
            return ;
          }
          
        }), [asyncResult]);
  var onSelectChange = React.useCallback((function (selection) {
          Curry._1(setSelectedProducts, (function (prev) {
                  var rowsData;
                  if (typeof data === "number") {
                    rowsData = data === /* NotAsked */0 ? [] : [];
                  } else if (data.TAG === /* Reloading */0) {
                    var data$1 = data._0;
                    rowsData = data$1.TAG === /* Ok */0 ? data$1._0 : [];
                  } else {
                    var data$2 = data._0;
                    rowsData = data$2.TAG === /* Ok */0 ? data$2._0 : [];
                  }
                  if (selection) {
                    return Belt_Array.keepMap(selection._0, (function (selectedRowId) {
                                  return Belt_Array.getBy(Belt_Array.concat(rowsData, prev), (function (row) {
                                                return row.id === selectedRowId;
                                              }));
                                }));
                  } else {
                    return prev;
                  }
                }));
        }), [
        data,
        disabledIds
      ]);
  var placeholderEmptyState = React.createElement(Placeholder.make, {
        status: senderShopId !== "" && recipientShopId !== "" ? /* NoDataAvailable */1 : /* Pending */({
              title: $$Intl.t("Beware !"),
              message: $$Intl.t("Please select the shops linked to the transfer to start adding products."),
              illustration: Caml_option.some(Illustration.shopMissing)
            })
      });
  return React.createElement(make, {
              children: React.createElement(AnimatedRender.make, {
                    children: React.createElement("div", {
                          style: {
                            display: "flex",
                            maxHeight: "70vh",
                            minHeight: "70vh"
                          }
                        }, React.createElement(TableView.make, {
                              data: data,
                              columns: columns,
                              keyExtractor: (function (row) {
                                  return row.id;
                                }),
                              disabledRowsKeys: disabledIds,
                              selectionEnabled: true,
                              selectAllEnabled: false,
                              hideReloadingPlaceholder: true,
                              compactRows: true,
                              placeholderEmptyState: placeholderEmptyState,
                              searchPlaceholder: $$Intl.t("Search product"),
                              onSearchQueryChange: (function (value) {
                                  Curry._1(setSearchQuery, (function (param) {
                                          return value;
                                        }));
                                }),
                              onLoadMore: onLoadMore,
                              onSelectChange: onSelectChange
                            })),
                    displayed: opened,
                    animation: "fadePopinTranslation",
                    duration: 300
                  }),
              opened: opened,
              selectedProducts: match[0],
              onCommit: onCommit,
              onRequestClose: onRequestClose
            });
}

var make$1 = React.memo(StockTransferFormProductPickerModalTable, (function (oldProps, newProps) {
        return oldProps.opened === newProps.opened;
      }));

export {
  Query ,
  rowsFromEdgesData ,
  columns ,
  PickerModal ,
  makeVariables$1 as makeVariables,
  make$1 as make,
}
/* query Not a pure module */
