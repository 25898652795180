// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Env from "../../core/Env.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Form from "../../bundles/Form/Form.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Group from "../../resources/layout-and-structure/Group.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as $$Request from "../../core/Request.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CountryCode from "../../primitives/CountryCode.bs.js";
import * as StackFields from "../../resources/layout-and-structure/StackFields.bs.js";
import * as BillingAccount from "../../modules/Billing/BillingAccount.bs.js";
import * as CorporateEntity from "../../core/CorporateEntity.bs.js";
import * as Form__Submission from "../../bundles/Form/Form__Submission.bs.js";
import * as FieldsetLayoutPanel from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as ResourceDetailsPage from "../../core/ResourceDetailsPage.bs.js";
import * as SupplierAddressComboBoxField from "../../modules/Supplier/SupplierAddressComboBoxField.bs.js";

function get(values, field) {
  switch (field) {
    case /* CorporateName */0 :
        return values.corporateName;
    case /* ShopName */1 :
        return values.shopName;
    case /* Email */2 :
        return values.email;
    case /* Phone */3 :
        return values.phone;
    case /* VatNumber */4 :
        return values.vatNumber;
    case /* Address */5 :
        return values.address;
    case /* City */6 :
        return values.city;
    case /* PostalCode */7 :
        return values.postalCode;
    case /* Country */8 :
        return values.country;
    case /* ShippingAddress */9 :
        return values.shippingAddress;
    case /* ShippingPostalCode */10 :
        return values.shippingPostalCode;
    case /* ShippingCity */11 :
        return values.shippingCity;
    case /* ShippingCountry */12 :
        return values.shippingCountry;
    case /* SameShippingAddressThanBilling */13 :
        return values.sameShippingAddressThanBilling;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case /* CorporateName */0 :
        return {
                corporateName: value,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* ShopName */1 :
        return {
                corporateName: values.corporateName,
                shopName: value,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* Email */2 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: value,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* Phone */3 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: value,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* VatNumber */4 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: value,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* Address */5 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: value,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* City */6 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: value,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* PostalCode */7 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: value,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* Country */8 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: value,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* ShippingAddress */9 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: value,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* ShippingPostalCode */10 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: value,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* ShippingCity */11 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: value,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* ShippingCountry */12 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: value,
                sameShippingAddressThanBilling: values.sameShippingAddressThanBilling
              };
    case /* SameShippingAddressThanBilling */13 :
        return {
                corporateName: values.corporateName,
                shopName: values.shopName,
                email: values.email,
                phone: values.phone,
                vatNumber: values.vatNumber,
                address: values.address,
                city: values.city,
                postalCode: values.postalCode,
                country: values.country,
                shippingAddress: values.shippingAddress,
                shippingPostalCode: values.shippingPostalCode,
                shippingCity: values.shippingCity,
                shippingCountry: values.shippingCountry,
                sameShippingAddressThanBilling: value
              };
    
  }
}

var BillingAccountFormLenses = {
  get: get,
  set: set
};

var BillingAccountForm = Form.Make(BillingAccountFormLenses);

function stripEmptyString(string) {
  if (string === "") {
    return ;
  } else {
    return string;
  }
}

function encodeBody(shopId, corporateName, shopName, email, phone, vatNumber, address, postalCode, city, country, shippingAddress, shippingPostalCode, shippingCity, shippingCountry, sameShippingAddressThanBilling) {
  return Js_dict.fromArray([
              [
                "shopId",
                Json.encodeString(shopId)
              ],
              [
                "corporateName",
                Json.encodeString(corporateName)
              ],
              [
                "shopName",
                Json.encodeString(shopName)
              ],
              [
                "email",
                Json.encodeString(email)
              ],
              [
                "phone",
                Json.encodeString(phone)
              ],
              [
                "vatNumber",
                Json.encodeString(vatNumber)
              ],
              [
                "billingAddress",
                Json.encodeDict(Js_dict.fromArray([
                          [
                            "address",
                            Json.encodeString(address)
                          ],
                          [
                            "city",
                            Json.encodeString(city)
                          ],
                          [
                            "postalCode",
                            Json.encodeString(postalCode)
                          ],
                          [
                            "country",
                            Json.encodeString(CountryCode.toIsoString(country))
                          ]
                        ]))
              ],
              [
                "shippingAddress",
                sameShippingAddressThanBilling ? Json.encodeDict(Js_dict.fromArray([
                            [
                              "address",
                              Json.encodeString(address)
                            ],
                            [
                              "city",
                              Json.encodeString(city)
                            ],
                            [
                              "postalCode",
                              Json.encodeString(postalCode)
                            ],
                            [
                              "country",
                              Json.encodeString(CountryCode.toIsoString(country))
                            ]
                          ])) : Json.encodeDict(Js_dict.fromArray([
                            [
                              "address",
                              Json.encodeString(shippingAddress)
                            ],
                            [
                              "city",
                              Json.encodeString(shippingCity)
                            ],
                            [
                              "postalCode",
                              Json.encodeString(shippingPostalCode)
                            ],
                            [
                              "country",
                              Json.encodeString(shippingCountry)
                            ]
                          ]))
              ]
            ]);
}

function decodeInvalidRequestFailure(serverFailure) {
  switch (serverFailure.kind) {
    case "DuplicateUserUsername" :
        return /* DuplicateUserUsername */0;
    case "InvalidVatNumber" :
        return /* InvalidVatNumber */1;
    case "NotFoundBillingAccount" :
        return /* NotFoundBillingAccount */2;
    default:
      return /* Unknown */3;
  }
}

var endpoint = Env.gatewayUrl(undefined) + "/customer-billing-account";

function make(shopId, corporateName, shopName, email, phone, vatNumber, address, postalCode, city, country, shippingAddress, shippingPostalCode, shippingCity, shippingCountry, sameShippingAddressThanBilling) {
  return Future.mapError(Future.mapOk($$Request.make("PATCH", Caml_option.some(Json.encodeDict(encodeBody(shopId, corporateName, shopName, email, phone, vatNumber, address, postalCode, city, country, shippingAddress, shippingPostalCode, shippingCity, shippingCountry, sameShippingAddressThanBilling))), true, undefined, endpoint), undefined, (function (param) {
                    
                  })), undefined, (function (error) {
                if (typeof error === "number" || error.TAG !== /* InvalidRequestFailures */2) {
                  return ;
                } else {
                  return Belt_Option.map(Belt_Array.get(error._0, 0), decodeInvalidRequestFailure);
                }
              }));
}

var BillingAccountEditRequest = {
  encodeBody: encodeBody,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure,
  endpoint: endpoint,
  make: make
};

function SettingsBillingAccountEditPage$NotificationBanner(Props) {
  var notification = Props.notification;
  return React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Banner.make, {
                          textStatus: notification
                        })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, Caml_option.some(Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.pct(100)), undefined, undefined)), undefined, undefined, undefined));
}

var make$1 = React.memo(SettingsBillingAccountEditPage$NotificationBanner);

var NotificationBanner = {
  make: make$1
};

function validateAddressValue(value, values) {
  if (!values.sameShippingAddressThanBilling && value === "") {
    return {
            TAG: /* Error */1,
            _0: $$Intl.t("Please fulfill this field.")
          };
  } else {
    return {
            TAG: /* Ok */0,
            _0: undefined
          };
  }
}

var schema = [
  {
    TAG: /* CustomString */8,
    _0: /* CorporateName */0,
    _1: (function (value, param) {
        if (CorporateEntity.CorporateName.validate(value)) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: $$Intl.t("The corporate name is invalid. Please provide the full name of the company.")
                };
        }
      })
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* ShopName */1
  },
  {
    TAG: /* Email */5,
    _0: /* Email */2
  },
  {
    TAG: /* PhoneNumber */4,
    _0: /* Phone */3
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* Address */5
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* PostalCode */7
  },
  {
    TAG: /* StringNotEmpty */1,
    _0: /* City */6
  },
  {
    TAG: /* CustomString */8,
    _0: /* ShippingAddress */9,
    _1: validateAddressValue
  },
  {
    TAG: /* CustomString */8,
    _0: /* ShippingPostalCode */10,
    _1: validateAddressValue
  },
  {
    TAG: /* CustomString */8,
    _0: /* ShippingCity */11,
    _1: validateAddressValue
  },
  {
    TAG: /* CustomString */8,
    _0: /* ShippingCountry */12,
    _1: validateAddressValue
  }
];

function SettingsBillingAccountEditPage(Props) {
  var activeShopId = Props.activeShopId;
  var corporateName = Props.corporateName;
  var shopName = Props.shopName;
  var email = Props.email;
  var phone = Props.phone;
  var vatNumber = Props.vatNumber;
  var billingAddress = Props.billingAddress;
  var shippingAddress = Props.shippingAddress;
  var requestBillingAccountUpdate = Props.requestBillingAccountUpdate;
  var jwt = Belt_Option.getWithDefault(Auth.getJwt(undefined), "");
  var logUser = Auth.useLogUser(undefined, undefined);
  var match = React.useState(function () {
        
      });
  var setNotfication = match[1];
  var notification = match[0];
  var onSubmitFailure = function (error) {
    Curry._1(setNotfication, (function (param) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          }));
  };
  var onSubmitSuccess = function (param) {
    Curry._1(logUser, jwt);
    var text = $$Intl.t("You have made changes to your billing information, please now verify your details in Shop settings.");
    Curry._1(setNotfication, (function (param) {
            return {
                    TAG: /* Ok */0,
                    _0: text
                  };
          }));
  };
  var sameShippingAddressThanBilling = billingAddress !== undefined ? (
      shippingAddress !== undefined ? CorporateEntity.Address.equal(billingAddress, shippingAddress) : false
    ) : shippingAddress === undefined;
  var billingAddress$1 = Belt_Option.getWithDefault(billingAddress, CorporateEntity.Address.initialValue);
  var shippingAddress$1 = Belt_Option.getWithDefault(shippingAddress, CorporateEntity.Address.initialValue);
  var formPropState = Curry._1(BillingAccountForm.useFormPropState, {
        initialValues: {
          corporateName: corporateName,
          shopName: shopName,
          email: email,
          phone: phone,
          vatNumber: Belt_Option.getWithDefault(vatNumber, ""),
          address: billingAddress$1.address,
          city: billingAddress$1.city,
          postalCode: billingAddress$1.postalCode,
          country: Belt_Result.getWithDefault(CountryCode.fromString(billingAddress$1.country), /* FR */0),
          shippingAddress: shippingAddress$1.address,
          shippingPostalCode: shippingAddress$1.postalCode,
          shippingCity: shippingAddress$1.city,
          shippingCountry: CountryCode.toMediumCountryString(Belt_Result.getWithDefault(CountryCode.fromString(shippingAddress$1.country), /* FR */0)),
          sameShippingAddressThanBilling: sameShippingAddressThanBilling
        },
        schema: schema,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var formState = formPropState[0];
  var validation = formState.validation;
  var submission = formState.submission;
  var values = formState.values;
  var formDispatch = formPropState[1];
  var submitting = Form__Submission.isRequested(submission);
  React.useEffect((function () {
          if (submitting) {
            Curry._1(setNotfication, (function (param) {
                    
                  }));
          }
          
        }), [submitting]);
  React.useEffect((function () {
          if (Belt_Option.isSome(notification)) {
            window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
          }
          
        }), [notification]);
  var onSubmit = function (param, param$1) {
    return Future.mapError(Future.mapOk(Curry.app(requestBillingAccountUpdate, [
                        activeShopId,
                        param$1.corporateName,
                        param$1.shopName,
                        param$1.email,
                        param$1.phone,
                        param$1.vatNumber,
                        param$1.address,
                        param$1.postalCode,
                        param$1.city,
                        param$1.country,
                        param$1.shippingAddress,
                        param$1.shippingPostalCode,
                        param$1.shippingCity,
                        param$1.shippingCountry,
                        param$1.sameShippingAddressThanBilling
                      ]), undefined, (function (param) {
                      return $$Intl.t("Account successfully set up.");
                    })), undefined, (function (failure) {
                  if (failure === undefined) {
                    return $$Intl.t("An unexpected error occured. Please try again or contact the support.");
                  }
                  switch (failure) {
                    case /* DuplicateUserUsername */0 :
                        return $$Intl.t("This email address is already used.");
                    case /* InvalidVatNumber */1 :
                        return $$Intl.t("The VAT number is not valid.");
                    case /* NotFoundBillingAccount */2 :
                        return $$Intl.t("Currently, a maintenance operation is underway on billing accounts. If you have an urgent need regarding these parameters, please contact the support.");
                    case /* Unknown */3 :
                        return $$Intl.t("An unexpected error occured. Please try again or contact the support.");
                    
                  }
                }));
  };
  var errorMessageAddress;
  errorMessageAddress = validation.TAG === /* Ok */0 || typeof submission === "number" || submission.TAG !== /* Failed */0 ? undefined : Belt_Array.get(Belt_Array.keepMap(validation._0, (function (param) {
                if (param[0]._0 !== 5) {
                  return ;
                } else {
                  return param[1];
                }
              })), 0);
  var onChangeAddress = function (value) {
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* Address */5,
          _1: (function (param) {
              return value;
            })
        });
  };
  var onRequestAddressAutoComplete = function (address) {
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* Address */5,
          _1: (function (param) {
              return address.name;
            })
        });
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* PostalCode */7,
          _1: (function (param) {
              return address.postcode;
            })
        });
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* City */6,
          _1: (function (param) {
              return address.city;
            })
        });
  };
  var errorMessageShippingAddress;
  errorMessageShippingAddress = validation.TAG === /* Ok */0 || typeof submission === "number" || submission.TAG !== /* Failed */0 ? undefined : Belt_Array.get(Belt_Array.keepMap(validation._0, (function (param) {
                if (param[0]._0 !== 9) {
                  return ;
                } else {
                  return param[1];
                }
              })), 0);
  var onChangeShippingAddress = function (value) {
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* ShippingAddress */9,
          _1: (function (param) {
              return value;
            })
        });
  };
  var onRequestShippingAddressAutoComplete = function (address) {
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* ShippingAddress */9,
          _1: (function (param) {
              return address.name;
            })
        });
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* ShippingPostalCode */10,
          _1: (function (param) {
              return address.postcode;
            })
        });
    Curry._1(formDispatch, {
          TAG: /* FieldValueChanged */2,
          _0: /* ShippingCity */11,
          _1: (function (param) {
              return address.city;
            })
        });
  };
  var match$1 = Navigation.useGoBack(undefined);
  var onGoBack = match$1[1];
  var cancelButton = match$1[0] ? React.createElement(Button.make, Button.makeProps($$Intl.t("Discard"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                Curry._1(onGoBack, undefined);
              }), undefined, undefined, undefined)) : React.createElement(BillingAccountForm.CancelButton.make, {
          text: $$Intl.t("Cancel"),
          size: "medium"
        });
  var actionsBar = React.createElement(ResourceDetailsPage.ActionsBar.make, {
        items: [
          cancelButton,
          React.createElement(BillingAccountForm.SubmitButton.make, {
                variation: "success",
                size: "medium",
                onSubmit: onSubmit,
                text: $$Intl.t("Save")
              })
        ]
      });
  var notificationBanner;
  if (notification !== undefined) {
    var onRequestClose = function (param) {
      Curry._1(setNotfication, (function (param) {
              
            }));
    };
    notificationBanner = React.createElement(ResourceDetailsPage.NotificationBanner.make, {
          value: notification,
          onRequestClose: onRequestClose
        });
  } else {
    notificationBanner = null;
  }
  var tmp = {
    required: true,
    addressName: values.address,
    onInputChange: onChangeAddress,
    onRequestAutoComplete: onRequestAddressAutoComplete
  };
  if (errorMessageAddress !== undefined) {
    tmp.errorMessage = Caml_option.valFromOption(errorMessageAddress);
  }
  var tmp$1;
  if (values.sameShippingAddressThanBilling) {
    tmp$1 = null;
  } else {
    var tmp$2 = {
      required: true,
      addressName: values.shippingAddress,
      onInputChange: onChangeShippingAddress,
      onRequestAutoComplete: onRequestShippingAddressAutoComplete
    };
    if (errorMessageShippingAddress !== undefined) {
      tmp$2.errorMessage = Caml_option.valFromOption(errorMessageShippingAddress);
    }
    tmp$1 = React.createElement(StackFields.make, {
          children: null
        }, React.createElement(SupplierAddressComboBoxField.make, tmp$2), React.createElement(Group.make, {
              children: null
            }, React.createElement(BillingAccountForm.InputText.make, {
                  field: /* ShippingPostalCode */10,
                  label: $$Intl.t("Postal code")
                }), React.createElement(BillingAccountForm.InputText.make, {
                  field: /* ShippingCity */11,
                  label: $$Intl.t("City")
                })), React.createElement(BillingAccountForm.InputText.make, {
              field: /* ShippingCountry */12,
              label: $$Intl.t("Country")
            }));
  }
  return React.createElement(BillingAccountForm.Core.Provider.make, {
              children: null,
              propState: formPropState
            }, React.createElement(BillingAccountForm.ControlEnterKey.make, {
                  onSubmit: onSubmit
                }), React.createElement(ResourceDetailsPage.make, {
                  title: $$Intl.t("Edit billing information"),
                  actionsBar: actionsBar,
                  notificationBanner: notificationBanner,
                  children: React.createElement(Stack.make, {
                        children: null,
                        space: "xlarge"
                      }, React.createElement(FieldsetLayoutPanel.make, {
                            title: $$Intl.t("General information"),
                            description: $$Intl.t("Complete the name of your company and associated information."),
                            children: null
                          }, React.createElement(BillingAccountForm.InputText.make, {
                                field: /* CorporateName */0,
                                label: $$Intl.t("Corporate name")
                              }), React.createElement(BillingAccountForm.InputText.make, {
                                field: /* ShopName */1,
                                label: $$Intl.t("Shop name")
                              }), React.createElement(BillingAccountForm.InputText.make, {
                                field: /* Email */2,
                                label: $$Intl.t("Billing email"),
                                placeholder: $$Intl.t("contact@shop.com")
                              }), React.createElement(BillingAccountForm.InputText.make, {
                                field: /* Phone */3,
                                label: $$Intl.t("Shop phone"),
                                placeholder: $$Intl.t("01 23 45 67 89")
                              }), React.createElement(BillingAccountForm.InputText.make, {
                                field: /* VatNumber */4,
                                label: $$Intl.t("VAT number"),
                                placeholder: $$Intl.t("FR 11 123123123")
                              })), React.createElement(FieldsetLayoutPanel.make, {
                            title: $$Intl.t("Billing address"),
                            description: $$Intl.t("Specify the address that will appear on your Wino subscription invoices."),
                            children: null
                          }, React.createElement(SupplierAddressComboBoxField.make, tmp), React.createElement(Group.make, {
                                children: null
                              }, React.createElement(BillingAccountForm.InputText.make, {
                                    field: /* PostalCode */7,
                                    label: $$Intl.t("Postal code")
                                  }), React.createElement(BillingAccountForm.InputText.make, {
                                    field: /* City */6,
                                    label: $$Intl.t("City")
                                  })), React.createElement(BillingAccountForm.InputSelect.make, {
                                field: /* Country */8,
                                label: $$Intl.t("Country"),
                                sections: [{
                                    items: Belt_Array.map(BillingAccount.acceptedCountryCodes, (function (value) {
                                            return {
                                                    key: CountryCode.toIso2String(value),
                                                    value: value,
                                                    label: $$Intl.t(CountryCode.toMediumCountryString(value))
                                                  };
                                          }))
                                  }]
                              })), React.createElement(FieldsetLayoutPanel.make, {
                            title: $$Intl.t("Shipping address"),
                            description: $$Intl.t("Provide the address where you would like to receive your cash register equipment."),
                            children: null
                          }, React.createElement(BillingAccountForm.InputToggleSwitch.make, {
                                label: $$Intl.t("Shipping address same as billing address."),
                                field: /* SameShippingAddressThanBilling */13
                              }), tmp$1))
                }));
}

var make$2 = React.memo(SettingsBillingAccountEditPage);

var requestBillingAccountUpdate = make;

export {
  BillingAccountFormLenses ,
  BillingAccountForm ,
  stripEmptyString ,
  BillingAccountEditRequest ,
  requestBillingAccountUpdate ,
  NotificationBanner ,
  validateAddressValue ,
  schema ,
  make$2 as make,
}
/* BillingAccountForm Not a pure module */
