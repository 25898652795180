// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$String from "rescript/lib/es6/string.js";
import * as OrderEdit from "../../app/Order/OrderEdit.bs.js";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as OrderStatus from "./OrderStatus.bs.js";
import * as RoundButton from "../../resources/actions/RoundButton.bs.js";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ProductPriceTableCell from "../Product/ProductPriceTableCell.bs.js";
import * as ProductStockTableCell from "../Product/ProductStockTableCell.bs.js";
import * as CartProductTaxTableCell from "../Cart/CartProductTaxTableCell.bs.js";
import * as CartProductFeesTableCell from "../Cart/CartProductFeesTableCell.bs.js";
import * as CartProductQuantityTableCell from "../Cart/CartProductQuantityTableCell.bs.js";
import * as CartProductDiscountsTableCell from "../Cart/CartProductDiscountsTableCell.bs.js";
import * as CartProductUnitPriceTableCell from "../Cart/CartProductUnitPriceTableCell.bs.js";
import * as LegacyProductReferenceTableCell from "../Product/LegacyProductReferenceTableCell.bs.js";

var ProductTaxNotFound = /* @__PURE__ */Caml_exceptions.create("OrderCartTable.ProductTaxNotFound");

function keyExtractor(row) {
  return row._0.id;
}

function tableColumns(statuses, hidden, taxesFree, editable, decimalPrecision, onRequestDispatch) {
  return [
          {
            key: "line",
            name: $$Intl.t("Line"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 65
              },
              width: {
                NAME: "px",
                VAL: 65
              }
            },
            render: (function (param) {
                var value = param.index + 1 | 0;
                var string = String(value);
                var length = 4 - string.length | 0;
                var leading = $$String.make(length, /* '0' */48);
                var formattedLineNumber = leading + string;
                return React.createElement(TextStyle.make, {
                            children: formattedLineNumber,
                            variation: "normal",
                            size: "xsmall"
                          });
              })
          },
          {
            key: "reference",
            name: $$Intl.t("Product"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 250
              },
              width: {
                NAME: "fr",
                VAL: 3
              },
              margin: "large",
              sticky: true
            },
            render: (function (param) {
                var product = param.data;
                var match;
                if (product.TAG === /* Unit */0) {
                  var match$1 = product._0;
                  match = [
                    match$1.identifier,
                    match$1.name,
                    match$1.description
                  ];
                } else {
                  var match$2 = product._0;
                  match = [
                    match$2.identifier,
                    match$2.name,
                    match$2.description
                  ];
                }
                var tmp = {
                  name: match[1],
                  description: match[2],
                  openNewTab: true
                };
                var tmp$1 = match[0];
                if (tmp$1 !== undefined) {
                  tmp.id = Caml_option.valFromOption(tmp$1);
                }
                if (param.errorMessage !== undefined) {
                  tmp.errorMessage = Caml_option.valFromOption(param.errorMessage);
                }
                return React.createElement(LegacyProductReferenceTableCell.make, tmp);
              })
          },
          {
            key: "stock",
            name: $$Intl.t("Current stock"),
            layout: {
              hidden: hidden === /* StatusAtAfterReception */1,
              minWidth: {
                NAME: "px",
                VAL: 80
              },
              width: {
                NAME: "pct",
                VAL: 7
              },
              alignX: editable ? "flexStart" : "center"
            },
            render: (function (param) {
                var product = param.data;
                var formattedStock;
                var exit = 0;
                var formattedStock$1;
                formattedStock$1 = product._0.formattedStock;
                exit = 1;
                if (exit === 1) {
                  formattedStock = Belt_Option.map(formattedStock$1, (function (value) {
                          return value.replace(".", ",");
                        }));
                }
                return React.createElement(ProductStockTableCell.make, {
                            value: formattedStock
                          });
              })
          },
          {
            key: "quantity",
            name: $$Intl.t("Ord. qt"),
            layout: {
              hidden: hidden === /* StatusAtAfterReception */1,
              minWidth: {
                NAME: "px",
                VAL: 120
              },
              width: {
                NAME: "fr",
                VAL: 1.5
              },
              alignX: editable ? "flexStart" : "center"
            },
            render: (function (param) {
                return React.createElement(CartProductQuantityTableCell.make, {
                            product: param.data,
                            editable: editable,
                            onRequestDispatch: onRequestDispatch
                          });
              })
          },
          {
            key: "reception_quantity",
            name: $$Intl.t("Rec. qt"),
            layout: {
              hidden: hidden === /* StatusBeforeReception */0,
              minWidth: {
                NAME: "px",
                VAL: 120
              },
              alignX: editable ? "flexStart" : "center"
            },
            render: (function (param) {
                return React.createElement(CartProductQuantityTableCell.make, {
                            product: param.data,
                            editable: editable && !OrderEdit.isLimitedEdition(statuses),
                            beforeOrderReception: false,
                            onRequestDispatch: onRequestDispatch
                          });
              })
          },
          {
            key: "unitPrice",
            name: $$Intl.t("U. price excl. VAT"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 140
              },
              width: {
                NAME: "fr",
                VAL: 1.5
              },
              margin: "normal"
            },
            render: (function (param) {
                return React.createElement(CartProductUnitPriceTableCell.make, {
                            product: param.data,
                            decimalPrecision: decimalPrecision,
                            editable: editable,
                            onRequestDispatch: onRequestDispatch
                          });
              })
          },
          {
            key: "discounts",
            name: $$Intl.t("U. discounts"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                return React.createElement(CartProductDiscountsTableCell.make, {
                            editable: editable,
                            decimalPrecision: decimalPrecision,
                            product: param.data,
                            onRequestDispatch: onRequestDispatch
                          });
              })
          },
          {
            key: "fees",
            name: $$Intl.t("U. fees"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                return React.createElement(CartProductFeesTableCell.make, {
                            editable: editable,
                            product: param.data,
                            decimalPrecision: decimalPrecision,
                            onRequestDispatch: onRequestDispatch
                          });
              })
          },
          {
            key: "amount",
            name: $$Intl.t("Total excl. VAT"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var product = param.data;
                var value;
                value = product._0.formattedTotalAmountExcludingGlobalDiscounts;
                return React.createElement(ProductPriceTableCell.make, {
                            value: value
                          });
              })
          },
          {
            key: "amountVAT",
            name: $$Intl.t("VAT total"),
            layout: {
              hidden: taxesFree,
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var product = param.data;
                var taxes;
                taxes = product._0.taxes;
                var match = Belt_Option.flatMap(taxes, (function (taxes) {
                        return Belt_Array.get(taxes, 0);
                      }));
                if (taxesFree) {
                  throw {
                        RE_EXN_ID: ProductTaxNotFound,
                        Error: new Error()
                      };
                }
                var tax;
                if (match !== undefined) {
                  tax = match;
                } else {
                  throw {
                        RE_EXN_ID: ProductTaxNotFound,
                        Error: new Error()
                      };
                }
                return React.createElement(CartProductTaxTableCell.make, {
                            tax: tax
                          });
              })
          },
          {
            key: "actions",
            layout: {
              hidden: (!editable || OrderStatus.has(statuses, "ARCHIVED") || hidden === /* StatusAtAfterReception */1) && !OrderStatus.has(statuses, "DRAFT"),
              minWidth: {
                NAME: "px",
                VAL: 70
              },
              width: {
                NAME: "px",
                VAL: 70
              }
            },
            render: (function (param) {
                var product = param.data;
                var productId;
                productId = product._0.id;
                return React.createElement(RoundButton.make, RoundButton.makeProps(undefined, "delete_light", undefined, undefined, undefined, (function (param) {
                                  Curry._1(onRequestDispatch, {
                                        TAG: /* ProductRemoved */2,
                                        _0: productId
                                      });
                                }), undefined, undefined, undefined));
              })
          }
        ];
}

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9, prim10, prim11, prim12, prim13, prim14, prim15, prim16, prim17, prim18, prim19, prim20, prim21, prim22, prim23, prim24, prim25, prim26, prim27) {
  var tmp = {
    data: prim0,
    columns: prim1,
    keyExtractor: prim2
  };
  if (prim3 !== undefined) {
    tmp.disabledRowsKeys = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.erroredRowsMap = Caml_option.valFromOption(prim4);
  }
  if (prim5 !== undefined) {
    tmp.selectionEnabled = prim5;
  }
  if (prim6 !== undefined) {
    tmp.selectAllEnabled = prim6;
  }
  if (prim7 !== undefined) {
    tmp.initialAllSelected = prim7;
  }
  if (prim8 !== undefined) {
    tmp.hideCard = prim8;
  }
  if (prim9 !== undefined) {
    tmp.hideReloadingPlaceholder = prim9;
  }
  if (prim10 !== undefined) {
    tmp.maxWidth = prim10;
  }
  if (prim11 !== undefined) {
    tmp.minHeight = prim11;
  }
  if (prim12 !== undefined) {
    tmp.maxHeight = prim12;
  }
  if (prim13 !== undefined) {
    tmp.compactRows = prim13;
  }
  if (prim14 !== undefined) {
    tmp.placeholderEmptyState = Caml_option.valFromOption(prim14);
  }
  if (prim15 !== undefined) {
    tmp.searchBar = Caml_option.valFromOption(prim15);
  }
  if (prim16 !== undefined) {
    tmp.searchPlaceholder = prim16;
  }
  if (prim17 !== undefined) {
    tmp.filters = Caml_option.valFromOption(prim17);
  }
  if (prim18 !== undefined) {
    tmp.typesDrop = Caml_option.valFromOption(prim18);
  }
  if (prim19 !== undefined) {
    tmp.sortDescriptor = Caml_option.valFromOption(prim19);
  }
  if (prim20 !== undefined) {
    tmp.onSortChange = Caml_option.valFromOption(prim20);
  }
  if (prim21 !== undefined) {
    tmp.onSearchQueryChange = Caml_option.valFromOption(prim21);
  }
  if (prim22 !== undefined) {
    tmp.onLoadMore = Caml_option.valFromOption(prim22);
  }
  if (prim23 !== undefined) {
    tmp.onSelectChange = Caml_option.valFromOption(prim23);
  }
  if (prim24 !== undefined) {
    tmp.onSuccessDrop = Caml_option.valFromOption(prim24);
  }
  if (prim25 !== undefined) {
    tmp.onErrorDrop = Caml_option.valFromOption(prim25);
  }
  if (prim26 !== undefined) {
    tmp.key = prim26;
  }
  return tmp;
}

var make = TableView.make;

export {
  ProductTaxNotFound ,
  keyExtractor ,
  tableColumns ,
  make ,
  makeProps ,
}
/* Intl Not a pure module */
