// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../layout-and-structure/Box.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as View from "../../primitives/View.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Style from "../../primitives/Style.bs.js";
import * as React from "react";
import * as Inline from "../layout-and-structure/Inline.bs.js";
import * as TextStyle from "../typography/TextStyle.bs.js";
import * as $$StyleSheet from "../../primitives/StyleSheet.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateHelpers from "../../helpers/DateHelpers.bs.js";
import * as DayPickerRange from "./DayPickerRange.bs.js";
import * as TextIconButton from "../actions/TextIconButton.bs.js";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as InputRadioGroupField from "./InputRadioGroupField.bs.js";

var maxDate = DateHelpers.endOfDay(new Date());

function isOutsideRange(date) {
  return date.getTime() > maxDate.getTime();
}

var styles = $$StyleSheet.create({
      presets: Style.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#e7e7ee", Style.hairlineWidth, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style.dp(342)), undefined, undefined, Caml_option.some(Style.dp(20)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

function DateRangePicker(Props) {
  var focusedInputOpt = Props.focusedInput;
  var visibleMonth = Props.visibleMonth;
  var activePreset = Props.activePreset;
  var presets = Props.presets;
  var formatPreset = Props.formatPreset;
  var isEqualPreset = Props.isEqualPreset;
  var range = Props.range;
  var disabledResetButtonOpt = Props.disabledResetButton;
  var onChangePreset = Props.onChangePreset;
  var onRequestReset = Props.onRequestReset;
  var onDatesChange = Props.onDatesChange;
  var onFocusChange = Props.onFocusChange;
  var onPrevMonthClick = Props.onPrevMonthClick;
  var onNextMonthClick = Props.onNextMonthClick;
  var focusedInput = focusedInputOpt !== undefined ? focusedInputOpt : DayPickerRange.defaultFocusedInput;
  var disabledResetButton = disabledResetButtonOpt !== undefined ? disabledResetButtonOpt : false;
  var onPrevMonthClick$1 = Curry.__1(onPrevMonthClick);
  var onNextMonthClick$1 = Curry.__1(onNextMonthClick);
  var initialVisibleMonth = function (param) {
    return visibleMonth;
  };
  return React.createElement(Inline.make, {
              children: null
            }, React.createElement(View.make, View.makeProps(Caml_option.some(null), Caml_option.some(styles.presets), undefined, undefined, undefined), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                  children: $$Intl.t("Period"),
                                  variation: "normal",
                                  weight: "semibold",
                                  size: "xxsmall"
                                })), undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(InputRadioGroupField.make, {
                      value: activePreset,
                      required: false,
                      onChange: onChangePreset,
                      isEqualValue: isEqualPreset,
                      options: presets,
                      optionToText: formatPreset
                    }), disabledResetButton ? null : React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextIconButton.make, TextIconButton.makeProps($$Intl.t("Reset period"), undefined, "small", (Js_null_undefined.fromOption(range[1]) == null), "delete_light", undefined, onRequestReset, undefined, undefined, undefined))), undefined, "xnormal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(DayPickerRange.make, {
                  startDate: range[0],
                  endDate: range[1],
                  maxDate: maxDate,
                  initialVisibleMonth: initialVisibleMonth,
                  isOutsideRange: isOutsideRange,
                  focusedInput: focusedInput,
                  onChange: onDatesChange,
                  onFocusChange: onFocusChange,
                  onPrevMonthClick: onPrevMonthClick$1,
                  onNextMonthClick: onNextMonthClick$1,
                  key: visibleMonth.toDateString()
                }));
}

var make = DateRangePicker;

export {
  maxDate ,
  isOutsideRange ,
  make ,
}
/* maxDate Not a pure module */
