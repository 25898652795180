// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Env from "../../core/Env.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as $$Request from "../../core/Request.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var acceptedCountryCodes = [
  /* FR */0,
  /* BE */3,
  /* LU */4
];

function fromString(value) {
  switch (value) {
    case "boost" :
        return {
                TAG: /* Ok */0,
                _0: /* Boost */2
              };
    case "company" :
        return {
                TAG: /* Ok */0,
                _0: /* Company */1
              };
    case "standard" :
        return {
                TAG: /* Ok */0,
                _0: /* Standard */0
              };
    case "warehouse" :
        return {
                TAG: /* Ok */0,
                _0: /* Warehouse */3
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function toString(value) {
  switch (value) {
    case /* Standard */0 :
        return "Standard";
    case /* Company */1 :
        return "Company";
    case /* Boost */2 :
        return "Boost";
    case /* Warehouse */3 :
        return "Warehouse";
    
  }
}

var BillingPlanKind = {
  fromString: fromString,
  toString: toString
};

function getPriority(issue) {
  return issue + 1 | 0;
}

function fromString$1(value) {
  switch (value) {
    case "InvalidBillingMandate" :
        return {
                TAG: /* Ok */0,
                _0: /* InvalidBillingMandate */1
              };
    case "InvoicePaymentOverdue" :
        return {
                TAG: /* Ok */0,
                _0: /* InvoicePaymentOverdue */2
              };
    case "MissingPaymentMethod" :
        return {
                TAG: /* Ok */0,
                _0: /* MissingPaymentMethod */0
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function mostImportantShopIssue(issues) {
  return Belt_Array.reduce(issues, undefined, (function (mostImportant, current) {
                if (mostImportant !== undefined && (current.issue + 1 | 0) >= (mostImportant.issue + 1 | 0)) {
                  return mostImportant;
                } else {
                  return current;
                }
              }));
}

function mostImportantIssue(issues) {
  return Belt_Array.reduce(issues, undefined, (function (mostImportant, current) {
                if (mostImportant !== undefined && (current + 1 | 0) >= (mostImportant + 1 | 0)) {
                  return mostImportant;
                } else {
                  return current;
                }
              }));
}

var BillingIssue = {
  getPriority: getPriority,
  fromString: fromString$1,
  mostImportantShopIssue: mostImportantShopIssue,
  mostImportantIssue: mostImportantIssue
};

function decodeBillingIssues(failure) {
  var match = fromString$1(failure.kind);
  var match$1 = Json.flatDecodeDictFieldString(Belt_Option.flatMap(failure.data, Json.decodeDict), "shopId");
  if (match.TAG === /* Ok */0 && match$1 !== undefined) {
    return {
            shopId: match$1,
            issue: match._0
          };
  }
  
}

function make(param) {
  return Future.map($$Request.make("GET", undefined, undefined, undefined, Env.gatewayUrl(undefined) + "/billing-accounts/audit"), undefined, (function (result) {
                if (result.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: result._0
                        };
                }
                var arr = Json.decodeArray(result._0);
                if (arr === undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: undefined
                        };
                }
                var failures = $$Request.decodeInvalidRequestFailures(arr);
                if (failures !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: Belt_Array.keepMap(failures, decodeBillingIssues)
                        };
                } else {
                  return {
                          TAG: /* Ok */0,
                          _0: undefined
                        };
                }
              }));
}

var StatusesRequest = {
  decodeBillingIssues: decodeBillingIssues,
  make: make
};

function fromString$2(status) {
  switch (status) {
    case "open" :
        return /* Open */0;
    case "paid" :
        return /* Paid */1;
    case "uncollectible" :
        return /* Uncollectible */2;
    case "void" :
        return /* Void */3;
    default:
      return ;
  }
}

function toString$1(status) {
  switch (status) {
    case /* Open */0 :
        return "Amount due";
    case /* Paid */1 :
        return "Paid";
    case /* Uncollectible */2 :
        return "Uncollectible";
    case /* Void */3 :
        return "Credited balance";
    
  }
}

var InvoiceStatus = {
  fromString: fromString$2,
  toString: toString$1
};

function decodeInvoice(json) {
  var dict = Json.decodeDict(json);
  var match = Json.flatDecodeDictFieldString(dict, "id");
  var match$1 = Json.flatDecodeDictFieldString(dict, "number");
  var match$2 = Json.flatDecodeDictFieldFloat(dict, "total");
  var match$3 = Json.flatDecodeDictFieldString(dict, "status");
  var match$4 = Json.flatDecodeDictFieldString(dict, "paymentLink");
  var match$5 = Json.flatDecodeDictFieldString(dict, "pdfLink");
  var match$6 = Json.flatDecodeDictFieldFloat(dict, "date");
  if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$6 !== undefined) {
    return {
            id: match,
            number: match$1,
            total: match$2,
            status: match$3 !== undefined ? fromString$2(match$3) : undefined,
            paymentLink: match$4,
            pdfLink: match$5,
            date: new Date(match$6)
          };
  }
  
}

function decodeResult(json) {
  var resultItems = Json.decodeArray(json);
  if (resultItems !== undefined) {
    return Belt_Array.keepMap(resultItems, decodeInvoice);
  } else {
    return [];
  }
}

var endpoint = Env.gatewayUrl(undefined) + "/customer-invoices/";

function make$1(shopId) {
  return Future.mapOk($$Request.make("GET", undefined, undefined, undefined, endpoint + shopId), undefined, decodeResult);
}

var InvoicesRequest = {
  decodeInvoice: decodeInvoice,
  decodeResult: decodeResult,
  endpoint: endpoint,
  make: make$1
};

var invoicesRequest = make$1;

export {
  acceptedCountryCodes ,
  BillingPlanKind ,
  BillingIssue ,
  StatusesRequest ,
  InvoiceStatus ,
  InvoicesRequest ,
  invoicesRequest ,
}
/* endpoint Not a pure module */
