// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Menu from "../../resources/navigation/Menu.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as MenuItem from "../../resources/navigation/MenuItem.bs.js";
import * as PromotionEditFormMutations from "./PromotionEditFormMutations.bs.js";

function PromotionEditActions(Props) {
  var variation = Props.variation;
  var id = Props.id;
  var status = Props.status;
  var scope = Auth.useScope(undefined);
  var startCampaign = PromotionEditFormMutations.useStartCampaign(id);
  var stopCampaign = PromotionEditFormMutations.useStopCampaign(id);
  if (!(status === "ONGOING" || status === "NOT_PROGRAMMED" || status === "PROGRAMMED")) {
    return null;
  }
  if (variation === "actionBar") {
    if (!(status === "ONGOING" || status === "PROGRAMMED")) {
      if (status === "NOT_PROGRAMMED" && scope.TAG !== /* Organisation */0) {
        return React.createElement(Button.make, Button.makeProps($$Intl.t("Schedule"), "medium", "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                          Curry._1(startCampaign, status);
                        }), undefined, undefined, undefined));
      } else {
        return null;
      }
    }
    if (scope.TAG === /* Organisation */0) {
      return null;
    }
    var label = status === "PROGRAMMED" ? $$Intl.t("Unschedule") : $$Intl.t("Stop");
    return React.createElement(Button.make, Button.makeProps(label, "medium", "danger", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(stopCampaign, status);
                    }), undefined, undefined, undefined));
  }
  var tmp;
  if (status === "ONGOING" || status === "PROGRAMMED") {
    var text = {
      TAG: /* Text */0,
      _0: status === "PROGRAMMED" ? $$Intl.t("Unschedule") : $$Intl.t("Stop")
    };
    tmp = React.createElement(MenuItem.make, {
          content: text,
          action: {
            TAG: /* Callback */0,
            _0: (function (param) {
                Curry._1(stopCampaign, status);
              })
          }
        });
  } else {
    tmp = status === "NOT_PROGRAMMED" ? React.createElement(MenuItem.make, {
            content: {
              TAG: /* Text */0,
              _0: $$Intl.t("Schedule")
            },
            action: {
              TAG: /* Callback */0,
              _0: (function (param) {
                  Curry._1(startCampaign, status);
                })
            }
          }) : null;
  }
  return React.createElement(Inline.make, {
              children: React.createElement(Menu.make, {
                    children: tmp,
                    variation: "more_round"
                  }),
              align: "end"
            });
}

var make = React.memo(PromotionEditActions);

var Mutations;

export {
  Mutations ,
  make ,
}
/* make Not a pure module */
