// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Spaces from "../theme/Spaces.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      container: {
        display: "flex",
        flexWrap: "wrap"
      }
    });

function containerStyleProps(spaceX, spaceY) {
  var columnGapStr = String(Spaces.toFloat(spaceX));
  var rowGapStr = String(Spaces.toFloat(spaceY));
  return Stylex.props(styles.container, {
              columnGap: "" + columnGapStr + "px",
              rowGap: "" + rowGapStr + "px"
            });
}

function itemStyleProps(flexBasisPct, spaceX, gridLength) {
  var gapPx = "" + String(Spaces.toFloat(spaceX)) + "px";
  var gridLength$1 = String(gridLength);
  return Stylex.props({
              flexBasis: "calc(" + flexBasisPct + " - " + gapPx + " / " + gridLength$1 + " * (" + gridLength$1 + " - 1))",
              flexGrow: "1",
              flexShrink: "1"
            });
}

function Group(Props) {
  var children = Props.children;
  var grid = Props.grid;
  var spaceXOpt = Props.spaceX;
  var spaceYOpt = Props.spaceY;
  var spaceX = spaceXOpt !== undefined ? spaceXOpt : "large";
  var spaceY = spaceYOpt !== undefined ? spaceYOpt : "large";
  var childrenLength = React.Children.toArray(children).length;
  var defaultFlexBasisPct = Math.max(Caml_int32.div(100, childrenLength) - childrenLength | 0, childrenLength - 2 | 0);
  var containerStyleProps$1 = containerStyleProps(spaceX, spaceY);
  var tmp = {};
  if (containerStyleProps$1.className !== undefined) {
    tmp.className = Caml_option.valFromOption(containerStyleProps$1.className);
  }
  if (containerStyleProps$1.style !== undefined) {
    tmp.style = Caml_option.valFromOption(containerStyleProps$1.style);
  }
  return React.createElement("div", tmp, Belt_Array.mapWithIndex(React.Children.toArray(children), (function (idx, child) {
                    var flexBasisPct = Belt_Option.getWithDefault(Belt_Option.flatMap(grid, (function (grid) {
                                return Belt_Array.get(grid, Caml_int32.mod_(idx, grid.length));
                              })), "" + String(defaultFlexBasisPct) + "%");
                    var match = itemStyleProps(flexBasisPct, spaceX, Belt_Option.mapWithDefault(grid, childrenLength, (function (value) {
                                return value.length;
                              })));
                    var rootKey = Belt_Array.joinWith(Belt_Option.getWithDefault(grid, []), "", (function (a) {
                            return a;
                          }));
                    var tmp = {
                      key: "" + rootKey + "" + String(idx) + ""
                    };
                    if (match.className !== undefined) {
                      tmp.className = Caml_option.valFromOption(match.className);
                    }
                    if (match.style !== undefined) {
                      tmp.style = Caml_option.valFromOption(match.style);
                    }
                    return React.createElement("div", tmp, child);
                  })));
}

var make = React.memo(Group);

export {
  make ,
}
/* styles Not a pure module */
