// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as Card from "../../resources/layout-and-structure/Card.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DeviceName from "../DeviceName.bs.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Client from "@apollo/client";
import * as ShowAllDataLink from "../../resources/navigation/ShowAllDataLink.bs.js";
import * as StockActivityKind from "../StockActivity/StockActivityKind.bs.js";
import * as TableDatetimeCell from "../../resources/tables/TableDatetimeCell.bs.js";
import * as StockActivityTypeTableCell from "../StockActivity/StockActivityTypeTableCell.bs.js";
import * as StockActivitySourceTableCell from "../StockActivity/StockActivitySourceTableCell.bs.js";
import * as StockActivityQuantityTableCell from "../StockActivity/StockActivityQuantityTableCell.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query stockActivitiesByVariantCku($cku: CKU!, $first: Int, $filterBy: InputStockActivitiesQueryFilter)  {\nstockActivitiesByVariantCku(cku: $cku, first: $first, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nkind  \nreason  \ncomment  \nshop  {\n__typename  \nname  \n}\n\ndevice  {\n__typename  \nname  \nslug  \n}\n\ncreatedAt  \nquantity  \nvariant  {\n__typename  \nid  \ncku  \nbulk  \ncapacityPrecision  \ncapacityUnit  \nproduct  {\n__typename  \nid  \ncolor  \n}\n\n}\n\n}\n\n}\n\npageInfo  {\n__typename  \nhasNextPage  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.stockActivitiesByVariantCku;
  var value$2 = value$1.edges;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  return {
          stockActivitiesByVariantCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.kind;
                    var tmp;
                    switch (value$2) {
                      case "CREDIT_MEMO" :
                          tmp = "CREDIT_MEMO";
                          break;
                      case "DELIVERY" :
                          tmp = "DELIVERY";
                          break;
                      case "DELIVERY_RECEIPT" :
                          tmp = "DELIVERY_RECEIPT";
                          break;
                      case "INCOMING_TRANSFER" :
                          tmp = "INCOMING_TRANSFER";
                          break;
                      case "LOSS" :
                          tmp = "LOSS";
                          break;
                      case "OUTGOING_TRANSFER" :
                          tmp = "OUTGOING_TRANSFER";
                          break;
                      case "RECEPTION" :
                          tmp = "RECEPTION";
                          break;
                      case "REFUND" :
                          tmp = "REFUND";
                          break;
                      case "RESET" :
                          tmp = "RESET";
                          break;
                      case "SALE" :
                          tmp = "SALE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$3 = value$1.reason;
                    var tmp$1;
                    if (value$3 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$3) {
                        case "CORKY" :
                            tmp$2 = "CORKY";
                            break;
                        case "DAMAGE" :
                            tmp$2 = "DAMAGE";
                            break;
                        case "LOSS" :
                            tmp$2 = "LOSS";
                            break;
                        case "OTHER" :
                            tmp$2 = "OTHER";
                            break;
                        case "OUTDATED_DLUO" :
                            tmp$2 = "OUTDATED_DLUO";
                            break;
                        case "SALE_BY_GLASS" :
                            tmp$2 = "SALE_BY_GLASS";
                            break;
                        case "TASTING" :
                            tmp$2 = "TASTING";
                            break;
                        case "THEFT" :
                            tmp$2 = "THEFT";
                            break;
                        case "TRADE_IN_SUPPLIER" :
                            tmp$2 = "TRADE_IN_SUPPLIER";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$4 = value$1.comment;
                    var value$5 = value$1.shop;
                    var value$6 = value$1.device;
                    var value$7 = value$1.variant;
                    var tmp$3;
                    if (value$7 == null) {
                      tmp$3 = undefined;
                    } else {
                      var value$8 = value$7.bulk;
                      var value$9 = value$7.capacityPrecision;
                      var value$10 = value$7.capacityUnit;
                      var value$11 = value$7.product;
                      var value$12 = value$11.color;
                      var tmp$4;
                      if (value$12 == null) {
                        tmp$4 = undefined;
                      } else {
                        var tmp$5;
                        switch (value$12) {
                          case "AMBER" :
                              tmp$5 = "AMBER";
                              break;
                          case "BLACK" :
                              tmp$5 = "BLACK";
                              break;
                          case "BLOND" :
                              tmp$5 = "BLOND";
                              break;
                          case "DARK" :
                              tmp$5 = "DARK";
                              break;
                          case "ORANGE" :
                              tmp$5 = "ORANGE";
                              break;
                          case "RED" :
                              tmp$5 = "RED";
                              break;
                          case "ROSE" :
                              tmp$5 = "ROSE";
                              break;
                          case "WHITE" :
                              tmp$5 = "WHITE";
                              break;
                          default:
                            throw {
                                  RE_EXN_ID: "Not_found",
                                  Error: new Error()
                                };
                        }
                        tmp$4 = tmp$5;
                      }
                      tmp$3 = {
                        __typename: value$7.__typename,
                        id: value$7.id,
                        cku: Scalar.CKU.parse(value$7.cku),
                        bulk: !(value$8 == null) ? value$8 : undefined,
                        capacityPrecision: !(value$9 == null) ? value$9 : undefined,
                        capacityUnit: !(value$10 == null) ? value$10 : undefined,
                        product: {
                          __typename: value$11.__typename,
                          id: value$11.id,
                          color: tmp$4
                        }
                      };
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              kind: tmp,
                              reason: tmp$1,
                              comment: !(value$4 == null) ? Scalar.$$Text.parse(value$4) : undefined,
                              shop: {
                                __typename: value$5.__typename,
                                name: value$5.name
                              },
                              device: {
                                __typename: value$6.__typename,
                                name: value$6.name,
                                slug: value$6.slug
                              },
                              createdAt: Scalar.Datetime.parse(value$1.createdAt),
                              quantity: value$1.quantity,
                              variant: tmp$3
                            }
                          };
                  }), value$2),
            pageInfo: {
              __typename: value$3.__typename,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.stockActivitiesByVariantCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.hasNextPage;
  var hasNextPage = value$3 !== undefined ? value$3 : null;
  var value$4 = value$2.__typename;
  var pageInfo = {
    __typename: value$4,
    hasNextPage: hasNextPage
  };
  var value$5 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variant;
          var variant;
          if (value$2 !== undefined) {
            var value$3 = value$2.product;
            var value$4 = value$3.color;
            var color = value$4 !== undefined ? (
                value$4 === "ORANGE" ? "ORANGE" : (
                    value$4 === "RED" ? "RED" : (
                        value$4 === "AMBER" ? "AMBER" : (
                            value$4 === "DARK" ? "DARK" : (
                                value$4 === "BLACK" ? "BLACK" : (
                                    value$4 === "BLOND" ? "BLOND" : (
                                        value$4 === "ROSE" ? "ROSE" : "WHITE"
                                      )
                                  )
                              )
                          )
                      )
                  )
              ) : null;
            var value$5 = value$3.id;
            var value$6 = value$3.__typename;
            var product = {
              __typename: value$6,
              id: value$5,
              color: color
            };
            var value$7 = value$2.capacityUnit;
            var capacityUnit = value$7 !== undefined ? value$7 : null;
            var value$8 = value$2.capacityPrecision;
            var capacityPrecision = value$8 !== undefined ? value$8 : null;
            var value$9 = value$2.bulk;
            var bulk = value$9 !== undefined ? value$9 : null;
            var value$10 = value$2.cku;
            var value$11 = Scalar.CKU.serialize(value$10);
            var value$12 = value$2.id;
            var value$13 = value$2.__typename;
            variant = {
              __typename: value$13,
              id: value$12,
              cku: value$11,
              bulk: bulk,
              capacityPrecision: capacityPrecision,
              capacityUnit: capacityUnit,
              product: product
            };
          } else {
            variant = null;
          }
          var value$14 = value$1.quantity;
          var value$15 = value$1.createdAt;
          var value$16 = Scalar.Datetime.serialize(value$15);
          var value$17 = value$1.device;
          var value$18 = value$17.slug;
          var value$19 = value$17.name;
          var value$20 = value$17.__typename;
          var device = {
            __typename: value$20,
            name: value$19,
            slug: value$18
          };
          var value$21 = value$1.shop;
          var value$22 = value$21.name;
          var value$23 = value$21.__typename;
          var shop = {
            __typename: value$23,
            name: value$22
          };
          var value$24 = value$1.comment;
          var comment = value$24 !== undefined ? Scalar.$$Text.serialize(value$24) : null;
          var value$25 = value$1.reason;
          var reason = value$25 !== undefined ? (
              value$25 === "DAMAGE" ? "DAMAGE" : (
                  value$25 === "OUTDATED_DLUO" ? "OUTDATED_DLUO" : (
                      value$25 === "SALE_BY_GLASS" ? "SALE_BY_GLASS" : (
                          value$25 === "THEFT" ? "THEFT" : (
                              value$25 === "TASTING" ? "TASTING" : (
                                  value$25 === "LOSS" ? "LOSS" : (
                                      value$25 === "OTHER" ? "OTHER" : (
                                          value$25 === "TRADE_IN_SUPPLIER" ? "TRADE_IN_SUPPLIER" : "CORKY"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$26 = value$1.kind;
          var kind = value$26 === "DELIVERY_RECEIPT" ? "DELIVERY_RECEIPT" : (
              value$26 === "RESET" ? "RESET" : (
                  value$26 === "INCOMING_TRANSFER" ? "INCOMING_TRANSFER" : (
                      value$26 === "DELIVERY" ? "DELIVERY" : (
                          value$26 === "REFUND" ? "REFUND" : (
                              value$26 === "LOSS" ? "LOSS" : (
                                  value$26 === "CREDIT_MEMO" ? "CREDIT_MEMO" : (
                                      value$26 === "SALE" ? "SALE" : (
                                          value$26 === "RECEPTION" ? "RECEPTION" : "OUTGOING_TRANSFER"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            );
          var value$27 = value$1.id;
          var value$28 = value$1.__typename;
          var node = {
            __typename: value$28,
            id: value$27,
            kind: kind,
            reason: reason,
            comment: comment,
            shop: shop,
            device: device,
            createdAt: value$16,
            quantity: value$14,
            variant: variant
          };
          var value$29 = value.__typename;
          return {
                  __typename: value$29,
                  node: node
                };
        }), value$5);
  var value$6 = value$1.__typename;
  var stockActivitiesByVariantCku = {
    __typename: value$6,
    edges: edges,
    pageInfo: pageInfo
  };
  return {
          stockActivitiesByVariantCku: stockActivitiesByVariantCku
        };
}

function serializeInputObjectInOrNotInFilter(inp) {
  var a = inp._in;
  var a$1 = inp._notIn;
  return {
          _in: a !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a) : undefined,
          _notIn: a$1 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$1) : undefined
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInputStockActivitiesQueryFilter(inp) {
  var a = inp.kind;
  var a$1 = inp.reason;
  var a$2 = inp.date;
  var a$3 = inp.shopIds;
  return {
          kind: a !== undefined ? serializeInputObjectInOrNotInFilter(a) : undefined,
          reason: a$1 !== undefined ? serializeInputObjectInFilter(a$1) : undefined,
          date: a$2 !== undefined ? serializeInputObjectDateFilter(a$2) : undefined,
          shopIds: a$3 !== undefined ? serializeInputObjectInFilter(a$3) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.first;
  var a$1 = inp.filterBy;
  return {
          cku: inp.cku,
          first: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputStockActivitiesQueryFilter(a$1) : undefined
        };
}

function makeVariables(cku, first, filterBy, param) {
  return {
          cku: cku,
          first: first,
          filterBy: filterBy
        };
}

function makeInputObjectInputStockActivitiesQueryFilter(kind, reason, date, shopIds, param) {
  return {
          kind: kind,
          reason: reason,
          date: date,
          shopIds: shopIds
        };
}

function makeInputObjectInOrNotInFilter(_in, _notIn, param) {
  return {
          _in: _in,
          _notIn: _notIn
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

var StockActivitiesQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputStockActivitiesQueryFilter: serializeInputObjectInputStockActivitiesQueryFilter,
  serializeInputObjectInOrNotInFilter: serializeInputObjectInOrNotInFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputStockActivitiesQueryFilter: makeInputObjectInputStockActivitiesQueryFilter,
  makeInputObjectInOrNotInFilter: makeInputObjectInOrNotInFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var useLazy = include.useLazy;

var StockActivitiesQuery_refetchQueryDescription = include.refetchQueryDescription;

var StockActivitiesQuery_use = include.use;

var StockActivitiesQuery_useLazyWithVariables = include.useLazyWithVariables;

var StockActivitiesQuery = {
  StockActivitiesQuery_inner: StockActivitiesQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputStockActivitiesQueryFilter: serializeInputObjectInputStockActivitiesQueryFilter,
  serializeInputObjectInOrNotInFilter: serializeInputObjectInOrNotInFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  makeVariables: makeVariables,
  makeInputObjectInputStockActivitiesQueryFilter: makeInputObjectInputStockActivitiesQueryFilter,
  makeInputObjectInOrNotInFilter: makeInputObjectInOrNotInFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  refetchQueryDescription: StockActivitiesQuery_refetchQueryDescription,
  use: StockActivitiesQuery_use,
  useLazy: useLazy,
  useLazyWithVariables: StockActivitiesQuery_useLazyWithVariables
};

function keyExtractor(row) {
  return row.id + "-activity";
}

var Row = {
  keyExtractor: keyExtractor
};

var columns = [
  {
    key: "activity-type",
    name: $$Intl.t("Type"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 130
      },
      width: {
        NAME: "fr",
        VAL: 1
      }
    },
    render: (function (param) {
        var match = param.data;
        return React.createElement(StockActivityTypeTableCell.make, {
                    value: match.formattedKind,
                    reason: match.reason
                  });
      })
  },
  {
    key: "activity-quantity",
    name: $$Intl.t("Quantity"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 70
      },
      width: {
        NAME: "fr",
        VAL: 1
      },
      alignX: "center"
    },
    render: (function (param) {
        var match = param.data;
        var tmp = {
          value: match.quantity,
          kind: match.kind
        };
        if (match.variantCapacityPrecision !== undefined) {
          tmp.capacityPrecision = Caml_option.valFromOption(match.variantCapacityPrecision);
        }
        if (match.variantCapacityUnit !== undefined) {
          tmp.capacityUnit = Caml_option.valFromOption(match.variantCapacityUnit);
        }
        return React.createElement(StockActivityQuantityTableCell.make, tmp);
      })
  },
  {
    key: "activity-description",
    name: $$Intl.t("Comment"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 130
      },
      width: {
        NAME: "fr",
        VAL: 1.5
      },
      margin: "normal"
    },
    render: (function (param) {
        return React.createElement(TextStyle.make, {
                    children: Belt_Option.getWithDefault(param.data.description, "-")
                  });
      })
  },
  {
    key: "activity-datetime",
    name: $$Intl.t("Datetime"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      }
    },
    render: (function (param) {
        return React.createElement(TableDatetimeCell.make, {
                    value: param.data.date
                  });
      })
  },
  {
    key: "activity-source",
    name: $$Intl.t("Source"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 150
      }
    },
    render: (function (param) {
        var match = param.data;
        return React.createElement(StockActivitySourceTableCell.make, {
                    deviceName: match.deviceName,
                    shopName: match.shopName
                  });
      })
  }
];

function CatalogVariantStockActivityTableCard(Props) {
  var cku = Props.cku;
  var match = Curry.app(useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* CacheAndNetwork */0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryResults = match[1];
  var executeQuery = match[0];
  var activeShop = Auth.useActiveShop(undefined);
  var activeShopId = Belt_Option.map(activeShop, (function (shop) {
          return shop.id;
        }));
  var match$1 = Navigation.useUrl(undefined);
  React.useEffect((function () {
          Curry._3(executeQuery, undefined, undefined, {
                cku: Scalar.CKU.serialize(cku),
                first: 5,
                filterBy: makeInputObjectInputStockActivitiesQueryFilter(undefined, undefined, undefined, activeShopId !== undefined ? ({
                          _in: [activeShopId]
                        }) : undefined, undefined)
              });
        }), [activeShopId]);
  var tmp;
  var exit = 0;
  if (queryResults.TAG === /* Executed */0) {
    var match$2 = queryResults._0;
    var match$3 = match$2.data;
    if (match$3 !== undefined) {
      var match$4 = match$3.stockActivitiesByVariantCku;
      var rows = Belt_Array.keepMap(match$4.edges, (function (param) {
              var node = param.node;
              var variant = node.variant;
              if (variant === undefined) {
                return ;
              }
              var match = variant.bulk;
              var match$1 = variant.capacityPrecision;
              var match$2 = variant.bulk;
              var match$3 = variant.capacityUnit;
              return {
                      id: node.id,
                      formattedKind: StockActivityKind.toLabel(node.kind),
                      kind: node.kind,
                      reason: node.reason,
                      quantity: node.quantity,
                      variantCapacityPrecision: match !== undefined && match && match$1 !== undefined ? match$1 : undefined,
                      variantCapacityUnit: match$2 !== undefined && match$2 && match$3 !== undefined ? match$3 : undefined,
                      description: node.comment,
                      date: node.createdAt,
                      shopName: node.shop.name,
                      deviceName: DeviceName.format(DeviceName.decode(node.device.slug, node.device.name))
                    };
            }));
      var placeholderEmptyState = React.createElement(Placeholder.make, {
            status: /* NoDataAvailable */1,
            customText: $$Intl.t("No stock activity has been yet recorded")
          });
      var data = {
        TAG: /* Done */1,
        _0: {
          TAG: /* Ok */0,
          _0: rows
        }
      };
      var tmp$1;
      if (match$4.pageInfo.hasNextPage === true) {
        var pathname = match$1.pathname + "/stockactivities";
        tmp$1 = React.createElement(ShowAllDataLink.make, {
              to: {
                TAG: /* Route */0,
                _0: pathname
              },
              text: $$Intl.t("Show all data")
            });
      } else {
        tmp$1 = null;
      }
      tmp = React.createElement(React.Fragment, undefined, React.createElement(TableView.make, {
                data: data,
                columns: columns,
                keyExtractor: keyExtractor,
                placeholderEmptyState: placeholderEmptyState
              }), tmp$1);
    } else if (match$2.loading) {
      exit = 1;
    } else {
      tmp = React.createElement(Placeholder.make, {
            status: /* Error */2
          });
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Placeholder.make, {
          status: /* Loading */0
        });
  }
  return React.createElement(Card.make, {
              children: tmp,
              title: $$Intl.t("Stock activities"),
              variation: "table"
            });
}

var make = React.memo(CatalogVariantStockActivityTableCard);

var edgesPerFetch = 5;

export {
  edgesPerFetch ,
  StockActivitiesQuery ,
  Row ,
  columns ,
  make ,
}
/* query Not a pure module */
