// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Env from "../../core/Env.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Json from "../../primitives/Json.bs.js";
import * as Menu from "../../resources/navigation/Menu.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Modal from "../../resources/overlays/Modal.bs.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Banner from "../../resources/feedback-indicators/Banner.bs.js";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Popover from "../../resources/overlays/Popover.bs.js";
import * as $$Request from "../../core/Request.bs.js";
import * as Tooltip from "../../resources/overlays/Tooltip.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as MenuItem from "../../resources/navigation/MenuItem.bs.js";
import * as JsonCodec from "../../primitives/JsonCodec.bs.js";
import * as Separator from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as EmptyState from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as HelpCenter from "../../core/HelpCenter.bs.js";
import * as TextAction from "../../resources/actions/TextAction.bs.js";
import * as AsyncResult from "../../primitives/AsyncResult.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as CatalogLabel from "../../modules/Catalog/CatalogLabel.bs.js";
import * as Illustration from "../../resources/images-and-icons/Illustration.bs.js";
import * as LegacyRouter from "../../core/LegacyRouter.bs.js";
import * as ResourceList from "../../core/ResourceList.bs.js";
import * as SpinnerModal from "../../resources/feedback-indicators/SpinnerModal.bs.js";
import * as ApolloHelpers from "../../helpers/ApolloHelpers.bs.js";
import * as CatalogRoutes from "./CatalogRoutes.bs.js";
import * as Client from "@apollo/client";
import * as CatalogProduct from "../../modules/Catalog/CatalogProduct.bs.js";
import * as SupplierSelect from "../../modules/Supplier/SupplierSelect.bs.js";
import * as RequestMenuItem from "../../resources/actions/RequestMenuItem.bs.js";
import * as ShortIconButton from "../../resources/actions/ShortIconButton.bs.js";
import * as TriggerDownload from "../../primitives/TriggerDownload.bs.js";
import * as ResourceListPage from "../../core/ResourceListPage.bs.js";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as CatalogTableActions from "../../modules/Catalog/CatalogTableActions.bs.js";
import * as CatalogProducerSelect from "../../modules/Catalog/CatalogProducerSelect.bs.js";
import * as CatalogCategoryEditCell from "../../modules/Catalog/CatalogCategoryEditCell.bs.js";
import * as CatalogStockRangeSelect from "../../modules/Catalog/CatalogStockRangeSelect.bs.js";
import * as CatalogProductStatusBadge from "../../modules/Catalog/CatalogProductStatusBadge.bs.js";
import * as ProductReferenceTableCell from "../../modules/Product/ProductReferenceTableCell.bs.js";
import * as CatalogCategorySelectFilter from "../../modules/Catalog/CatalogCategorySelectFilter.bs.js";
import * as RequestOpenStorageUrlButton from "../../resources/actions/RequestOpenStorageUrlButton.bs.js";
import * as CatalogLabelQuickPrintButton from "../../modules/Catalog/CatalogLabelQuickPrintButton.bs.js";
import * as RequestOpenStorageUrlMenuItem from "../../resources/actions/RequestOpenStorageUrlMenuItem.bs.js";
import * as CatalogProductEditUrlQueryString from "../../modules/Catalog/CatalogProductEditUrlQueryString.bs.js";
import * as CatalogVariantStockThresholdCell from "../../modules/Catalog/CatalogVariantStockThresholdCell.bs.js";
import * as PricePurchaseTableEditCellWrapper from "../../modules/Price/PricePurchaseTableEditCellWrapper.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var nonApplicableStringLiteral = "-";

var Raw = {};

var query = Client.gql(["query CatalogListMultiShopsQuery($search: String, $filterBy: InputVariantsDistinctOnCkuQueryFilter, $orderBy: [InputVariantsDistinctOnCkuOrderBy!], $before: String, $after: String, $first: Int, $last: Int)  {\nvariantsDistinctOnCku(search: $search, filterBy: $filterBy, orderBy: $orderBy, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \ncku  \nid  \ncreatedAt  \nname  \nproduct  {\n__typename  \nid  \nname  \nkind  \ncolor  \nwineType  \nwhiteWineType  \nbeerType  \nproducer  \ndesignation  \nfamily  \nregion  \ncountry  \n}\n\nstockKeepingUnit  \ninternalCode  \npriceLookUpCode  \nsupplier  {\n__typename  \ncompanyName  \n}\n\nalcoholVolume  \nformattedPurchasedPrice  \npurchasedPrice  \nformattedCategory  \nbulk  \ncapacityUnit  \nmaxStockThreshold  \nminStockThreshold  \nstockOrderTriggerThreshold  \nstock  {\n__typename  \nformattedShopsNames  \nformattedQuantity  \nstate  \n}\n\nformattedStatus  \n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          variantsDistinctOnCku: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.product;
                    var value$3 = value$2.kind;
                    var tmp;
                    switch (value$3) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$4 = value$2.color;
                    var tmp$1;
                    if (value$4 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$4) {
                        case "AMBER" :
                            tmp$2 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$2 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$2 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$2 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$2 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$2 = "RED";
                            break;
                        case "ROSE" :
                            tmp$2 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$2 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$5 = value$2.wineType;
                    var tmp$3;
                    if (value$5 == null) {
                      tmp$3 = undefined;
                    } else {
                      var tmp$4;
                      switch (value$5) {
                        case "EFFERVESCENT" :
                            tmp$4 = "EFFERVESCENT";
                            break;
                        case "STILL" :
                            tmp$4 = "STILL";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$3 = tmp$4;
                    }
                    var value$6 = value$2.whiteWineType;
                    var tmp$5;
                    if (value$6 == null) {
                      tmp$5 = undefined;
                    } else {
                      var tmp$6;
                      switch (value$6) {
                        case "DRY" :
                            tmp$6 = "DRY";
                            break;
                        case "SEMIDRY" :
                            tmp$6 = "SEMIDRY";
                            break;
                        case "SOFT" :
                            tmp$6 = "SOFT";
                            break;
                        case "SWEET" :
                            tmp$6 = "SWEET";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$5 = tmp$6;
                    }
                    var value$7 = value$2.beerType;
                    var value$8 = value$2.producer;
                    var value$9 = value$2.designation;
                    var value$10 = value$2.family;
                    var value$11 = value$2.region;
                    var value$12 = value$2.country;
                    var value$13 = value$1.stockKeepingUnit;
                    var value$14 = value$1.internalCode;
                    var value$15 = value$1.priceLookUpCode;
                    var value$16 = value$1.supplier;
                    var value$17 = value$1.alcoholVolume;
                    var value$18 = value$1.formattedPurchasedPrice;
                    var value$19 = value$1.purchasedPrice;
                    var value$20 = value$1.formattedCategory;
                    var value$21 = value$1.bulk;
                    var value$22 = value$1.capacityUnit;
                    var value$23 = value$1.maxStockThreshold;
                    var value$24 = value$1.minStockThreshold;
                    var value$25 = value$1.stockOrderTriggerThreshold;
                    var value$26 = value$1.stock;
                    var value$27 = value$26.formattedShopsNames;
                    var value$28 = value$26.formattedQuantity;
                    var value$29 = value$26.state;
                    var tmp$7;
                    if (value$29 == null) {
                      tmp$7 = undefined;
                    } else {
                      var tmp$8;
                      switch (value$29) {
                        case "ALERT" :
                            tmp$8 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$8 = "DANGER";
                            break;
                        case "OK" :
                            tmp$8 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$7 = tmp$8;
                    }
                    var value$30 = value$1.formattedStatus;
                    var tmp$9;
                    switch (value$30) {
                      case "ACTIVE" :
                          tmp$9 = "ACTIVE";
                          break;
                      case "ARCHIVED" :
                          tmp$9 = "ARCHIVED";
                          break;
                      case "INACTIVE" :
                          tmp$9 = "INACTIVE";
                          break;
                      case "VARIABLE" :
                          tmp$9 = "VARIABLE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              cku: Scalar.CKU.parse(value$1.cku),
                              id: value$1.id,
                              createdAt: Scalar.Datetime.parse(value$1.createdAt),
                              name: value$1.name,
                              product: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name,
                                kind: tmp,
                                color: tmp$1,
                                wineType: tmp$3,
                                whiteWineType: tmp$5,
                                beerType: !(value$7 == null) ? value$7 : undefined,
                                producer: !(value$8 == null) ? value$8 : undefined,
                                designation: !(value$9 == null) ? value$9 : undefined,
                                family: !(value$10 == null) ? value$10 : undefined,
                                region: !(value$11 == null) ? value$11 : undefined,
                                country: !(value$12 == null) ? value$12 : undefined
                              },
                              stockKeepingUnit: !(value$13 == null) ? value$13 : undefined,
                              internalCode: !(value$14 == null) ? value$14 : undefined,
                              priceLookUpCode: !(value$15 == null) ? value$15 : undefined,
                              supplier: !(value$16 == null) ? ({
                                    __typename: value$16.__typename,
                                    companyName: value$16.companyName
                                  }) : undefined,
                              alcoholVolume: !(value$17 == null) ? value$17 : undefined,
                              formattedPurchasedPrice: !(value$18 == null) ? value$18 : undefined,
                              purchasedPrice: !(value$19 == null) ? value$19 : undefined,
                              formattedCategory: !(value$20 == null) ? value$20 : undefined,
                              bulk: !(value$21 == null) ? value$21 : undefined,
                              capacityUnit: !(value$22 == null) ? value$22 : undefined,
                              maxStockThreshold: !(value$23 == null) ? value$23 : undefined,
                              minStockThreshold: !(value$24 == null) ? value$24 : undefined,
                              stockOrderTriggerThreshold: !(value$25 == null) ? value$25 : undefined,
                              stock: {
                                __typename: value$26.__typename,
                                formattedShopsNames: !(value$27 == null) ? value$27 : undefined,
                                formattedQuantity: !(value$28 == null) ? value$28 : undefined,
                                state: tmp$7
                              },
                              formattedStatus: tmp$9
                            }
                          };
                  }), value$5),
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.formattedStatus;
          var formattedStatus = value$2 === "INACTIVE" ? "INACTIVE" : (
              value$2 === "ARCHIVED" ? "ARCHIVED" : (
                  value$2 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
                )
            );
          var value$3 = value$1.stock;
          var value$4 = value$3.state;
          var state = value$4 !== undefined ? (
              value$4 === "OK" ? "OK" : (
                  value$4 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$5 = value$3.formattedQuantity;
          var formattedQuantity = value$5 !== undefined ? value$5 : null;
          var value$6 = value$3.formattedShopsNames;
          var formattedShopsNames = value$6 !== undefined ? value$6 : null;
          var value$7 = value$3.__typename;
          var stock = {
            __typename: value$7,
            formattedShopsNames: formattedShopsNames,
            formattedQuantity: formattedQuantity,
            state: state
          };
          var value$8 = value$1.stockOrderTriggerThreshold;
          var stockOrderTriggerThreshold = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.minStockThreshold;
          var minStockThreshold = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.maxStockThreshold;
          var maxStockThreshold = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.capacityUnit;
          var capacityUnit = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.bulk;
          var bulk = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.formattedCategory;
          var formattedCategory = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.purchasedPrice;
          var purchasedPrice = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.formattedPurchasedPrice;
          var formattedPurchasedPrice = value$15 !== undefined ? value$15 : null;
          var value$16 = value$1.alcoholVolume;
          var alcoholVolume = value$16 !== undefined ? value$16 : null;
          var value$17 = value$1.supplier;
          var supplier;
          if (value$17 !== undefined) {
            var value$18 = value$17.companyName;
            var value$19 = value$17.__typename;
            supplier = {
              __typename: value$19,
              companyName: value$18
            };
          } else {
            supplier = null;
          }
          var value$20 = value$1.priceLookUpCode;
          var priceLookUpCode = value$20 !== undefined ? value$20 : null;
          var value$21 = value$1.internalCode;
          var internalCode = value$21 !== undefined ? value$21 : null;
          var value$22 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$22 !== undefined ? value$22 : null;
          var value$23 = value$1.product;
          var value$24 = value$23.country;
          var country = value$24 !== undefined ? value$24 : null;
          var value$25 = value$23.region;
          var region = value$25 !== undefined ? value$25 : null;
          var value$26 = value$23.family;
          var family = value$26 !== undefined ? value$26 : null;
          var value$27 = value$23.designation;
          var designation = value$27 !== undefined ? value$27 : null;
          var value$28 = value$23.producer;
          var producer = value$28 !== undefined ? value$28 : null;
          var value$29 = value$23.beerType;
          var beerType = value$29 !== undefined ? value$29 : null;
          var value$30 = value$23.whiteWineType;
          var whiteWineType = value$30 !== undefined ? (
              value$30 === "SWEET" ? "SWEET" : (
                  value$30 === "SEMIDRY" ? "SEMIDRY" : (
                      value$30 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : null;
          var value$31 = value$23.wineType;
          var wineType = value$31 !== undefined ? (
              value$31 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : null;
          var value$32 = value$23.color;
          var color = value$32 !== undefined ? (
              value$32 === "ORANGE" ? "ORANGE" : (
                  value$32 === "RED" ? "RED" : (
                      value$32 === "AMBER" ? "AMBER" : (
                          value$32 === "DARK" ? "DARK" : (
                              value$32 === "BLACK" ? "BLACK" : (
                                  value$32 === "BLOND" ? "BLOND" : (
                                      value$32 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$33 = value$23.kind;
          var kind = value$33 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$33 === "BEER" ? "BEER" : (
                  value$33 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$34 = value$23.name;
          var value$35 = value$23.id;
          var value$36 = value$23.__typename;
          var product = {
            __typename: value$36,
            id: value$35,
            name: value$34,
            kind: kind,
            color: color,
            wineType: wineType,
            whiteWineType: whiteWineType,
            beerType: beerType,
            producer: producer,
            designation: designation,
            family: family,
            region: region,
            country: country
          };
          var value$37 = value$1.name;
          var value$38 = value$1.createdAt;
          var value$39 = Scalar.Datetime.serialize(value$38);
          var value$40 = value$1.id;
          var value$41 = value$1.cku;
          var value$42 = Scalar.CKU.serialize(value$41);
          var value$43 = value$1.__typename;
          var node = {
            __typename: value$43,
            cku: value$42,
            id: value$40,
            createdAt: value$39,
            name: value$37,
            product: product,
            stockKeepingUnit: stockKeepingUnit,
            internalCode: internalCode,
            priceLookUpCode: priceLookUpCode,
            supplier: supplier,
            alcoholVolume: alcoholVolume,
            formattedPurchasedPrice: formattedPurchasedPrice,
            purchasedPrice: purchasedPrice,
            formattedCategory: formattedCategory,
            bulk: bulk,
            capacityUnit: capacityUnit,
            maxStockThreshold: maxStockThreshold,
            minStockThreshold: minStockThreshold,
            stockOrderTriggerThreshold: stockOrderTriggerThreshold,
            stock: stock,
            formattedStatus: formattedStatus
          };
          var value$44 = value.__typename;
          return {
                  __typename: value$44,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var variantsDistinctOnCku = {
    __typename: value$8,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          variantsDistinctOnCku: variantsDistinctOnCku
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputVariantsDistinctOnCkuOrderBy(inp) {
  var a = inp.createdAt;
  var a$1 = inp.active;
  var a$2 = inp.name;
  var a$3 = inp.producer;
  var a$4 = inp.purchasedPrice;
  return {
          createdAt: a !== undefined ? (
              a === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          active: a$1 !== undefined ? (
              a$1 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          name: a$2 !== undefined ? (
              a$2 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          producer: a$3 !== undefined ? (
              a$3 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          purchasedPrice: a$4 !== undefined ? (
              a$4 === "DESC" ? "DESC" : "ASC"
            ) : undefined
        };
}

function serializeInputObjectInputVariantsDistinctOnCkuQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.active;
  var a$2 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          active: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          archived: a$2 !== undefined ? (
              a$2 === "ONLY" ? "ONLY" : (
                  a$2 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.orderBy;
  var a$3 = inp.before;
  var a$4 = inp.after;
  var a$5 = inp.first;
  var a$6 = inp.last;
  return {
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputVariantsDistinctOnCkuQueryFilter(a$1) : undefined,
          orderBy: a$2 !== undefined ? Js_array.map(serializeInputObjectInputVariantsDistinctOnCkuOrderBy, a$2) : undefined,
          before: a$3 !== undefined ? a$3 : undefined,
          after: a$4 !== undefined ? a$4 : undefined,
          first: a$5 !== undefined ? a$5 : undefined,
          last: a$6 !== undefined ? a$6 : undefined
        };
}

function makeVariables(search, filterBy, orderBy, before, after, first, last, param) {
  return {
          search: search,
          filterBy: filterBy,
          orderBy: orderBy,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeInputObjectInputVariantsDistinctOnCkuQueryFilter(shopIds, active, archived, param) {
  return {
          shopIds: shopIds,
          active: active,
          archived: archived
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectInputVariantsDistinctOnCkuOrderBy(createdAt, active, name, producer, purchasedPrice, param) {
  return {
          createdAt: createdAt,
          active: active,
          name: name,
          producer: producer,
          purchasedPrice: purchasedPrice
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          filterBy: undefined,
          orderBy: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined
        };
}

var CatalogListMultiShopsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsDistinctOnCkuQueryFilter: serializeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectInputVariantsDistinctOnCkuOrderBy: serializeInputObjectInputVariantsDistinctOnCkuOrderBy,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsDistinctOnCkuQueryFilter: makeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectInputVariantsDistinctOnCkuOrderBy: makeInputObjectInputVariantsDistinctOnCkuOrderBy,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CatalogListMultiShopsQuery_refetchQueryDescription = include.refetchQueryDescription;

var CatalogListMultiShopsQuery_useLazy = include.useLazy;

var CatalogListMultiShopsQuery_useLazyWithVariables = include.useLazyWithVariables;

var CatalogListMultiShopsQuery = {
  CatalogListMultiShopsQuery_inner: CatalogListMultiShopsQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsDistinctOnCkuQueryFilter: serializeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectInputVariantsDistinctOnCkuOrderBy: serializeInputObjectInputVariantsDistinctOnCkuOrderBy,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsDistinctOnCkuQueryFilter: makeInputObjectInputVariantsDistinctOnCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectInputVariantsDistinctOnCkuOrderBy: makeInputObjectInputVariantsDistinctOnCkuOrderBy,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: CatalogListMultiShopsQuery_refetchQueryDescription,
  use: use,
  useLazy: CatalogListMultiShopsQuery_useLazy,
  useLazyWithVariables: CatalogListMultiShopsQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query CatalogListSingleShopQuery($search: String, $filterBy: InputVariantsQueryFilter, $orderBy: [InputVariantsOrderBy!], $before: String, $after: String, $first: Int, $last: Int)  {\nvariants(search: $search, filterBy: $filterBy, orderBy: $orderBy, before: $before, after: $after, first: $first, last: $last)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \ncku  \nid  \ncreatedAt  \nname  \nproduct  {\n__typename  \nid  \nname  \nkind  \ncolor  \nwineType  \nwhiteWineType  \nbeerType  \nproducer  \ndesignation  \nfamily  \nregion  \ncountry  \n}\n\nvariantPrices  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nvalueIncludingTax  \nvalueExcludingTax  \nprice  {\n__typename  \nname  \nenableByDefault  \ntaxIncluded  \n}\n\n}\n\n}\n\n}\n\nstockKeepingUnit  \ninternalCode  \npriceLookUpCode  \nsupplier  {\n__typename  \ncompanyName  \n}\n\nalcoholVolume  \nformattedPurchasedPrice  \npurchasedPrice  \nformattedCategory  \nbulk  \ncapacityUnit  \nmaxStockThreshold  \nminStockThreshold  \nstockOrderTriggerThreshold  \nstock  {\n__typename  \nformattedQuantity  \nstate  \nformattedShopsNames  \n}\n\nformattedStatus  \n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.variants;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          variants: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.product;
                    var value$3 = value$2.kind;
                    var tmp;
                    switch (value$3) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$4 = value$2.color;
                    var tmp$1;
                    if (value$4 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$4) {
                        case "AMBER" :
                            tmp$2 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$2 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$2 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$2 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$2 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$2 = "RED";
                            break;
                        case "ROSE" :
                            tmp$2 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$2 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$5 = value$2.wineType;
                    var tmp$3;
                    if (value$5 == null) {
                      tmp$3 = undefined;
                    } else {
                      var tmp$4;
                      switch (value$5) {
                        case "EFFERVESCENT" :
                            tmp$4 = "EFFERVESCENT";
                            break;
                        case "STILL" :
                            tmp$4 = "STILL";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$3 = tmp$4;
                    }
                    var value$6 = value$2.whiteWineType;
                    var tmp$5;
                    if (value$6 == null) {
                      tmp$5 = undefined;
                    } else {
                      var tmp$6;
                      switch (value$6) {
                        case "DRY" :
                            tmp$6 = "DRY";
                            break;
                        case "SEMIDRY" :
                            tmp$6 = "SEMIDRY";
                            break;
                        case "SOFT" :
                            tmp$6 = "SOFT";
                            break;
                        case "SWEET" :
                            tmp$6 = "SWEET";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$5 = tmp$6;
                    }
                    var value$7 = value$2.beerType;
                    var value$8 = value$2.producer;
                    var value$9 = value$2.designation;
                    var value$10 = value$2.family;
                    var value$11 = value$2.region;
                    var value$12 = value$2.country;
                    var value$13 = value$1.variantPrices;
                    var value$14 = value$13.edges;
                    var value$15 = value$1.stockKeepingUnit;
                    var value$16 = value$1.internalCode;
                    var value$17 = value$1.priceLookUpCode;
                    var value$18 = value$1.supplier;
                    var value$19 = value$1.alcoholVolume;
                    var value$20 = value$1.formattedPurchasedPrice;
                    var value$21 = value$1.purchasedPrice;
                    var value$22 = value$1.formattedCategory;
                    var value$23 = value$1.bulk;
                    var value$24 = value$1.capacityUnit;
                    var value$25 = value$1.maxStockThreshold;
                    var value$26 = value$1.minStockThreshold;
                    var value$27 = value$1.stockOrderTriggerThreshold;
                    var value$28 = value$1.stock;
                    var value$29 = value$28.formattedQuantity;
                    var value$30 = value$28.state;
                    var tmp$7;
                    if (value$30 == null) {
                      tmp$7 = undefined;
                    } else {
                      var tmp$8;
                      switch (value$30) {
                        case "ALERT" :
                            tmp$8 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$8 = "DANGER";
                            break;
                        case "OK" :
                            tmp$8 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$7 = tmp$8;
                    }
                    var value$31 = value$28.formattedShopsNames;
                    var value$32 = value$1.formattedStatus;
                    var tmp$9;
                    switch (value$32) {
                      case "ACTIVE" :
                          tmp$9 = "ACTIVE";
                          break;
                      case "ARCHIVED" :
                          tmp$9 = "ARCHIVED";
                          break;
                      case "INACTIVE" :
                          tmp$9 = "INACTIVE";
                          break;
                      case "VARIABLE" :
                          tmp$9 = "VARIABLE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              cku: Scalar.CKU.parse(value$1.cku),
                              id: value$1.id,
                              createdAt: Scalar.Datetime.parse(value$1.createdAt),
                              name: value$1.name,
                              product: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name,
                                kind: tmp,
                                color: tmp$1,
                                wineType: tmp$3,
                                whiteWineType: tmp$5,
                                beerType: !(value$7 == null) ? value$7 : undefined,
                                producer: !(value$8 == null) ? value$8 : undefined,
                                designation: !(value$9 == null) ? value$9 : undefined,
                                family: !(value$10 == null) ? value$10 : undefined,
                                region: !(value$11 == null) ? value$11 : undefined,
                                country: !(value$12 == null) ? value$12 : undefined
                              },
                              variantPrices: {
                                __typename: value$13.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        var value$2 = value$1.price;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  valueIncludingTax: value$1.valueIncludingTax,
                                                  valueExcludingTax: value$1.valueExcludingTax,
                                                  price: !(value$2 == null) ? ({
                                                        __typename: value$2.__typename,
                                                        name: value$2.name,
                                                        enableByDefault: value$2.enableByDefault,
                                                        taxIncluded: value$2.taxIncluded
                                                      }) : undefined
                                                }
                                              };
                                      }), value$14)
                              },
                              stockKeepingUnit: !(value$15 == null) ? value$15 : undefined,
                              internalCode: !(value$16 == null) ? value$16 : undefined,
                              priceLookUpCode: !(value$17 == null) ? value$17 : undefined,
                              supplier: !(value$18 == null) ? ({
                                    __typename: value$18.__typename,
                                    companyName: value$18.companyName
                                  }) : undefined,
                              alcoholVolume: !(value$19 == null) ? value$19 : undefined,
                              formattedPurchasedPrice: !(value$20 == null) ? value$20 : undefined,
                              purchasedPrice: !(value$21 == null) ? value$21 : undefined,
                              formattedCategory: !(value$22 == null) ? value$22 : undefined,
                              bulk: !(value$23 == null) ? value$23 : undefined,
                              capacityUnit: !(value$24 == null) ? value$24 : undefined,
                              maxStockThreshold: !(value$25 == null) ? value$25 : undefined,
                              minStockThreshold: !(value$26 == null) ? value$26 : undefined,
                              stockOrderTriggerThreshold: !(value$27 == null) ? value$27 : undefined,
                              stock: {
                                __typename: value$28.__typename,
                                formattedQuantity: !(value$29 == null) ? value$29 : undefined,
                                state: tmp$7,
                                formattedShopsNames: !(value$31 == null) ? value$31 : undefined
                              },
                              formattedStatus: tmp$9
                            }
                          };
                  }), value$5),
            totalCount: value$1.totalCount
          }
        };
}

function serialize$1(value) {
  var value$1 = value.variants;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.formattedStatus;
          var formattedStatus = value$2 === "INACTIVE" ? "INACTIVE" : (
              value$2 === "ARCHIVED" ? "ARCHIVED" : (
                  value$2 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
                )
            );
          var value$3 = value$1.stock;
          var value$4 = value$3.formattedShopsNames;
          var formattedShopsNames = value$4 !== undefined ? value$4 : null;
          var value$5 = value$3.state;
          var state = value$5 !== undefined ? (
              value$5 === "OK" ? "OK" : (
                  value$5 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$6 = value$3.formattedQuantity;
          var formattedQuantity = value$6 !== undefined ? value$6 : null;
          var value$7 = value$3.__typename;
          var stock = {
            __typename: value$7,
            formattedQuantity: formattedQuantity,
            state: state,
            formattedShopsNames: formattedShopsNames
          };
          var value$8 = value$1.stockOrderTriggerThreshold;
          var stockOrderTriggerThreshold = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.minStockThreshold;
          var minStockThreshold = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.maxStockThreshold;
          var maxStockThreshold = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.capacityUnit;
          var capacityUnit = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.bulk;
          var bulk = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.formattedCategory;
          var formattedCategory = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.purchasedPrice;
          var purchasedPrice = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.formattedPurchasedPrice;
          var formattedPurchasedPrice = value$15 !== undefined ? value$15 : null;
          var value$16 = value$1.alcoholVolume;
          var alcoholVolume = value$16 !== undefined ? value$16 : null;
          var value$17 = value$1.supplier;
          var supplier;
          if (value$17 !== undefined) {
            var value$18 = value$17.companyName;
            var value$19 = value$17.__typename;
            supplier = {
              __typename: value$19,
              companyName: value$18
            };
          } else {
            supplier = null;
          }
          var value$20 = value$1.priceLookUpCode;
          var priceLookUpCode = value$20 !== undefined ? value$20 : null;
          var value$21 = value$1.internalCode;
          var internalCode = value$21 !== undefined ? value$21 : null;
          var value$22 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$22 !== undefined ? value$22 : null;
          var value$23 = value$1.variantPrices;
          var value$24 = value$23.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.price;
                  var price;
                  if (value$2 !== undefined) {
                    var value$3 = value$2.taxIncluded;
                    var value$4 = value$2.enableByDefault;
                    var value$5 = value$2.name;
                    var value$6 = value$2.__typename;
                    price = {
                      __typename: value$6,
                      name: value$5,
                      enableByDefault: value$4,
                      taxIncluded: value$3
                    };
                  } else {
                    price = null;
                  }
                  var value$7 = value$1.valueExcludingTax;
                  var value$8 = value$1.valueIncludingTax;
                  var value$9 = value$1.__typename;
                  var node = {
                    __typename: value$9,
                    valueIncludingTax: value$8,
                    valueExcludingTax: value$7,
                    price: price
                  };
                  var value$10 = value.__typename;
                  return {
                          __typename: value$10,
                          node: node
                        };
                }), value$24);
          var value$25 = value$23.__typename;
          var variantPrices = {
            __typename: value$25,
            edges: edges
          };
          var value$26 = value$1.product;
          var value$27 = value$26.country;
          var country = value$27 !== undefined ? value$27 : null;
          var value$28 = value$26.region;
          var region = value$28 !== undefined ? value$28 : null;
          var value$29 = value$26.family;
          var family = value$29 !== undefined ? value$29 : null;
          var value$30 = value$26.designation;
          var designation = value$30 !== undefined ? value$30 : null;
          var value$31 = value$26.producer;
          var producer = value$31 !== undefined ? value$31 : null;
          var value$32 = value$26.beerType;
          var beerType = value$32 !== undefined ? value$32 : null;
          var value$33 = value$26.whiteWineType;
          var whiteWineType = value$33 !== undefined ? (
              value$33 === "SWEET" ? "SWEET" : (
                  value$33 === "SEMIDRY" ? "SEMIDRY" : (
                      value$33 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : null;
          var value$34 = value$26.wineType;
          var wineType = value$34 !== undefined ? (
              value$34 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : null;
          var value$35 = value$26.color;
          var color = value$35 !== undefined ? (
              value$35 === "ORANGE" ? "ORANGE" : (
                  value$35 === "RED" ? "RED" : (
                      value$35 === "AMBER" ? "AMBER" : (
                          value$35 === "DARK" ? "DARK" : (
                              value$35 === "BLACK" ? "BLACK" : (
                                  value$35 === "BLOND" ? "BLOND" : (
                                      value$35 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$36 = value$26.kind;
          var kind = value$36 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$36 === "BEER" ? "BEER" : (
                  value$36 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$37 = value$26.name;
          var value$38 = value$26.id;
          var value$39 = value$26.__typename;
          var product = {
            __typename: value$39,
            id: value$38,
            name: value$37,
            kind: kind,
            color: color,
            wineType: wineType,
            whiteWineType: whiteWineType,
            beerType: beerType,
            producer: producer,
            designation: designation,
            family: family,
            region: region,
            country: country
          };
          var value$40 = value$1.name;
          var value$41 = value$1.createdAt;
          var value$42 = Scalar.Datetime.serialize(value$41);
          var value$43 = value$1.id;
          var value$44 = value$1.cku;
          var value$45 = Scalar.CKU.serialize(value$44);
          var value$46 = value$1.__typename;
          var node = {
            __typename: value$46,
            cku: value$45,
            id: value$43,
            createdAt: value$42,
            name: value$40,
            product: product,
            variantPrices: variantPrices,
            stockKeepingUnit: stockKeepingUnit,
            internalCode: internalCode,
            priceLookUpCode: priceLookUpCode,
            supplier: supplier,
            alcoholVolume: alcoholVolume,
            formattedPurchasedPrice: formattedPurchasedPrice,
            purchasedPrice: purchasedPrice,
            formattedCategory: formattedCategory,
            bulk: bulk,
            capacityUnit: capacityUnit,
            maxStockThreshold: maxStockThreshold,
            minStockThreshold: minStockThreshold,
            stockOrderTriggerThreshold: stockOrderTriggerThreshold,
            stock: stock,
            formattedStatus: formattedStatus
          };
          var value$47 = value.__typename;
          return {
                  __typename: value$47,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var variants = {
    __typename: value$8,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          variants: variants
        };
}

function serializeInputObjectNumberRangeFilter(inp) {
  var a = inp._min;
  var a$1 = inp._max;
  var a$2 = inp._between;
  return {
          _min: a !== undefined ? a : undefined,
          _max: a$1 !== undefined ? a$1 : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInFilter$1(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectNullableStringEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a !== undefined ? a : undefined
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectBooleanEqualsFilter$1(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsOrderBy(inp) {
  var a = inp.createdAt;
  var a$1 = inp.active;
  var a$2 = inp.name;
  var a$3 = inp.producer;
  var a$4 = inp.purchasedPrice;
  return {
          createdAt: a !== undefined ? (
              a === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          active: a$1 !== undefined ? (
              a$1 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          name: a$2 !== undefined ? (
              a$2 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          producer: a$3 !== undefined ? (
              a$3 === "DESC" ? "DESC" : "ASC"
            ) : undefined,
          purchasedPrice: a$4 !== undefined ? (
              a$4 === "DESC" ? "DESC" : "ASC"
            ) : undefined
        };
}

function serializeInputObjectInputVariantsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.stockKeepingUnit;
  var a$2 = inp.ean13;
  var a$3 = inp.active;
  var a$4 = inp.archived;
  var a$5 = inp.createdAt;
  var a$6 = inp.updatedAt;
  var a$7 = inp.categoryId;
  var a$8 = inp.supplierId;
  var a$9 = inp.stock;
  var a$10 = inp.producer;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter$1(a) : undefined,
          stockKeepingUnit: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          ean13: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          active: a$3 !== undefined ? ({
                _equals: a$3._equals
              }) : undefined,
          archived: a$4 !== undefined ? (
              a$4 === "ONLY" ? "ONLY" : (
                  a$4 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          createdAt: a$5 !== undefined ? serializeInputObjectDateFilter(a$5) : undefined,
          updatedAt: a$6 !== undefined ? serializeInputObjectDateFilter(a$6) : undefined,
          categoryId: a$7 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$7) : undefined,
          supplierId: a$8 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$8) : undefined,
          stock: a$9 !== undefined ? serializeInputObjectNumberRangeFilter(a$9) : undefined,
          producer: a$10 !== undefined ? ({
                _equals: a$10._equals
              }) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.orderBy;
  var a$3 = inp.before;
  var a$4 = inp.after;
  var a$5 = inp.first;
  var a$6 = inp.last;
  return {
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputVariantsQueryFilter(a$1) : undefined,
          orderBy: a$2 !== undefined ? Js_array.map(serializeInputObjectInputVariantsOrderBy, a$2) : undefined,
          before: a$3 !== undefined ? a$3 : undefined,
          after: a$4 !== undefined ? a$4 : undefined,
          first: a$5 !== undefined ? a$5 : undefined,
          last: a$6 !== undefined ? a$6 : undefined
        };
}

function makeVariables$1(search, filterBy, orderBy, before, after, first, last, param) {
  return {
          search: search,
          filterBy: filterBy,
          orderBy: orderBy,
          before: before,
          after: after,
          first: first,
          last: last
        };
}

function makeInputObjectInputVariantsQueryFilter(shopIds, stockKeepingUnit, ean13, active, archived, createdAt, updatedAt, categoryId, supplierId, stock, producer, param) {
  return {
          shopIds: shopIds,
          stockKeepingUnit: stockKeepingUnit,
          ean13: ean13,
          active: active,
          archived: archived,
          createdAt: createdAt,
          updatedAt: updatedAt,
          categoryId: categoryId,
          supplierId: supplierId,
          stock: stock,
          producer: producer
        };
}

function makeInputObjectInFilter$1(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectBooleanEqualsFilter$1(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeInputObjectNullableStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectNumberRangeFilter(_min, _max, _between, param) {
  return {
          _min: _min,
          _max: _max,
          _between: _between
        };
}

function makeInputObjectInputVariantsOrderBy(createdAt, active, name, producer, purchasedPrice, param) {
  return {
          createdAt: createdAt,
          active: active,
          name: name,
          producer: producer,
          purchasedPrice: purchasedPrice
        };
}

function makeDefaultVariables$1(param) {
  return {
          search: undefined,
          filterBy: undefined,
          orderBy: undefined,
          before: undefined,
          after: undefined,
          first: undefined,
          last: undefined
        };
}

var CatalogListSingleShopQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter$1,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectNumberRangeFilter: serializeInputObjectNumberRangeFilter,
  serializeInputObjectInputVariantsOrderBy: serializeInputObjectInputVariantsOrderBy,
  makeVariables: makeVariables$1,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter$1,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectNumberRangeFilter: makeInputObjectNumberRangeFilter,
  makeInputObjectInputVariantsOrderBy: makeInputObjectInputVariantsOrderBy,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var CatalogListSingleShopQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var CatalogListSingleShopQuery_useLazy = include$1.useLazy;

var CatalogListSingleShopQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var CatalogListSingleShopQuery = {
  CatalogListSingleShopQuery_inner: CatalogListSingleShopQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter$1,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectNumberRangeFilter: serializeInputObjectNumberRangeFilter,
  serializeInputObjectInputVariantsOrderBy: serializeInputObjectInputVariantsOrderBy,
  makeVariables: makeVariables$1,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter$1,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectNumberRangeFilter: makeInputObjectNumberRangeFilter,
  makeInputObjectInputVariantsOrderBy: makeInputObjectInputVariantsOrderBy,
  makeDefaultVariables: makeDefaultVariables$1,
  refetchQueryDescription: CatalogListSingleShopQuery_refetchQueryDescription,
  use: use$1,
  useLazy: CatalogListSingleShopQuery_useLazy,
  useLazyWithVariables: CatalogListSingleShopQuery_useLazyWithVariables
};

var make = React.memo(function (Props) {
      var notification = Props.notification;
      var onRequestClose = Props.onRequestClose;
      if (notification === undefined) {
        return null;
      }
      var tmp;
      tmp = notification.TAG === /* Ok */0 ? React.createElement(Banner.make, {
              textStatus: {
                TAG: /* Success */0,
                _0: notification._0
              },
              onRequestClose: onRequestClose
            }) : React.createElement(Banner.make, {
              textStatus: {
                TAG: /* Danger */1,
                _0: notification._0
              },
              onRequestClose: onRequestClose
            });
      return React.createElement(Box.make, Box.makeProps(Caml_option.some(tmp), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    });

var CatalogListNotificationBanner = {
  make: make
};

var endpoint = Env.sheetUrl(undefined) + "/inventory-export";

function encodeRequestBodyJson(shopIds, variantsActive, variantsArchived, categoryId, supplierId, producer, stock, param) {
  var filterBy = {};
  filterBy["shopIds"] = Json.encodeDict(Js_dict.fromArray([[
              "_in",
              Json.encodeArray(Belt_Array.map(shopIds, Json.encodeString))
            ]]));
  if (variantsActive !== undefined) {
    filterBy["active"] = Json.encodeDict(Js_dict.fromArray([[
                "_equals",
                Json.encodeBoolean(variantsActive)
              ]]));
  }
  if (variantsArchived !== undefined) {
    filterBy["archived"] = Json.encodeString(variantsArchived ? "ONLY" : "EXCLUDED");
  } else {
    filterBy["archived"] = Json.encodeString("INCLUDED");
  }
  if (categoryId !== undefined) {
    var categoryId$1 = Caml_option.valFromOption(categoryId);
    filterBy["categoryId"] = Json.encodeDict(Js_dict.fromArray([[
                "_equals",
                (categoryId$1 == null) ? Json.encodedNull : Json.encodeString(categoryId$1)
              ]]));
  }
  if (supplierId !== undefined) {
    var supplierId$1 = Caml_option.valFromOption(supplierId);
    filterBy["supplierId"] = Json.encodeDict(Js_dict.fromArray([[
                "_equals",
                (supplierId$1 == null) ? Json.encodedNull : Json.encodeString(supplierId$1)
              ]]));
  }
  if (producer !== undefined) {
    filterBy["producer"] = Json.encodeDict(Js_dict.fromArray([[
                "_equals",
                Json.encodeString(producer)
              ]]));
  }
  if (stock !== undefined) {
    var max = stock.max;
    var min = stock.min;
    filterBy["stock"] = Json.encodeDict(Js_dict.fromArray([
              min !== undefined ? [
                  "_min",
                  Json.encodeNumber(min)
                ] : [
                  "_min",
                  Json.encodedNull
                ],
              max !== undefined ? [
                  "_max",
                  Json.encodeNumber(max)
                ] : [
                  "_max",
                  Json.encodedNull
                ]
            ]));
  }
  return Json.encodeDict(Js_dict.fromArray([
                  [
                    "filterBy",
                    Json.encodeDict(filterBy)
                  ],
                  [
                    "timeZone",
                    Json.encodeString($$Intl.timeZone)
                  ]
                ]));
}

function CatalogListPage$CatalogListInventoryExportShortIconButton(Props) {
  var tooltip = Props.tooltip;
  var shopIds = Props.shopIds;
  var activeFilters = Props.activeFilters;
  var variantsActive = Props.variantsActive;
  var variantsArchived = Props.variantsArchived;
  var categoryId = Props.categoryId;
  var supplierId = Props.supplierId;
  var producer = Props.producer;
  var stock = Props.stock;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var request = function (param) {
    return $$Request.make("POST", Caml_option.some(encodeRequestBodyJson(shopIds, variantsActive, variantsArchived, categoryId, supplierId, producer, stock, undefined)), undefined, undefined, endpoint);
  };
  var onFailure = function (error) {
    Curry._1(onRequestErrorNotification, RequestOpenStorageUrlButton.failureErrorToString(error, "inventory"));
  };
  var operableRequest = {
    TAG: /* Ok */0,
    _0: request
  };
  return React.createElement(RequestOpenStorageUrlButton.make, {
              variation: "compact",
              statusLight: activeFilters,
              text: tooltip,
              operableRequest: operableRequest,
              onFailure: onFailure
            });
}

var CatalogListInventoryExportShortIconButton = {
  endpoint: endpoint,
  encodeRequestBodyJson: encodeRequestBodyJson,
  make: CatalogListPage$CatalogListInventoryExportShortIconButton
};

var endpoint$1 = Env.sheetUrl(undefined) + "/variants-price-look-up-codes";

function encodeRequestBodyJson$1(shopId, variantsActive, variantsArchived, param) {
  var body = {};
  body["shopId"] = Json.encodeString(shopId);
  if (variantsActive !== undefined) {
    body["variantsActive"] = Json.encodeBoolean(variantsActive);
  }
  if (variantsArchived !== undefined) {
    body["variantsArchived"] = Json.encodeBoolean(variantsArchived);
  }
  return Json.encodeDict(body);
}

function CatalogListPage$CatalogListPriceLookUpExportMenuItem(Props) {
  var text = Props.text;
  var shopId = Props.shopId;
  var variantsActive = Props.variantsActive;
  var variantsArchived = Props.variantsArchived;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var match = Popover.useState(undefined);
  var onRequestClose = match.close;
  var request = function (param) {
    return $$Request.make("POST", Caml_option.some(encodeRequestBodyJson$1(Belt_Option.getWithDefault(shopId, ""), variantsActive, variantsArchived, undefined)), undefined, undefined, endpoint$1);
  };
  var onSuccess = function (param) {
    Curry._1(onRequestClose, undefined);
  };
  var onFailure = function (error) {
    var errorMessage = RequestOpenStorageUrlMenuItem.failureErrorToString(error, "PLU codes");
    Curry._1(onRequestErrorNotification, errorMessage);
    Curry._1(onRequestClose, undefined);
  };
  var operableRequest = shopId !== undefined ? ({
        TAG: /* Ok */0,
        _0: request
      }) : ({
        TAG: /* Error */1,
        _0: $$Intl.t("Please select a shop beforehand with the filter.")
      });
  return React.createElement(RequestOpenStorageUrlMenuItem.make, {
              text: text,
              operableRequest: operableRequest,
              onSuccess: onSuccess,
              onFailure: onFailure
            });
}

var CatalogListPriceLookUpExportMenuItem = {
  endpoint: endpoint$1,
  encodeRequestBodyJson: encodeRequestBodyJson$1,
  make: CatalogListPage$CatalogListPriceLookUpExportMenuItem
};

var endpoint$2 = Env.gatewayUrl(undefined) + "/variants-centralization";

function encodeRequestBodyJson$2(param) {
  return Json.encodeDict({});
}

function decodeRequestResponseJson(json) {
  var decodedIssuesCount = Json.flatDecodeDictField(Json.decodeDict(json), "issuesCount", Json.decodeNumber);
  var decodedPatchesCount = Json.flatDecodeDictField(Json.decodeDict(json), "patchesCount", Json.decodeNumber);
  var decodedFileUrl = Json.flatDecodeDictFieldString(Json.flatDecodeDictField(Json.decodeDict(json), "file", Json.decodeDict), "url");
  if (decodedIssuesCount !== undefined && decodedPatchesCount !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: {
              issuesCount: decodedIssuesCount | 0,
              patchesCount: decodedPatchesCount | 0,
              fileUrl: Belt_Option.map(decodedFileUrl, (function (prim) {
                      return new URL(prim);
                    }))
            }
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: /* MalformedResponse */1
          };
  }
}

function renderPatchesText(patchesCount) {
  return React.createElement(TextStyle.make, {
              children: $$Intl.template("▪ " + $$Intl.t($$Intl.isPlural(patchesCount) ? "{{productCount}} new products have been successfully centralized." : "1 new product has been successfully centralized."), {
                    productCount: String(patchesCount)
                  }, undefined),
              variation: "success",
              weight: "medium"
            });
}

function renderIssuesText(issuesCount) {
  return React.createElement(TextStyle.make, {
              children: $$Intl.template("▪ " + $$Intl.t($$Intl.isPlural(issuesCount) ? "{{productCount}} errors detected, some products could not be centralized." : "1 error detected, a product could not be centralized."), {
                    productCount: String(issuesCount)
                  }, undefined),
              variation: "negative",
              weight: "medium"
            });
}

var make$1 = React.memo(function (Props) {
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestClose = Props.onRequestClose;
      return React.createElement(Stack.make, {
                  children: null,
                  space: "xxlarge"
                }, React.createElement(Stack.make, {
                      children: null,
                      space: "medium"
                    }, React.createElement(Stack.make, {
                          children: null
                        }, React.createElement(TextStyle.make, {
                              children: $$Intl.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                            }), React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(TextStyle.make, {
                                          children: "▪ " + $$Intl.t("no new product has been centralized."),
                                          weight: "medium"
                                        })), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(Inline.make, {
                          children: null,
                          space: "none"
                        }, React.createElement(TextStyle.make, {
                              children: $$Intl.t("To know more about the catalog centralization") + ", "
                            }), React.createElement(TextAction.make, {
                              text: $$Intl.t("please visit the help center."),
                              highlighted: true,
                              onPress: onPressHelpCenterText
                            }))), React.createElement(Inline.make, {
                      children: React.createElement(Button.make, Button.makeProps($$Intl.t("Close"), "large", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                  Curry._1(onRequestClose, undefined);
                                }), undefined, undefined, undefined)),
                      align: "end",
                      grow: true
                    }));
    });

var NoIssueNoPatchView = {
  make: make$1
};

var make$2 = React.memo(function (Props) {
      var issuesCount = Props.issuesCount;
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestDownloadFile = Props.onRequestDownloadFile;
      return React.createElement(Stack.make, {
                  children: null,
                  space: "xxlarge"
                }, React.createElement(Stack.make, {
                      children: null,
                      space: "medium"
                    }, React.createElement(Stack.make, {
                          children: null
                        }, React.createElement(TextStyle.make, {
                              children: $$Intl.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                            }), React.createElement(Box.make, Box.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(TextStyle.make, {
                                  children: "▪ " + $$Intl.t("no new product has been centralized."),
                                  weight: "medium"
                                }), renderIssuesText(issuesCount))), React.createElement(TextStyle.make, {
                          children: $$Intl.t("The products could not be centralized because identical SKUs were detected within the same store. Click on the \"Download errors reporting\" button to retrieve the list of products to correct.")
                        }), React.createElement(Inline.make, {
                          children: null,
                          space: "none"
                        }, React.createElement(TextStyle.make, {
                              children: $$Intl.t("To find out what corrections need to be made") + ", "
                            }), React.createElement(TextAction.make, {
                              text: $$Intl.t("please visit the help center."),
                              highlighted: true,
                              onPress: onPressHelpCenterText
                            }))), React.createElement(Inline.make, {
                      children: React.createElement(Button.make, Button.makeProps($$Intl.t("Download errors reporting"), "large", "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                  Curry._1(onRequestDownloadFile, undefined);
                                }), undefined, undefined, undefined)),
                      align: "end",
                      grow: true
                    }));
    });

var IssueNoPatchView = {
  make: make$2
};

var make$3 = React.memo(function (Props) {
      var patchesCount = Props.patchesCount;
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestClose = Props.onRequestClose;
      return React.createElement(Stack.make, {
                  children: React.createElement(Stack.make, {
                        children: null,
                        space: "xxlarge"
                      }, React.createElement(Stack.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Stack.make, {
                                children: null
                              }, React.createElement(TextStyle.make, {
                                    children: $$Intl.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                                  }), React.createElement(Box.make, Box.makeProps(Caml_option.some(renderPatchesText(patchesCount)), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(Inline.make, {
                                children: null,
                                space: "none"
                              }, React.createElement(TextStyle.make, {
                                    children: $$Intl.t("To know more about the catalog centralization") + ", "
                                  }), React.createElement(TextAction.make, {
                                    text: $$Intl.t("please visit the help center."),
                                    highlighted: true,
                                    onPress: onPressHelpCenterText
                                  }))), React.createElement(Inline.make, {
                            children: React.createElement(Button.make, Button.makeProps($$Intl.t("Close"), "large", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                        Curry._1(onRequestClose, undefined);
                                      }), undefined, undefined, undefined)),
                            align: "end",
                            grow: true
                          })),
                  space: "xxlarge"
                });
    });

var NoIssuePatchView = {
  make: make$3
};

var make$4 = React.memo(function (Props) {
      var issuesCount = Props.issuesCount;
      var patchesCount = Props.patchesCount;
      var onPressHelpCenterText = Props.onPressHelpCenterText;
      var onRequestDownloadFile = Props.onRequestDownloadFile;
      return React.createElement(Stack.make, {
                  children: React.createElement(Stack.make, {
                        children: null,
                        space: "xxlarge"
                      }, React.createElement(Stack.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Stack.make, {
                                children: null
                              }, React.createElement(TextStyle.make, {
                                    children: $$Intl.t("Following the launch of the catalog centralization operation, here are the results obtained:")
                                  }), React.createElement(Box.make, Box.makeProps(Caml_option.some(null), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined), renderPatchesText(patchesCount), renderIssuesText(issuesCount))), React.createElement(TextStyle.make, {
                                children: $$Intl.t("The products could not be centralized because identical SKUs were detected within the same store. Click on the \"Download errors reporting\" button to retrieve the list of products to correct.")
                              }), React.createElement(Inline.make, {
                                children: null,
                                space: "none"
                              }, React.createElement(TextStyle.make, {
                                    children: $$Intl.t("To find out what corrections need to be made") + ", "
                                  }), React.createElement(TextAction.make, {
                                    text: $$Intl.t("please visit the help center."),
                                    highlighted: true,
                                    onPress: onPressHelpCenterText
                                  }))), React.createElement(Inline.make, {
                            children: React.createElement(Button.make, Button.makeProps($$Intl.t("Download errors reporting"), "large", "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                        Curry._1(onRequestDownloadFile, undefined);
                                      }), undefined, undefined, undefined)),
                            align: "end",
                            grow: true
                          })),
                  space: "xxlarge"
                });
    });

var IssuePatchView = {
  make: make$4
};

var make$5 = React.memo(function (Props) {
      var issuesCount = Props.issuesCount;
      var patchesCount = Props.patchesCount;
      var fileUrl = Props.fileUrl;
      var onRequestErrorNotification = Props.onRequestErrorNotification;
      var onRequestClose = Props.onRequestClose;
      var match = React.useState(function () {
            return true;
          });
      var setOpened = match[1];
      var onPressHelpCenterText = function (param) {
        HelpCenter.showArticle(HelpCenter.howCentralizeCatalog);
      };
      var onRequestClose$1 = function (param) {
        Curry._1(setOpened, (function (param) {
                return false;
              }));
        Curry._1(onRequestClose, undefined);
      };
      var onRequestDownloadFile = function (param) {
        Belt_Option.forEach(fileUrl, (function (url) {
                Future.get(Future.mapError(TriggerDownload.fromUrl(undefined, url), undefined, (function (param) {
                            return Curry._1(onRequestErrorNotification, $$Intl.t("An issue when attempting downloading the file occurred."));
                          })), (function (param) {
                        Curry._1(setOpened, (function (param) {
                                return false;
                              }));
                        Curry._1(onRequestClose, undefined);
                      }));
              }));
      };
      var match$1 = issuesCount > 0;
      var match$2 = patchesCount > 0;
      return React.createElement(Modal.make, {
                  children: React.createElement(Box.make, Box.makeProps(Caml_option.some(match$1 ? (
                                  match$2 ? React.createElement(make$4, {
                                          issuesCount: issuesCount,
                                          patchesCount: patchesCount,
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestDownloadFile: onRequestDownloadFile
                                        }) : React.createElement(make$2, {
                                          issuesCount: issuesCount,
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestDownloadFile: onRequestDownloadFile
                                        })
                                ) : (
                                  match$2 ? React.createElement(make$3, {
                                          patchesCount: patchesCount,
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestClose: onRequestClose$1
                                        }) : React.createElement(make$1, {
                                          onPressHelpCenterText: onPressHelpCenterText,
                                          onRequestClose: onRequestClose$1
                                        })
                                )), "xlarge", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                  opened: match[0],
                  title: $$Intl.t("Centralize catalogs"),
                  variation: "secondary",
                  hideFooter: true,
                  onRequestClose: onRequestClose$1
                });
    });

var ReportModal = {
  renderPatchesText: renderPatchesText,
  renderIssuesText: renderIssuesText,
  NoIssueNoPatchView: NoIssueNoPatchView,
  IssueNoPatchView: IssueNoPatchView,
  NoIssuePatchView: NoIssuePatchView,
  IssuePatchView: IssuePatchView,
  make: make$5
};

function CatalogListPage$CatalogListCentralizeRequestMenuItem(Props) {
  var text = Props.text;
  var onRequestErrorNotification = Props.onRequestErrorNotification;
  var match = Popover.useState(undefined);
  var match$1 = React.useState(function () {
        return /* NotAsked */0;
      });
  var setAsyncResult = match$1[1];
  var asyncResult = match$1[0];
  React.useEffect((function () {
          if (typeof asyncResult !== "number" && asyncResult.TAG === /* Done */1 && asyncResult._0.TAG !== /* Ok */0) {
            Curry._1(onRequestErrorNotification, $$Request.serverErrorMessage);
          }
          
        }), [asyncResult]);
  var request = function (param) {
    return Future.map($$Request.make("POST", Caml_option.some(Json.encodeDict({})), undefined, undefined, endpoint$2), undefined, (function (json) {
                  return Belt_Result.flatMap(json, decodeRequestResponseJson);
                }));
  };
  var onChange = function (asyncResult) {
    if (typeof asyncResult !== "number" && asyncResult.TAG === /* Done */1) {
      var match = asyncResult._0;
      if (match.TAG !== /* Ok */0) {
        return Curry._1(setAsyncResult, (function (param) {
                      return asyncResult;
                    }));
      }
      var heuristicTimeout = 1000 + Math.imul(match._0.patchesCount, 175) | 0;
      setTimeout((function (param) {
              Curry._1(setAsyncResult, (function (param) {
                      return asyncResult;
                    }));
            }), heuristicTimeout);
      return ;
    }
    Curry._1(setAsyncResult, (function (param) {
            return asyncResult;
          }));
  };
  var operableRequest = {
    TAG: /* Ok */0,
    _0: request
  };
  var tmp;
  if (typeof asyncResult === "number" || asyncResult.TAG !== /* Done */1) {
    tmp = null;
  } else {
    var match$2 = asyncResult._0;
    if (match$2.TAG === /* Ok */0) {
      var match$3 = match$2._0;
      tmp = React.createElement(make$5, {
            issuesCount: match$3.issuesCount,
            patchesCount: match$3.patchesCount,
            fileUrl: match$3.fileUrl,
            onRequestErrorNotification: onRequestErrorNotification,
            onRequestClose: match.close
          });
    } else {
      tmp = null;
    }
  }
  return React.createElement(React.Fragment, undefined, React.createElement(SpinnerModal.make, {
                  title: text,
                  opened: AsyncResult.isBusy(asyncResult)
                }), React.createElement(RequestMenuItem.make, {
                  text: text,
                  operableRequest: operableRequest,
                  onChange: onChange
                }), tmp);
}

var CatalogListCentralizeRequestMenuItem = {
  endpoint: endpoint$2,
  encodeRequestBodyJson: encodeRequestBodyJson$2,
  decodeRequestResponseJson: decodeRequestResponseJson,
  ReportModal: ReportModal,
  make: CatalogListPage$CatalogListCentralizeRequestMenuItem
};

function getStatuses(cavavinUserPermitted) {
  if (cavavinUserPermitted) {
    return [
            /* Active */0,
            /* Inactive */1,
            /* Archived */2
          ];
  } else {
    return [
            /* Unarchived */3,
            /* Archived */2
          ];
  }
}

var supplier = JsonCodec.object2((function (param) {
        return [
                Belt_Option.getWithDefault(Caml_option.nullable_to_opt(param.id), "null"),
                param.name
              ];
      }), (function (param) {
        var id = param[0];
        return {
                TAG: /* Ok */0,
                _0: {
                  id: id === "null" ? null : id,
                  name: param[1]
                }
              };
      }), JsonCodec.field("id", JsonCodec.string), JsonCodec.field("name", JsonCodec.string));

var category = JsonCodec.object2((function (param) {
        return [
                Belt_Option.getWithDefault(Caml_option.nullable_to_opt(param.id), "null"),
                param.name
              ];
      }), (function (param) {
        var id = param[0];
        return {
                TAG: /* Ok */0,
                _0: {
                  id: id === "null" ? null : id,
                  name: param[1]
                }
              };
      }), JsonCodec.field("id", JsonCodec.string), JsonCodec.field("name", JsonCodec.string));

var stock = JsonCodec.object2((function (param) {
        return [
                param.min,
                param.max
              ];
      }), (function (param) {
        return {
                TAG: /* Ok */0,
                _0: {
                  min: param[0],
                  max: param[1]
                }
              };
      }), JsonCodec.optional(JsonCodec.field("min", JsonCodec.$$float)), JsonCodec.optional(JsonCodec.field("max", JsonCodec.$$float)));

function jsonCodec(shops) {
  return JsonCodec.object6((function (param) {
                return [
                        Belt_Option.map(param.shop, (function (shop) {
                                return shop.id;
                              })),
                        Belt_Option.map(param.status, CatalogProduct.Status.toString),
                        param.category,
                        param.supplier,
                        param.producer,
                        param.stock
                      ];
              }), (function (param) {
                var shopId = param[0];
                var match = Belt_Option.map(param[1], CatalogProduct.Status.fromString);
                var tmp;
                tmp = match !== undefined && match.TAG === /* Ok */0 ? match._0 : undefined;
                return {
                        TAG: /* Ok */0,
                        _0: {
                          shop: Belt_Array.getBy(shops, (function (shop) {
                                  return shop.id === shopId;
                                })),
                          status: tmp,
                          category: param[2],
                          supplier: param[3],
                          producer: param[4],
                          stock: param[5]
                        }
                      };
              }), JsonCodec.optional(JsonCodec.field("shopId", JsonCodec.string)), JsonCodec.optional(JsonCodec.field("status", JsonCodec.string)), JsonCodec.optional(JsonCodec.field("category", category)), JsonCodec.optional(JsonCodec.field("supplier", supplier)), JsonCodec.optional(JsonCodec.field("producer", JsonCodec.string)), JsonCodec.optional(JsonCodec.field("stock", stock)));
}

var Raw$2 = {};

var query$2 = Client.gql(["query productRegions($country: String)  {\nproductRegions(country: $country)  \n}\n"]);

function parse$2(value) {
  var value$1 = value.productRegions;
  return {
          productRegions: Js_array.map((function (value) {
                  return value;
                }), value$1)
        };
}

function serialize$2(value) {
  var value$1 = value.productRegions;
  var productRegions = Js_array.map((function (value) {
          return value;
        }), value$1);
  return {
          productRegions: productRegions
        };
}

function serializeVariables$2(inp) {
  var a = inp.country;
  return {
          country: a !== undefined ? a : undefined
        };
}

function makeVariables$2(country, param) {
  return {
          country: country
        };
}

function makeDefaultVariables$2(param) {
  return {
          country: undefined
        };
}

var Query_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  makeDefaultVariables: makeDefaultVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var Query_refetchQueryDescription = include$2.refetchQueryDescription;

var Query_useLazy = include$2.useLazy;

var Query_useLazyWithVariables = include$2.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  makeDefaultVariables: makeDefaultVariables$2,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use$2,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function CatalogListPage$CatalogListFilters$RegionSelect(Props) {
  var disabled = Props.disabled;
  var value = Props.value;
  var onChange = Props.onChange;
  var results = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          country: "France"
        }
      ]);
  var match = results.data;
  var items = match !== undefined ? Belt_Array.map(Belt_Array.keep(match.productRegions, (function (suggestion) {
                return suggestion !== "";
              })), (function (productRegions) {
            return {
                    key: productRegions,
                    value: productRegions,
                    label: productRegions
                  };
          })) : [];
  var defaultItem_label = $$Intl.t("All");
  var defaultItem_sticky = true;
  var defaultItem = {
    key: "default",
    label: defaultItem_label,
    sticky: defaultItem_sticky
  };
  var sections = [
    {
      items: [defaultItem]
    },
    {
      items: items,
      title: $$Intl.t("Regions")
    }
  ];
  return React.createElement(Tooltip.make, {
              children: React.createElement(Select.make, {
                    label: $$Intl.t("Regions"),
                    disabled: disabled,
                    sections: sections,
                    preset: "filter",
                    size: "compact",
                    value: value,
                    onChange: onChange
                  }),
              placement: "top",
              content: {
                TAG: /* Text */0,
                _0: $$Intl.t("Please select a shop beforehand.")
              },
              disabled: !disabled,
              delay: 0
            });
}

var RegionSelect = {
  Query: Query,
  make: CatalogListPage$CatalogListFilters$RegionSelect
};

var CatalogListFilters = {
  getStatuses: getStatuses,
  supplier: supplier,
  category: category,
  stock: stock,
  jsonCodec: jsonCodec,
  RegionSelect: RegionSelect
};

function toSortDescriptor(sort) {
  switch (sort.TAG | 0) {
    case /* Name */0 :
        return {
                column: "reference",
                direction: sort._0
              };
    case /* Producer */1 :
        return {
                column: "producer",
                direction: sort._0
              };
    case /* PurchasePrice */2 :
        return {
                column: "purchase-price",
                direction: sort._0
              };
    case /* CreatedAt */3 :
        return {
                column: "created-at",
                direction: sort._0
              };
    
  }
}

function fromSortDescriptor(sort) {
  switch (sort.column) {
    case "created-at" :
        return {
                TAG: /* Ok */0,
                _0: {
                  TAG: /* CreatedAt */3,
                  _0: sort.direction
                }
              };
    case "producer" :
        return {
                TAG: /* Ok */0,
                _0: {
                  TAG: /* Producer */1,
                  _0: sort.direction
                }
              };
    case "purchase-price" :
        return {
                TAG: /* Ok */0,
                _0: {
                  TAG: /* PurchasePrice */2,
                  _0: sort.direction
                }
              };
    case "reference" :
        return {
                TAG: /* Ok */0,
                _0: {
                  TAG: /* Name */0,
                  _0: sort.direction
                }
              };
    default:
      return {
              TAG: /* Error */1,
              _0: undefined
            };
  }
}

function jsonCodec$1(param) {
  return JsonCodec.object2((function (sort) {
                return [
                        toSortDescriptor(sort).column,
                        toSortDescriptor(sort).direction
                      ];
              }), (function (param) {
                var direction = param[1] === "descending" ? "descending" : "ascending";
                var ok = fromSortDescriptor({
                      column: param[0],
                      direction: direction
                    });
                if (ok.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: ok._0
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: {
                            NAME: "SyntaxError",
                            VAL: "Invalid sort descriptor"
                          }
                        };
                }
              }), JsonCodec.field("column", JsonCodec.string), JsonCodec.field("direction", JsonCodec.string));
}

var CatalogListSorting = {
  toSortDescriptor: toSortDescriptor,
  fromSortDescriptor: fromSortDescriptor,
  jsonCodec: jsonCodec$1
};

function keyExtractor(param) {
  return param.id;
}

var CatalogListTableRow = {
  keyExtractor: keyExtractor
};

function makeFromFilters(filters) {
  var status = filters.status;
  var shopId = Belt_Option.map(filters.shop, (function (param) {
          return param.id;
        }));
  var variantsActive = status !== undefined ? (
      status !== 1 ? (
          status !== 0 ? undefined : true
        ) : false
    ) : undefined;
  var variantsArchived = status !== undefined ? (
      status !== 2 ? false : true
    ) : undefined;
  var categoryId = Belt_Option.map(filters.category, (function (category) {
          return category.id;
        }));
  var supplierId = Belt_Option.map(filters.supplier, (function (supplier) {
          return supplier.id;
        }));
  return {
          shopId: shopId,
          variantsActive: variantsActive,
          variantsArchived: variantsArchived,
          categoryId: categoryId,
          supplierId: supplierId,
          producer: filters.producer,
          stock: filters.stock
        };
}

var CatalogListSheetExportInput = {
  makeFromFilters: makeFromFilters
};

function catalogListMultiShopsTableRowsFromQueryResult(queryResult) {
  return Belt_Array.map(queryResult.variantsDistinctOnCku.edges, (function (edge) {
                var match = edge.node.formattedStatus;
                var match$1 = edge.node.capacityUnit;
                var match$2 = Belt_Option.getWithDefault(edge.node.bulk, false);
                return {
                        cku: edge.node.cku,
                        id: edge.node.id,
                        createdAt: edge.node.createdAt,
                        productId: edge.node.product.id,
                        name: edge.node.name,
                        productName: edge.node.product.name,
                        productKind: edge.node.product.kind,
                        designation: edge.node.product.designation,
                        family: edge.node.product.family,
                        wineType: edge.node.product.wineType,
                        whiteWineType: edge.node.product.whiteWineType,
                        beerType: edge.node.product.beerType,
                        color: edge.node.product.color,
                        producer: edge.node.product.producer,
                        categoryName: Belt_Option.getWithDefault(edge.node.formattedCategory, "—"),
                        supplierName: Belt_Option.map(edge.node.supplier, (function (supplier) {
                                return supplier.companyName;
                              })),
                        region: edge.node.product.region,
                        country: edge.node.product.country,
                        purchasePrice: Belt_Option.getWithDefault(edge.node.purchasedPrice, 0),
                        formattedPurchasePrice: edge.node.formattedPurchasedPrice,
                        stockKeepingUnit: edge.node.stockKeepingUnit,
                        priceLookUpCode: edge.node.priceLookUpCode,
                        internalCode: edge.node.internalCode,
                        stockQuantity: edge.node.stock.formattedQuantity,
                        stockState: edge.node.stock.state,
                        formattedShopsNames: edge.node.stock.formattedShopsNames,
                        status: match === "INACTIVE" ? /* Inactive */1 : (
                            match === "ARCHIVED" ? /* Archived */2 : (
                                match === "ACTIVE" ? /* Active */0 : undefined
                              )
                          ),
                        bulkUnit: match$1 !== undefined && match$2 ? match$1 : undefined,
                        maxStockThreshold: Belt_Option.getWithDefault(edge.node.maxStockThreshold, 0),
                        minStockThreshold: Belt_Option.getWithDefault(edge.node.minStockThreshold, 0),
                        stockOrderTriggerThreshold: Belt_Option.getWithDefault(edge.node.stockOrderTriggerThreshold, 0),
                        bulk: Belt_Option.getWithDefault(edge.node.bulk, false),
                        alcoholVolume: edge.node.alcoholVolume
                      };
              }));
}

function catalogListSingleShopTableRowsFromQueryResult(queryResult) {
  return Belt_Array.map(queryResult.variants.edges, (function (edge) {
                var match = edge.node.formattedStatus;
                var match$1 = edge.node.capacityUnit;
                var match$2 = Belt_Option.getWithDefault(edge.node.bulk, false);
                return {
                        cku: edge.node.cku,
                        id: edge.node.id,
                        createdAt: edge.node.createdAt,
                        productId: edge.node.product.id,
                        name: edge.node.name,
                        productName: edge.node.product.name,
                        productKind: edge.node.product.kind,
                        designation: edge.node.product.designation,
                        family: edge.node.product.family,
                        wineType: edge.node.product.wineType,
                        whiteWineType: edge.node.product.whiteWineType,
                        beerType: edge.node.product.beerType,
                        color: edge.node.product.color,
                        producer: edge.node.product.producer,
                        categoryName: Belt_Option.getWithDefault(edge.node.formattedCategory, "—"),
                        supplierName: Belt_Option.map(edge.node.supplier, (function (supplier) {
                                return supplier.companyName;
                              })),
                        region: edge.node.product.region,
                        country: edge.node.product.country,
                        purchasePrice: Belt_Option.getWithDefault(edge.node.purchasedPrice, 0),
                        retailPriceInformation: Belt_Option.flatMap(Belt_Array.getBy(edge.node.variantPrices.edges, (function (variantPrice) {
                                    var match = variantPrice.node.price;
                                    var match$1 = edge.node.variantPrices.edges.length > 1;
                                    if (match !== undefined && (match.enableByDefault || !match$1)) {
                                      return true;
                                    } else {
                                      return false;
                                    }
                                  })), (function (variantPrice) {
                                var price = variantPrice.node.price;
                                if (price !== undefined) {
                                  return {
                                          name: price.name,
                                          value: price.taxIncluded ? variantPrice.node.valueIncludingTax : variantPrice.node.valueExcludingTax,
                                          taxIncluded: price.taxIncluded
                                        };
                                }
                                
                              })),
                        formattedPurchasePrice: edge.node.formattedPurchasedPrice,
                        stockKeepingUnit: edge.node.stockKeepingUnit,
                        priceLookUpCode: edge.node.priceLookUpCode,
                        internalCode: edge.node.internalCode,
                        stockQuantity: edge.node.stock.formattedQuantity,
                        stockState: edge.node.stock.state,
                        formattedShopsNames: edge.node.stock.formattedShopsNames,
                        status: match === "INACTIVE" ? /* Inactive */1 : (
                            match === "ARCHIVED" ? /* Archived */2 : (
                                match === "ACTIVE" ? /* Active */0 : undefined
                              )
                          ),
                        bulkUnit: match$1 !== undefined && match$2 ? match$1 : undefined,
                        maxStockThreshold: Belt_Option.getWithDefault(edge.node.maxStockThreshold, 0),
                        minStockThreshold: Belt_Option.getWithDefault(edge.node.minStockThreshold, 0),
                        stockOrderTriggerThreshold: Belt_Option.getWithDefault(edge.node.stockOrderTriggerThreshold, 0),
                        bulk: Belt_Option.getWithDefault(edge.node.bulk, false),
                        alcoholVolume: edge.node.alcoholVolume
                      };
              }));
}

function catalogListMultiShopsQueryVariablesFilterBy(param) {
  var status = param.status;
  var shop = param.shop;
  return {
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined,
          active: status !== undefined ? (
              status !== 1 ? (
                  status !== 0 ? undefined : ({
                        _equals: true
                      })
                ) : ({
                    _equals: false
                  })
            ) : undefined,
          archived: status !== undefined ? (
              status !== 2 ? "EXCLUDED" : "ONLY"
            ) : "INCLUDED"
        };
}

function catalogListMultiShopsQueryVariablesOrderBy(sort) {
  var toRawDirection = function (direction) {
    if (direction === "ascending") {
      return "ASC";
    } else {
      return "DESC";
    }
  };
  switch (sort.TAG | 0) {
    case /* Name */0 :
        return {
                createdAt: undefined,
                active: undefined,
                name: toRawDirection(sort._0),
                producer: undefined,
                purchasedPrice: undefined
              };
    case /* Producer */1 :
        return {
                createdAt: undefined,
                active: undefined,
                name: undefined,
                producer: toRawDirection(sort._0),
                purchasedPrice: undefined
              };
    case /* PurchasePrice */2 :
        return {
                createdAt: undefined,
                active: undefined,
                name: undefined,
                producer: undefined,
                purchasedPrice: toRawDirection(sort._0)
              };
    case /* CreatedAt */3 :
        return {
                createdAt: toRawDirection(sort._0),
                active: undefined,
                name: undefined,
                producer: undefined,
                purchasedPrice: undefined
              };
    
  }
}

function catalogListSingleShopQueryVariablesFilterBy(param) {
  var stock = param.stock;
  var producer = param.producer;
  var supplier = param.supplier;
  var category = param.category;
  var status = param.status;
  var shop = param.shop;
  var tmp;
  if (category !== undefined) {
    var categoryId = category.id;
    tmp = {
      _equals: (categoryId == null) ? null : categoryId
    };
  } else {
    tmp = undefined;
  }
  var tmp$1;
  if (supplier !== undefined) {
    var supplierId = supplier.id;
    tmp$1 = {
      _equals: (supplierId == null) ? null : supplierId
    };
  } else {
    tmp$1 = undefined;
  }
  return {
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined,
          stockKeepingUnit: undefined,
          ean13: undefined,
          active: status !== undefined ? (
              status !== 1 ? (
                  status !== 0 ? undefined : ({
                        _equals: true
                      })
                ) : ({
                    _equals: false
                  })
            ) : undefined,
          archived: status !== undefined ? (
              status !== 2 ? "EXCLUDED" : "ONLY"
            ) : "INCLUDED",
          createdAt: undefined,
          updatedAt: undefined,
          categoryId: tmp,
          supplierId: tmp$1,
          stock: stock !== undefined ? ({
                _min: stock.min,
                _max: stock.max,
                _between: undefined
              }) : undefined,
          producer: producer !== undefined ? ({
                _equals: producer
              }) : undefined
        };
}

function catalogListSingleShopQueryVariablesOrderBy(sort) {
  var toRawDirection = function (direction) {
    if (direction === "ascending") {
      return "ASC";
    } else {
      return "DESC";
    }
  };
  switch (sort.TAG | 0) {
    case /* Name */0 :
        return {
                createdAt: undefined,
                active: undefined,
                name: toRawDirection(sort._0),
                producer: undefined,
                purchasedPrice: undefined
              };
    case /* Producer */1 :
        return {
                createdAt: undefined,
                active: undefined,
                name: undefined,
                producer: toRawDirection(sort._0),
                purchasedPrice: undefined
              };
    case /* PurchasePrice */2 :
        return {
                createdAt: undefined,
                active: undefined,
                name: undefined,
                producer: undefined,
                purchasedPrice: toRawDirection(sort._0)
              };
    case /* CreatedAt */3 :
        return {
                createdAt: toRawDirection(sort._0),
                active: undefined,
                name: undefined,
                producer: undefined,
                purchasedPrice: undefined
              };
    
  }
}

function catalogListMultiShopsQueryVariables(param) {
  var match = param.connectionArguments;
  return {
          search: param.searchQuery,
          filterBy: catalogListMultiShopsQueryVariablesFilterBy(param.filters),
          orderBy: [catalogListMultiShopsQueryVariablesOrderBy(param.sorting)],
          before: match.before,
          after: match.after,
          first: match.first,
          last: match.last
        };
}

function catalogListSingleShopQueryVariables(param) {
  var match = param.connectionArguments;
  return {
          search: param.searchQuery,
          filterBy: catalogListSingleShopQueryVariablesFilterBy(param.filters),
          orderBy: [catalogListSingleShopQueryVariablesOrderBy(param.sorting)],
          before: match.before,
          after: match.after,
          first: match.first,
          last: match.last
        };
}

function CatalogListPage(Props) {
  var cavavinUserPermitted = Props.cavavinUserPermitted;
  var variantIdfromLabelEditSettings = Props.variantIdfromLabelEditSettings;
  var shops = Auth.useShops(undefined);
  var authScope = Auth.useScope(undefined);
  var activeShop = Auth.useActiveShop(undefined);
  var authDispatch = Auth.useDispatch(undefined);
  var match = Auth.useScope(undefined);
  var organisationAccount;
  organisationAccount = match.TAG === /* Organisation */0 ? true : false;
  var initialFilterStatus = cavavinUserPermitted ? /* Active */0 : /* Unarchived */3;
  var initialFilters = {
    shop: activeShop,
    status: initialFilterStatus,
    category: undefined,
    supplier: undefined,
    producer: undefined,
    stock: undefined
  };
  var initialState = ResourceList.initialState(ResourceList.defaultEdgesPerPage, initialFilters, {
        TAG: /* Name */0,
        _0: "ascending"
      }, undefined);
  var filtersJsonCodec = jsonCodec(shops);
  var sortingJsonCodec = jsonCodec$1(undefined);
  var resourceListPropState = ResourceList.use(initialState, filtersJsonCodec, sortingJsonCodec);
  var dispatch = resourceListPropState[1];
  var state = resourceListPropState[0];
  var match$1 = React.useState(function () {
        
      });
  var setPrinterStatusResult = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setNotification = match$2[1];
  var queryMultiShopsAsyncResult = ApolloHelpers.queryResultToAsyncResult(Curry.app(use, [
            undefined,
            undefined,
            undefined,
            undefined,
            /* CacheAndNetwork */0,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            Belt_Option.isSome(state.filters.shop),
            undefined,
            catalogListMultiShopsQueryVariables(state)
          ]));
  var querySingleShopAsyncResult = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* CacheAndNetwork */0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        Belt_Option.isNone(state.filters.shop),
        undefined,
        catalogListSingleShopQueryVariables(state)
      ]);
  var querySingleShopAsyncResult$1 = ApolloHelpers.queryResultToAsyncResult(querySingleShopAsyncResult);
  var onRequestPaginate = React.useCallback((function (action) {
          var startCursor;
          var endCursor;
          var totalCount;
          var exit = 0;
          var exit$1 = 0;
          var exit$2 = 0;
          if (typeof queryMultiShopsAsyncResult === "number" || queryMultiShopsAsyncResult.TAG !== /* Reloading */0) {
            exit$2 = 4;
          } else {
            var match = queryMultiShopsAsyncResult._0;
            if (match.TAG === /* Ok */0) {
              var match$1 = match._0.variantsDistinctOnCku;
              var match$2 = match$1.pageInfo;
              startCursor = match$2.startCursor;
              endCursor = match$2.endCursor;
              totalCount = match$1.totalCount;
            } else {
              exit$2 = 4;
            }
          }
          if (exit$2 === 4) {
            if (typeof querySingleShopAsyncResult$1 === "number" || querySingleShopAsyncResult$1.TAG !== /* Reloading */0) {
              exit$1 = 3;
            } else {
              var match$3 = querySingleShopAsyncResult$1._0;
              if (match$3.TAG === /* Ok */0) {
                var match$4 = match$3._0.variants;
                var match$5 = match$4.pageInfo;
                startCursor = match$5.startCursor;
                endCursor = match$5.endCursor;
                totalCount = match$4.totalCount;
              } else {
                exit$1 = 3;
              }
            }
          }
          if (exit$1 === 3) {
            if (typeof queryMultiShopsAsyncResult === "number" || queryMultiShopsAsyncResult.TAG !== /* Done */1) {
              exit = 2;
            } else {
              var match$6 = queryMultiShopsAsyncResult._0;
              if (match$6.TAG === /* Ok */0) {
                var match$7 = match$6._0.variantsDistinctOnCku;
                var match$8 = match$7.pageInfo;
                startCursor = match$8.startCursor;
                endCursor = match$8.endCursor;
                totalCount = match$7.totalCount;
              } else {
                exit = 2;
              }
            }
          }
          if (exit === 2) {
            if (typeof querySingleShopAsyncResult$1 === "number") {
              return ;
            }
            if (querySingleShopAsyncResult$1.TAG !== /* Done */1) {
              return ;
            }
            var match$9 = querySingleShopAsyncResult$1._0;
            if (match$9.TAG !== /* Ok */0) {
              return ;
            }
            var match$10 = match$9._0.variants;
            var match$11 = match$10.pageInfo;
            startCursor = match$11.startCursor;
            endCursor = match$11.endCursor;
            totalCount = match$10.totalCount;
          }
          var totalPages = ResourceList.totalPages(totalCount, ResourceList.defaultEdgesPerPage);
          var nextPage = ResourceList.nextPage(state, action, totalPages);
          var cursors = [
            startCursor,
            endCursor
          ];
          if (nextPage !== undefined) {
            return Curry._1(dispatch, {
                        TAG: /* Navigated */0,
                        nextPage: nextPage,
                        totalCount: totalCount,
                        cursors: cursors
                      });
          }
          
        }), [
        state.currentPage,
        queryMultiShopsAsyncResult,
        querySingleShopAsyncResult$1
      ]);
  var onTableSortChange = function (sortDescriptor) {
    var sort = fromSortDescriptor(sortDescriptor);
    if (sort.TAG === /* Ok */0) {
      return Curry._1(dispatch, {
                  TAG: /* SortingUpdated */3,
                  _0: sort._0
                });
    }
    
  };
  React.useEffect((function () {
          var match = state.filters.shop;
          if (match === undefined) {
            return ;
          }
          var future = Curry._1(CatalogLabel.Print.DefaultPrinterRequest.make, match.id);
          Future.map(future, undefined, (function (result) {
                  Curry._1(setPrinterStatusResult, (function (param) {
                          return result;
                        }));
                }));
          if (state.filters.shop !== activeShop) {
            Curry._1(authDispatch, {
                  TAG: /* ActiveShopSet */2,
                  _0: state.filters.shop
                });
          }
          return (function (param) {
                    Future.cancel(future);
                  });
        }), [state.filters.shop]);
  ReactUpdateEffect.use1((function (param) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (prev) {
                    return {
                            shop: prev.shop,
                            status: prev.status,
                            category: undefined,
                            supplier: undefined,
                            producer: prev.producer,
                            stock: prev.stock
                          };
                  })
              });
        }), [state.filters.shop]);
  var tableColumns = [
    {
      key: "reference",
      name: $$Intl.t("Name and description"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 275
        },
        width: {
          NAME: "pct",
          VAL: 30
        },
        margin: "xxlarge",
        sticky: true
      },
      allowsSorting: true,
      render: (function (param) {
          var data = param.data;
          var redirectRoute = CatalogRoutes.variantRoute(data.cku);
          var contrasted = param.index % 2 === 1;
          var productKind = data.productKind;
          var information_productName = data.productName;
          var information_variantName = data.name;
          var information_sku = data.stockKeepingUnit;
          var information_plu = Belt_Option.map(data.priceLookUpCode, (function (prim) {
                  return String(prim);
                }));
          var information_color = data.color;
          var information_producerName = data.producer;
          var information_designation = data.designation;
          var information_productFamily = data.family;
          var information_region = data.region;
          var information_country = Belt_Option.getWithDefault(data.country, "—");
          var information_categoryName = data.categoryName;
          var information_supplierName = data.supplierName;
          var information_alcoholVolume = Belt_Option.map(data.alcoholVolume, (function (alcoholVolume) {
                  return $$Intl.decimalFormat(undefined, 1, alcoholVolume) + "°";
                }));
          var information = {
            productName: information_productName,
            variantName: information_variantName,
            sku: information_sku,
            plu: information_plu,
            color: information_color,
            producerName: information_producerName,
            designation: information_designation,
            productFamily: information_productFamily,
            region: information_region,
            country: information_country,
            categoryName: information_categoryName,
            supplierName: information_supplierName,
            alcoholVolume: information_alcoholVolume
          };
          return React.createElement(ProductReferenceTableCell.make, {
                      redirectRoute: redirectRoute,
                      contrasted: contrasted,
                      productKind: productKind,
                      information: information
                    });
        })
    },
    {
      key: "producer",
      name: $$Intl.t("Producer/Supplier"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 140
        },
        width: {
          NAME: "pct",
          VAL: 20
        },
        margin: "xsmall"
      },
      allowsSorting: true,
      render: (function (param) {
          var match = param.data;
          var supplierName = match.supplierName;
          var formattedProducer = Belt_Option.getWithDefault(match.producer, nonApplicableStringLiteral);
          return React.createElement("div", {
                      style: {
                        display: "flex",
                        width: "100%",
                        flexDirection: "column"
                      }
                    }, React.createElement(TextStyle.make, {
                          children: formattedProducer,
                          size: "xsmall",
                          maxLines: 1
                        }), supplierName !== undefined ? React.createElement(TextStyle.make, {
                            children: supplierName,
                            variation: "normal",
                            size: "tiny",
                            lineHeight: "normal",
                            maxLines: 1
                          }) : React.createElement(TextStyle.make, {
                            children: $$Intl.t("Not specified"),
                            variation: "subdued",
                            size: "tiny",
                            lineHeight: "normal",
                            maxLines: 1
                          }));
        })
    },
    {
      key: "category",
      name: $$Intl.t("Category"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 140
        },
        width: {
          NAME: "pct",
          VAL: 18
        }
      },
      render: (function (param) {
          var match = param.data;
          var categoryName = match.categoryName;
          var tmp;
          var exit = 0;
          switch (categoryName) {
            case "Non class\xc3\xa9" :
                tmp = React.createElement(TextStyle.make, {
                      children: $$Intl.t("Not classified"),
                      variation: "subdued",
                      size: "xsmall"
                    });
                break;
            case "VARIABLE" :
                tmp = React.createElement(TextStyle.make, {
                      children: $$Intl.t("Differing"),
                      variation: "normal",
                      size: "xsmall"
                    });
                break;
            default:
              if (authScope.TAG === /* Organisation */0 && authScope.activeShop === undefined) {
                tmp = React.createElement("div", {
                      style: {
                        display: "flex",
                        width: "100%"
                      }
                    }, React.createElement(TextStyle.make, {
                          children: categoryName,
                          size: "xsmall",
                          maxLines: 1,
                          direction: "rtl"
                        }));
              } else {
                exit = 1;
              }
          }
          if (exit === 1) {
            tmp = React.createElement(CatalogCategoryEditCell.make, {
                  compact: true,
                  value: categoryName,
                  productId: match.productId
                });
          }
          return React.createElement(React.Fragment, undefined, tmp);
        })
    },
    {
      key: "stock",
      name: $$Intl.t("Stock"),
      layout: {
        minWidth: Belt_Option.isNone(activeShop) ? ({
              NAME: "px",
              VAL: 130
            }) : ({
              NAME: "px",
              VAL: 85
            }),
        width: {
          NAME: "px",
          VAL: 175
        },
        margin: "small"
      },
      render: (function (param) {
          var match = param.data;
          return React.createElement(CatalogVariantStockThresholdCell.make, {
                      compact: true,
                      stockQuantity: match.stockQuantity,
                      stockState: match.stockState,
                      formattedShopsNames: match.formattedShopsNames,
                      id: match.id,
                      maxStockThreshold: match.maxStockThreshold,
                      minStockThreshold: match.minStockThreshold,
                      stockOrderTriggerThreshold: match.stockOrderTriggerThreshold,
                      bulk: match.bulk
                    });
        })
    },
    {
      key: "purchase-price",
      name: $$Intl.t("Purchase price"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 95
        },
        width: {
          NAME: "px",
          VAL: 200
        }
      },
      allowsSorting: true,
      render: (function (param) {
          var match = param.data;
          var formattedPurchasePrice = match.formattedPurchasePrice;
          var disabled;
          disabled = authScope.TAG === /* Organisation */0 ? authScope.activeShop === undefined : false;
          if (formattedPurchasePrice === "VARIABLE") {
            return React.createElement(TextStyle.make, {
                        children: $$Intl.t("Differing"),
                        variation: "normal",
                        size: "xsmall"
                      });
          }
          return React.createElement(PricePurchaseTableEditCellWrapper.make, {
                      value: match.purchasePrice,
                      formattedPurchasePrice: formattedPurchasePrice,
                      bulkUnit: match.bulkUnit,
                      disabled: disabled,
                      variantId: match.id,
                      compact: true
                    });
        })
    },
    {
      key: "retail-price",
      name: $$Intl.t("Retail price"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 85
        },
        width: {
          NAME: "px",
          VAL: 170
        }
      },
      render: (function (param) {
          var formattedRetailPrice = Belt_Option.mapWithDefault(param.data.retailPriceInformation, "—", (function (price) {
                  return $$Intl.currencyFormat("EUR", undefined, undefined, price.value);
                }));
          if (Belt_Option.isSome(activeShop)) {
            return React.createElement(TextStyle.make, {
                        children: formattedRetailPrice,
                        weight: "strong",
                        size: "xsmall"
                      });
          } else {
            return React.createElement(Tooltip.make, {
                        children: React.createElement(TextStyle.make, {
                              children: "—",
                              variation: "placeholder",
                              size: "xsmall"
                            }),
                        placement: "top",
                        content: {
                          TAG: /* Text */0,
                          _0: $$Intl.t("Please select a shop beforehand.")
                        },
                        delay: 0
                      });
          }
        })
    },
    {
      key: "created-at",
      name: $$Intl.t("Creation date"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 74
        },
        width: {
          NAME: "px",
          VAL: 140
        }
      },
      allowsSorting: true,
      render: (function (param) {
          var createdAt = param.data.createdAt;
          var formattedDatetime = $$Intl.dateTimeFormat(undefined, undefined, "2-digit", "2-digit", "2-digit", undefined, undefined, undefined, createdAt);
          var match = $$Intl.dateTimeFormat(undefined, undefined, undefined, undefined, undefined, "numeric", "numeric", undefined, createdAt);
          var formattedTime = $$Intl.locale === "fr-FR" ? match.replace(":", "h") : match;
          return React.createElement("div", {
                      style: {
                        display: "flex",
                        width: "100%",
                        flexDirection: "column"
                      }
                    }, React.createElement(TextStyle.make, {
                          children: formattedDatetime,
                          size: "xxsmall",
                          maxLines: 1
                        }), React.createElement(TextStyle.make, {
                          children: formattedTime,
                          variation: "normal",
                          size: "tiny",
                          lineHeight: "normal",
                          maxLines: 1
                        }));
        })
    },
    {
      key: "status",
      name: $$Intl.t("Status"),
      layout: {
        breakpoint: "huge",
        defaultWidth: {
          NAME: "px",
          VAL: 100
        }
      },
      render: (function (param) {
          var status = param.data.status;
          if (status !== undefined) {
            return React.createElement(CatalogProductStatusBadge.make, {
                        value: status,
                        size: "medium"
                      });
          } else {
            return React.createElement(TextStyle.make, {
                        children: $$Intl.t("Differing"),
                        variation: "normal",
                        size: "xsmall"
                      });
          }
        })
    },
    {
      key: "quickprint-action",
      layout: {
        hidden: Belt_Option.isNone(state.filters.shop) || Belt_Option.mapWithDefault(match$1[0], true, Belt_Result.isError),
        minWidth: {
          NAME: "px",
          VAL: 40
        },
        width: {
          NAME: "px",
          VAL: 40
        },
        alignX: "flexEnd"
      },
      render: (function (param) {
          var id = param.data.id;
          var match = state.filters.shop;
          if (match !== undefined) {
            return React.createElement(CatalogLabelQuickPrintButton.make, {
                        variation: "vertical",
                        featureLocked: false,
                        fromEditRedirection: variantIdfromLabelEditSettings === id,
                        variantId: id,
                        shopId: match.id,
                        requestBarcodeCompletion: CatalogLabel.BarcodeCompletionRequest.make,
                        requestLabelsPrinting: CatalogLabel.Print.LabelsRequest.make,
                        onRequestNotification: (function (value) {
                            Curry._1(setNotification, (function (param) {
                                    return value;
                                  }));
                          })
                      });
          } else {
            return null;
          }
        })
    },
    {
      key: "actions",
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 40
        },
        width: {
          NAME: "px",
          VAL: 40
        },
        alignX: "flexEnd"
      },
      render: (function (param) {
          var match = param.data;
          var tmp = {
            cku: match.cku,
            id: match.id
          };
          if (match.status !== undefined) {
            tmp.status = Caml_option.valFromOption(match.status);
          }
          return React.createElement(CatalogTableActions.make, tmp);
        })
    }
  ];
  var shopFilter;
  if (organisationAccount) {
    var tmp = {
      variation: "secondary",
      hideLabel: true,
      truncateItemLabel: true,
      onChange: (function (shop) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (prev) {
                    return {
                            shop: shop,
                            status: prev.status,
                            category: prev.category,
                            supplier: prev.supplier,
                            producer: prev.producer,
                            stock: prev.stock
                          };
                  })
              });
        })
    };
    if (state.filters.shop !== undefined) {
      tmp.value = Caml_option.valFromOption(state.filters.shop);
    }
    shopFilter = React.createElement(Auth.SelectShopFilter.make, tmp);
  } else {
    shopFilter = null;
  }
  var statuses = getStatuses(cavavinUserPermitted);
  var defaultItem_label = $$Intl.t("All");
  var defaultItem = {
    key: "default",
    label: defaultItem_label
  };
  var items = Belt_Array.map(statuses, (function (value) {
          return {
                  key: CatalogProduct.Status.toString(value),
                  value: value,
                  label: CatalogProduct.Status.toLabel(value)
                };
        }));
  var filters = [
    React.createElement(Select.make, {
          label: $$Intl.t("Status"),
          highlighted: cavavinUserPermitted ? state.filters.status !== /* Active */0 : state.filters.status !== /* Unarchived */3,
          sections: [{
              items: Belt_Array.concat(items, [defaultItem]),
              title: $$Intl.t("Statuses")
            }],
          preset: "filter",
          size: "compact",
          value: state.filters.status,
          onChange: (function (status) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                status: status,
                                category: prev.category,
                                supplier: prev.supplier,
                                producer: prev.producer,
                                stock: prev.stock
                              };
                      })
                  });
            })
        }),
    React.createElement(CatalogCategorySelectFilter.make, {
          shopId: Belt_Option.map(activeShop, (function (shop) {
                  return shop.id;
                })),
          value: state.filters.category,
          onChange: (function (category) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                status: prev.status,
                                category: category,
                                supplier: prev.supplier,
                                producer: prev.producer,
                                stock: prev.stock
                              };
                      })
                  });
            })
        }),
    React.createElement(SupplierSelect.make, {
          size: "compact",
          label: $$Intl.t("Supplier"),
          preset: "filter",
          showDefaultItem: true,
          hideOverlayFooter: true,
          shopId: Belt_Option.map(activeShop, (function (shop) {
                  return shop.id;
                })),
          value: state.filters.supplier,
          onChange: (function (supplier) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                status: prev.status,
                                category: prev.category,
                                supplier: supplier,
                                producer: prev.producer,
                                stock: prev.stock
                              };
                      })
                  });
            })
        }),
    React.createElement(CatalogProducerSelect.make, {
          shopId: Belt_Option.map(activeShop, (function (shop) {
                  return shop.id;
                })),
          value: state.filters.producer,
          onChange: (function (producer) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                status: prev.status,
                                category: prev.category,
                                supplier: prev.supplier,
                                producer: producer,
                                stock: prev.stock
                              };
                      })
                  });
            })
        }),
    React.createElement(CatalogStockRangeSelect.make, {
          disabled: Belt_Option.isNone(activeShop),
          value: state.filters.stock,
          onChange: (function (stock) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */2,
                    _0: (function (prev) {
                        return {
                                shop: prev.shop,
                                status: prev.status,
                                category: prev.category,
                                supplier: prev.supplier,
                                producer: prev.producer,
                                stock: stock
                              };
                      })
                  });
            })
        })
  ];
  var match$3 = state.filters;
  var status = match$3.status;
  var activeFiltersCount = Belt_Array.keep([
        cavavinUserPermitted ? (
            status !== undefined ? status !== 0 : true
          ) : (
            status !== undefined ? status < 3 : true
          ),
        Belt_Option.isSome(match$3.category),
        Belt_Option.isSome(match$3.supplier),
        Belt_Option.isSome(match$3.producer),
        Belt_Option.isSome(match$3.stock)
      ], (function (active) {
          return active;
        })).length;
  var handleFilterReset = activeFiltersCount > 0 ? (function (param) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (param) {
                  return initialState.filters;
                })
            });
      }) : undefined;
  var match$4 = makeFromFilters(state.filters);
  var variantsArchived = match$4.variantsArchived;
  var variantsActive = match$4.variantsActive;
  var shopId = match$4.shopId;
  var match$5 = Auth.useShops(undefined);
  var shopIds = match$5.length !== 0 ? (
      shopId !== undefined ? [shopId] : Belt_Array.map(match$5, (function (shop) {
                return shop.id;
              }))
    ) : undefined;
  var makeMenuItemAction = function (productKind) {
    return {
            TAG: /* OpenLink */1,
            _0: {
              TAG: /* RouteWithQueryString */1,
              _0: LegacyRouter.routeToPathname(/* Catalog */2) + "/product/create",
              _1: CatalogProductEditUrlQueryString.CreateProduct.encode({
                    productKind: productKind
                  })
            }
          };
  };
  var onRequestErrorNotification = function (error) {
    Curry._1(setNotification, (function (param) {
            return {
                    TAG: /* Error */1,
                    _0: error
                  };
          }));
  };
  var tmp$1;
  if (shopIds !== undefined) {
    var tmp$2 = {
      tooltip: activeFiltersCount === 0 ? $$Intl.t("Export inventory") : $$Intl.t("Export filtered inventory"),
      shopIds: shopIds,
      activeFilters: activeFiltersCount > 0,
      onRequestErrorNotification: onRequestErrorNotification
    };
    if (variantsActive !== undefined) {
      tmp$2.variantsActive = Caml_option.valFromOption(variantsActive);
    }
    if (variantsArchived !== undefined) {
      tmp$2.variantsArchived = Caml_option.valFromOption(variantsArchived);
    }
    if (match$4.categoryId !== undefined) {
      tmp$2.categoryId = Caml_option.valFromOption(match$4.categoryId);
    }
    if (match$4.supplierId !== undefined) {
      tmp$2.supplierId = Caml_option.valFromOption(match$4.supplierId);
    }
    if (match$4.producer !== undefined) {
      tmp$2.producer = Caml_option.valFromOption(match$4.producer);
    }
    if (match$4.stock !== undefined) {
      tmp$2.stock = Caml_option.valFromOption(match$4.stock);
    }
    tmp$1 = React.createElement(CatalogListPage$CatalogListInventoryExportShortIconButton, tmp$2);
  } else {
    tmp$1 = null;
  }
  var tmp$3 = {
    text: $$Intl.t("Export PLU codes"),
    shopId: shopId,
    onRequestErrorNotification: onRequestErrorNotification
  };
  if (variantsActive !== undefined) {
    tmp$3.variantsActive = Caml_option.valFromOption(variantsActive);
  }
  if (variantsArchived !== undefined) {
    tmp$3.variantsArchived = Caml_option.valFromOption(variantsArchived);
  }
  var actions = React.createElement(Inline.make, {
        children: null,
        space: "small"
      }, tmp$1, React.createElement(ShortIconButton.make, ShortIconButton.makeProps("printer", undefined, undefined, undefined, undefined, $$Intl.t("Print labels"), undefined, {
                TAG: /* OpenLink */1,
                _0: {
                  TAG: /* Route */0,
                  _0: LegacyRouter.routeToPathname(/* Catalog */2) + "/labels/create"
                }
              }, undefined, undefined, undefined)), React.createElement(Menu.make, {
            children: React.createElement(React.Fragment, undefined, React.createElement(MenuItem.make, {
                      content: {
                        TAG: /* Text */0,
                        _0: $$Intl.t("Import inventory")
                      },
                      size: "normal",
                      action: {
                        TAG: /* OpenLink */1,
                        _0: {
                          TAG: /* Route */0,
                          _0: LegacyRouter.routeToPathname(/* Catalog */2) + "/inventory/import"
                        }
                      }
                    }), React.createElement(CatalogListPage$CatalogListPriceLookUpExportMenuItem, tmp$3), organisationAccount ? React.createElement(CatalogListPage$CatalogListCentralizeRequestMenuItem, {
                        text: $$Intl.t("Centralize catalogs"),
                        onRequestErrorNotification: onRequestErrorNotification
                      }) : null),
            variation: "more_square",
            disabled: Belt_Option.isNone(shopIds),
            overlayPriority: false
          }), React.createElement(Separator.make, {
            size: "large"
          }), React.createElement(Menu.make, {
            children: null,
            buttonText: $$Intl.t("New reference"),
            buttonVariation: "primary",
            buttonSize: "small"
          }, React.createElement(MenuItem.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct.Kind.toLabel(true, undefined, "WINE")
                },
                action: makeMenuItemAction("WINE")
              }), React.createElement(MenuItem.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct.Kind.toLabel(true, undefined, "SPIRITUOUS")
                },
                action: makeMenuItemAction("SPIRITUOUS")
              }), React.createElement(MenuItem.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct.Kind.toLabel(true, undefined, "BEER")
                },
                action: makeMenuItemAction("BEER")
              }), React.createElement(MenuItem.make, {
                content: {
                  TAG: /* Text */0,
                  _0: CatalogProduct.Kind.toLabel(true, undefined, "SIMPLE")
                },
                action: makeMenuItemAction("SIMPLE")
              })));
  var banner = React.createElement(make, {
        notification: match$2[0],
        onRequestClose: (function (param) {
            Curry._1(setNotification, (function (param) {
                    
                  }));
          })
      });
  var tmp$4 = {
    placeholder: $$Intl.t("Search") + "...",
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$4.value = state.searchQuery;
  }
  var searchBar = React.createElement(ResourceListPage.ResourceListSearchBar.make, tmp$4);
  var tableEmptyState;
  var exit = 0;
  if (state.currentPage !== 1) {
    exit = 1;
  } else {
    var match$6 = state.searchQuery;
    if (match$6 === "") {
      var match$7 = state.filters;
      if (match$7.status !== undefined || match$7.category !== undefined || match$7.supplier !== undefined || match$7.producer !== undefined || match$7.shop !== activeShop) {
        exit = 1;
      } else {
        tableEmptyState = React.createElement(EmptyState.make, {
              illustration: Illustration.create,
              title: $$Intl.t("Welcome to the catalog space.")
            });
      }
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tableEmptyState = React.createElement(EmptyState.make, {
          illustration: Illustration.notFound,
          title: $$Intl.t("No result were found."),
          text: $$Intl.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button.make, Button.makeProps($$Intl.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */4,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  var paginationCurrentPage = state.currentPage;
  var match$8;
  var exit$1 = 0;
  var totalCount;
  var exit$2 = 0;
  var exit$3 = 0;
  var exit$4 = 0;
  if (typeof queryMultiShopsAsyncResult === "number" || queryMultiShopsAsyncResult.TAG !== /* Done */1) {
    exit$4 = 4;
  } else {
    var match$9 = queryMultiShopsAsyncResult._0;
    if (match$9.TAG === /* Ok */0) {
      totalCount = match$9._0.variantsDistinctOnCku.totalCount;
      exit$1 = 1;
    } else {
      exit$4 = 4;
    }
  }
  if (exit$4 === 4) {
    if (typeof querySingleShopAsyncResult$1 === "number" || querySingleShopAsyncResult$1.TAG !== /* Done */1) {
      exit$3 = 3;
    } else {
      var match$10 = querySingleShopAsyncResult$1._0;
      if (match$10.TAG === /* Ok */0) {
        totalCount = match$10._0.variants.totalCount;
        exit$1 = 1;
      } else {
        exit$3 = 3;
      }
    }
  }
  if (exit$3 === 3) {
    if (typeof queryMultiShopsAsyncResult === "number" || queryMultiShopsAsyncResult.TAG !== /* Reloading */0) {
      exit$2 = 2;
    } else {
      var match$11 = queryMultiShopsAsyncResult._0;
      if (match$11.TAG === /* Ok */0) {
        totalCount = match$11._0.variantsDistinctOnCku.totalCount;
        exit$1 = 1;
      } else {
        exit$2 = 2;
      }
    }
  }
  if (exit$2 === 2) {
    if (typeof querySingleShopAsyncResult$1 === "number" || querySingleShopAsyncResult$1.TAG !== /* Reloading */0) {
      match$8 = [
        0,
        1
      ];
    } else {
      var match$12 = querySingleShopAsyncResult$1._0;
      if (match$12.TAG === /* Ok */0) {
        totalCount = match$12._0.variants.totalCount;
        exit$1 = 1;
      } else {
        match$8 = [
          0,
          1
        ];
      }
    }
  }
  if (exit$1 === 1) {
    match$8 = [
      totalCount,
      ResourceList.totalPages(totalCount, ResourceList.defaultEdgesPerPage)
    ];
  }
  var paginationLoading = AsyncResult.isReloading(queryMultiShopsAsyncResult) || AsyncResult.isReloading(querySingleShopAsyncResult$1);
  var match$13 = state.connectionArguments;
  var paginationLastDirection = match$13.last !== undefined ? /* Backward */1 : /* Forward */0;
  var tableData = Belt_Option.isNone(state.filters.shop) ? AsyncResult.mapError(AsyncResult.mapOk(queryMultiShopsAsyncResult, catalogListMultiShopsTableRowsFromQueryResult), (function (param) {
            
          })) : AsyncResult.mapError(AsyncResult.mapOk(querySingleShopAsyncResult$1, catalogListSingleShopTableRowsFromQueryResult), (function (param) {
            
          }));
  var tmp$5 = {
    title: $$Intl.t("Catalog"),
    shopFilter: shopFilter,
    filters: filters,
    activeFiltersCount: activeFiltersCount,
    actions: actions,
    banner: banner,
    searchBar: searchBar,
    tableData: tableData,
    tableColumns: tableColumns,
    tableKeyExtractor: keyExtractor,
    tableEmptyState: tableEmptyState,
    paginationCurrentPage: paginationCurrentPage,
    paginationTotalCount: match$8[0],
    paginationTotalPages: match$8[1],
    paginationLoading: paginationLoading,
    paginationLastDirection: paginationLastDirection,
    tableSortDescriptor: toSortDescriptor(state.sorting),
    onTableSortChange: onTableSortChange,
    onRequestPaginate: onRequestPaginate
  };
  if (handleFilterReset !== undefined) {
    tmp$5.onRequestResetFilters = Caml_option.valFromOption(handleFilterReset);
  }
  return React.createElement(ResourceListPage.make, tmp$5);
}

var make$6 = React.memo(CatalogListPage);

export {
  nonApplicableStringLiteral ,
  CatalogListMultiShopsQuery ,
  CatalogListSingleShopQuery ,
  CatalogListNotificationBanner ,
  CatalogListInventoryExportShortIconButton ,
  CatalogListPriceLookUpExportMenuItem ,
  CatalogListCentralizeRequestMenuItem ,
  CatalogListFilters ,
  CatalogListSorting ,
  CatalogListTableRow ,
  CatalogListSheetExportInput ,
  catalogListMultiShopsTableRowsFromQueryResult ,
  catalogListSingleShopTableRowsFromQueryResult ,
  catalogListMultiShopsQueryVariablesFilterBy ,
  catalogListMultiShopsQueryVariablesOrderBy ,
  catalogListSingleShopQueryVariablesFilterBy ,
  catalogListSingleShopQueryVariablesOrderBy ,
  catalogListMultiShopsQueryVariables ,
  catalogListSingleShopQueryVariables ,
  make$6 as make,
}
/* query Not a pure module */
