// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Badge from "../../resources/feedback-indicators/Badge.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Price from "../Price/Price.bs.js";
import * as React from "react";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as TableView from "../../resources/tables/TableView.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Placeholder from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as Client from "@apollo/client";
import * as TableLayoutPanel from "../../resources/layout-and-structure/TableLayoutPanel.bs.js";
import * as ReactUpdateEffect from "../../primitives/ReactUpdateEffect.bs.js";
import * as PriceRateTableCell from "../Price/PriceRateTableCell.bs.js";
import * as CatalogVariantEditForm from "./CatalogVariantEditForm.bs.js";
import * as PriceRetailTableEditCell from "../Price/PriceRetailTableEditCell.bs.js";
import * as CatalogVariantRetailPrice from "./CatalogVariantRetailPrice.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query prices($filterBy: InputPricesQueryFilter)  {\nprices(first: 50, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nname  \ntaxIncluded  \nshop  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.prices;
  var value$2 = value$1.edges;
  return {
          prices: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              name: value$1.name,
                              taxIncluded: value$1.taxIncluded,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.prices;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.taxIncluded;
          var value$6 = value$1.name;
          var value$7 = value$1.id;
          var value$8 = value$1.__typename;
          var node = {
            __typename: value$8,
            id: value$7,
            name: value$6,
            taxIncluded: value$5,
            shop: shop
          };
          var value$9 = value.__typename;
          return {
                  __typename: value$9,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var prices = {
    __typename: value$3,
    edges: edges
  };
  return {
          prices: prices
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputPricesQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          archived: a$1 !== undefined ? (
              a$1 === "ONLY" ? "ONLY" : (
                  a$1 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputPricesQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputPricesQueryFilter(shopIds, archived, param) {
  return {
          shopIds: shopIds,
          archived: archived
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputPricesQueryFilter: serializeInputObjectInputPricesQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputPricesQueryFilter: makeInputObjectInputPricesQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputPricesQueryFilter: serializeInputObjectInputPricesQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputPricesQueryFilter: makeInputObjectInputPricesQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function keyExtractor(row) {
  return row.id + "-retail";
}

var Row = {
  keyExtractor: keyExtractor
};

function tableColumns(purchasePrice, bulkUnit, onRequestPriceUpdate) {
  return [
          {
            key: "retailprice-list",
            name: $$Intl.t("Price list"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 220
              },
              width: {
                NAME: "fr",
                VAL: 1.5
              }
            },
            render: (function (param) {
                var match = param.data;
                var taxTypename = $$Intl.t(match.taxIncluded ? "VAT incl." : "VAT excl.");
                return React.createElement(Inline.make, {
                            children: null,
                            space: "small"
                          }, React.createElement(TextStyle.make, {
                                children: match.name,
                                weight: "semibold"
                              }), React.createElement(Badge.make, {
                                children: taxTypename,
                                size: "small",
                                variation: "neutral"
                              }));
              })
          },
          {
            key: "retailprice-prices",
            name: $$Intl.t("Retail prices"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 160
              },
              margin: "large"
            },
            render: (function (param) {
                var match = param.data;
                var id = match.id;
                return React.createElement(PriceRetailTableEditCell.make, {
                            value: match.retailPrice,
                            secondaryValue: match.secondaryRetailPrice,
                            purchasePrice: purchasePrice,
                            bulkUnit: bulkUnit,
                            onChange: (function (value) {
                                Curry._2(onRequestPriceUpdate, id, Price.toRawValue(value));
                              })
                          });
              })
          },
          {
            key: "retailprice-marginrate",
            name: $$Intl.t("Margin rt"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 110
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(PriceRateTableCell.make, {
                            rateType: /* MarginRate */0,
                            retailPrice: Belt_Option.getWithDefault(match.secondaryRetailPrice, match.retailPrice),
                            purchasePrice: purchasePrice
                          });
              })
          },
          {
            key: "retailprice-markuprate",
            name: $$Intl.t("Markup rt"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 110
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(PriceRateTableCell.make, {
                            rateType: /* MarkupRate */1,
                            retailPrice: Belt_Option.getWithDefault(match.secondaryRetailPrice, match.retailPrice),
                            purchasePrice: purchasePrice
                          });
              })
          }
        ];
}

function retailPriceUpdater(priceData, priceId, newRawValue, taxRate) {
  var match = priceData.id === priceId;
  var match$1 = priceData.taxIncluded;
  if (match) {
    if (match$1) {
      return {
              id: priceData.id,
              name: priceData.name,
              retailPrice: {
                TAG: /* PriceInclTax */1,
                _0: newRawValue
              },
              secondaryRetailPrice: Price.makeWithTax({
                    TAG: /* PriceInclTax */1,
                    _0: newRawValue
                  }, taxRate),
              taxIncluded: priceData.taxIncluded
            };
    } else {
      return {
              id: priceData.id,
              name: priceData.name,
              retailPrice: {
                TAG: /* PriceExclTax */0,
                _0: newRawValue
              },
              secondaryRetailPrice: priceData.secondaryRetailPrice,
              taxIncluded: priceData.taxIncluded
            };
    }
  } else {
    return priceData;
  }
}

function CatalogVariantEditFormRetailPriceTablePanel(Props) {
  var taxRate = Props.taxRate;
  var match = Curry._1(CatalogVariantEditForm.useFormState, undefined).values;
  var purchasePrice = match.purchasePrice;
  var bulk = match.bulk;
  var capacityUnit = match.capacityUnit;
  var dispatch = Curry._1(CatalogVariantEditForm.useFormDispatch, undefined);
  var activeShop = Auth.useActiveShop(undefined);
  var queryResults = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        {
          filterBy: {
            shopIds: {
              _in: [Belt_Option.mapWithDefault(activeShop, "", (function (param) {
                        return param.id;
                      }))]
            },
            archived: undefined
          }
        }
      ]);
  var match$1 = React.useState(function () {
        return [];
      });
  var setPrices = match$1[1];
  var prices = match$1[0];
  React.useEffect((function () {
          if (prices.length === 0) {
            var match = queryResults.data;
            if (match !== undefined) {
              var prices$1 = match.prices;
              Curry._1(setPrices, (function (param) {
                      return Belt_Array.map(prices$1.edges, (function (param) {
                                    var price = param.node;
                                    return {
                                            id: price.id,
                                            name: price.name,
                                            retailPrice: price.taxIncluded ? ({
                                                  TAG: /* PriceInclTax */1,
                                                  _0: 0
                                                }) : ({
                                                  TAG: /* PriceExclTax */0,
                                                  _0: 0
                                                }),
                                            secondaryRetailPrice: price.taxIncluded ? ({
                                                  TAG: /* PriceExclTax */0,
                                                  _0: 0
                                                }) : undefined,
                                            taxIncluded: price.taxIncluded
                                          };
                                  }));
                    }));
            }
            
          }
          
        }), [
        queryResults,
        purchasePrice
      ]);
  ReactUpdateEffect.use1((function (param) {
          Curry._1(dispatch, {
                TAG: /* FieldValueChanged */2,
                _0: /* VariantPrices */13,
                _1: (function (param) {
                    return Belt_Array.keepMap(prices, (function (priceData) {
                                  var match = CatalogVariantRetailPrice.makeMutationValuesFromRetailPrice(priceData.retailPrice, taxRate);
                                  var valueExcludingTax = match.valueExcludingTax;
                                  if (valueExcludingTax > 0) {
                                    return {
                                            priceId: priceData.id,
                                            valueIncludingTax: match.valueIncludingTax,
                                            valueExcludingTax: valueExcludingTax
                                          };
                                  }
                                  
                                }));
                  })
              });
        }), [prices]);
  var onRequestPriceUpdate = React.useCallback((function (priceId) {
          return function (newRawValue) {
            return Curry._1(setPrices, (function (prices) {
                          return Belt_Array.map(prices, (function (param) {
                                        return retailPriceUpdater(param, priceId, newRawValue, taxRate);
                                      }));
                        }));
          };
        }), []);
  var columns = React.useMemo((function () {
          return tableColumns(purchasePrice, capacityUnit !== undefined && bulk ? capacityUnit : undefined, onRequestPriceUpdate);
        }), [
        purchasePrice,
        capacityUnit,
        bulk
      ]);
  return React.createElement(TableLayoutPanel.make, {
              title: $$Intl.t("Retail price"),
              description: $$Intl.t("Enter the retail prices for each of your price lists."),
              children: queryResults.data !== undefined ? React.createElement(TableView.make, {
                      data: {
                        TAG: /* Done */1,
                        _0: {
                          TAG: /* Ok */0,
                          _0: prices
                        }
                      },
                      columns: columns,
                      keyExtractor: keyExtractor
                    }) : (
                  queryResults.loading ? React.createElement(Placeholder.make, {
                          status: /* Loading */0
                        }) : React.createElement(Placeholder.make, {
                          status: /* Error */2
                        })
                )
            });
}

var make = React.memo(CatalogVariantEditFormRetailPriceTablePanel);

export {
  Query ,
  Row ,
  tableColumns ,
  retailPriceUpdater ,
  make ,
}
/* query Not a pure module */
