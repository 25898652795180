// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Navigation from "../../primitives/Navigation.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as OrderStatus from "./OrderStatus.bs.js";
import * as OrderEditForm from "./OrderEditForm.bs.js";
import * as OrderMutations from "./OrderMutations.bs.js";

function OrderPageActions(Props) {
  var id = Props.id;
  var statuses = Props.statuses;
  var edition = Props.edition;
  var onRequestCancelEdition = Props.onRequestCancelEdition;
  var match = Navigation.useGoBack(undefined);
  var onGoBack = match[1];
  var canGoBack = match[0];
  var action = OrderStatus.toAction(statuses);
  var createOrder = OrderMutations.useCreate(undefined);
  var updateOrder = OrderMutations.useUpdate(id);
  var moveOrder = OrderMutations.useMove(id, action);
  var unarchive = OrderMutations.useUnarchive(id);
  var match$1 = Belt_Array.getExn(statuses, 0);
  if (edition) {
    return React.createElement(Inline.make, {
                children: null,
                space: "small"
              }, React.createElement(OrderEditForm.ResetButton.make, {
                    variation: "neutral",
                    size: "medium",
                    onPress: (function (param) {
                        if (id !== undefined || !canGoBack) {
                          return Curry._1(onRequestCancelEdition, undefined);
                        } else {
                          return Curry._1(onGoBack, undefined);
                        }
                      }),
                    text: $$Intl.t("Discard")
                  }), React.createElement(OrderEditForm.SubmitButton.make, {
                    variation: "success",
                    size: "medium",
                    onSubmit: Belt_Option.isNone(id) ? createOrder : updateOrder,
                    text: $$Intl.t(Belt_Option.isNone(id) ? "Create draft" : "Save")
                  }));
  } else if (match$1 === "ARCHIVED") {
    return React.createElement(Button.make, Button.makeProps($$Intl.t("Unarchive"), "medium", "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(unarchive, undefined);
                    }), undefined, undefined, undefined));
  } else if (match$1 === "DRAFT") {
    return React.createElement(OrderEditForm.SubmitButton.make, {
                variation: "primary",
                size: "medium",
                onSubmit: moveOrder,
                text: $$Intl.t("Finalize")
              });
  } else if (match$1 === "FINALIZED") {
    return React.createElement(OrderEditForm.SubmitButton.make, {
                variation: "primary",
                size: "medium",
                onSubmit: moveOrder,
                text: $$Intl.t("Define as accepted")
              });
  } else if (match$1 === "ACCEPTED") {
    return React.createElement(OrderEditForm.SubmitButton.make, {
                variation: "primary",
                size: "medium",
                onSubmit: moveOrder,
                text: $$Intl.t("Start reception")
              });
  } else if (match$1 === "RECEIVING") {
    return React.createElement(OrderEditForm.SubmitButton.make, {
                variation: "primary",
                size: "medium",
                onSubmit: moveOrder,
                text: $$Intl.t("Finish reception")
              });
  } else if (match$1 === "RECEIVED" && !OrderStatus.has(statuses, "PAID")) {
    return React.createElement(OrderEditForm.SubmitButton.make, {
                variation: "primary",
                size: "medium",
                onSubmit: moveOrder,
                text: $$Intl.t("Define as paid")
              });
  } else {
    return null;
  }
}

var make = React.memo(OrderPageActions);

export {
  make ,
}
/* make Not a pure module */
