// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Box from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth from "../../bundles/Auth/Auth.bs.js";
import * as $$Intl from "../../primitives/Intl.bs.js";
import * as Menu from "../../resources/navigation/Menu.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Stack from "../../resources/layout-and-structure/Stack.bs.js";
import * as React from "react";
import * as Button from "../../resources/actions/Button.bs.js";
import * as Inline from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar from "../../core/Scalar.bs.js";
import * as Select from "../../resources/selection-and-input/Select.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as MenuItem from "../../resources/navigation/MenuItem.bs.js";
import * as TextLink from "../../resources/navigation/TextLink.bs.js";
import * as JsonCodec from "../../primitives/JsonCodec.bs.js";
import * as SearchBar from "../../resources/selection-and-input/SearchBar.bs.js";
import * as Separator from "../../resources/layout-and-structure/Separator.bs.js";
import * as TextStyle from "../../resources/typography/TextStyle.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as ButtonLink from "../../resources/actions/ButtonLink.bs.js";
import * as EmptyState from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as AsyncResult from "../../primitives/AsyncResult.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Illustration from "../../resources/images-and-icons/Illustration.bs.js";
import * as ApolloHelpers from "../../helpers/ApolloHelpers.bs.js";
import * as Client from "@apollo/client";
import * as SupplierStatus from "../../modules/Supplier/SupplierStatus.bs.js";
import * as SupplierCivility from "../../modules/Supplier/SupplierCivility.bs.js";
import * as LegacyResourceList from "../../core/LegacyResourceList.bs.js";
import * as SupplierArchiveModal from "../../modules/Supplier/SupplierArchiveModal.bs.js";
import * as LegacyResourceListPage from "../../core/LegacyResourceListPage.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["query SupplierListPageQuery($after: String, $before: String, $filterBy: InputSuppliersQueryFilter, $first: Int, $last: Int, $search: String)  {\nsuppliers(after: $after, before: $before, filterBy: $filterBy, first: $first, last: $last, search: $search)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \narchivedAt  \ncompanyName  \ncontacts  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nfirstName  \nlastName  \ncivility  \n}\n\n}\n\n}\n\nemail  \nphoneNumber  \nformattedOrdersTotalAmountIncludingTaxes  \nshop  {\n__typename  \nname  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.suppliers;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          suppliers: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            totalCount: value$1.totalCount,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.archivedAt;
                    var value$3 = value$1.contacts;
                    var value$4 = value$3.edges;
                    var value$5 = value$1.email;
                    var value$6 = value$1.phoneNumber;
                    var value$7 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              archivedAt: !(value$2 == null) ? Caml_option.some(Scalar.Datetime.parse(value$2)) : undefined,
                              companyName: value$1.companyName,
                              contacts: {
                                __typename: value$3.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        var value$2 = value$1.firstName;
                                        var value$3 = value$1.civility;
                                        var tmp;
                                        if (value$3 == null) {
                                          tmp = undefined;
                                        } else {
                                          var tmp$1;
                                          switch (value$3) {
                                            case "MR" :
                                                tmp$1 = "MR";
                                                break;
                                            case "MRS" :
                                                tmp$1 = "MRS";
                                                break;
                                            case "NEUTRAL" :
                                                tmp$1 = "NEUTRAL";
                                                break;
                                            default:
                                              throw {
                                                    RE_EXN_ID: "Not_found",
                                                    Error: new Error()
                                                  };
                                          }
                                          tmp = tmp$1;
                                        }
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  firstName: !(value$2 == null) ? value$2 : undefined,
                                                  lastName: value$1.lastName,
                                                  civility: tmp
                                                }
                                              };
                                      }), value$4)
                              },
                              email: !(value$5 == null) ? value$5 : undefined,
                              phoneNumber: !(value$6 == null) ? value$6 : undefined,
                              formattedOrdersTotalAmountIncludingTaxes: value$1.formattedOrdersTotalAmountIncludingTaxes,
                              shop: {
                                __typename: value$7.__typename,
                                name: value$7.name
                              }
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.suppliers;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.name;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            name: value$3
          };
          var value$5 = value$1.formattedOrdersTotalAmountIncludingTaxes;
          var value$6 = value$1.phoneNumber;
          var phoneNumber = value$6 !== undefined ? value$6 : null;
          var value$7 = value$1.email;
          var email = value$7 !== undefined ? value$7 : null;
          var value$8 = value$1.contacts;
          var value$9 = value$8.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.civility;
                  var civility = value$2 !== undefined ? (
                      value$2 === "MR" ? "MR" : (
                          value$2 === "MRS" ? "MRS" : "NEUTRAL"
                        )
                    ) : null;
                  var value$3 = value$1.lastName;
                  var value$4 = value$1.firstName;
                  var firstName = value$4 !== undefined ? value$4 : null;
                  var value$5 = value$1.__typename;
                  var node = {
                    __typename: value$5,
                    firstName: firstName,
                    lastName: value$3,
                    civility: civility
                  };
                  var value$6 = value.__typename;
                  return {
                          __typename: value$6,
                          node: node
                        };
                }), value$9);
          var value$10 = value$8.__typename;
          var contacts = {
            __typename: value$10,
            edges: edges
          };
          var value$11 = value$1.companyName;
          var value$12 = value$1.archivedAt;
          var archivedAt = value$12 !== undefined ? Scalar.Datetime.serialize(Caml_option.valFromOption(value$12)) : null;
          var value$13 = value$1.id;
          var value$14 = value$1.__typename;
          var node = {
            __typename: value$14,
            id: value$13,
            archivedAt: archivedAt,
            companyName: value$11,
            contacts: contacts,
            email: email,
            phoneNumber: phoneNumber,
            formattedOrdersTotalAmountIncludingTaxes: value$5,
            shop: shop
          };
          var value$15 = value.__typename;
          return {
                  __typename: value$15,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.totalCount;
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var suppliers = {
    __typename: value$8,
    pageInfo: pageInfo,
    totalCount: value$3,
    edges: edges
  };
  return {
          suppliers: suppliers
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputSuppliersQueryFilter(inp) {
  var a = inp.archived;
  var a$1 = inp.shopIds;
  return {
          archived: a !== undefined ? (
              a === "ONLY" ? "ONLY" : (
                  a === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          shopIds: a$1 !== undefined ? serializeInputObjectInFilter(a$1) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.after;
  var a$1 = inp.before;
  var a$2 = inp.filterBy;
  var a$3 = inp.first;
  var a$4 = inp.last;
  var a$5 = inp.search;
  return {
          after: a !== undefined ? a : undefined,
          before: a$1 !== undefined ? a$1 : undefined,
          filterBy: a$2 !== undefined ? serializeInputObjectInputSuppliersQueryFilter(a$2) : undefined,
          first: a$3 !== undefined ? a$3 : undefined,
          last: a$4 !== undefined ? a$4 : undefined,
          search: a$5 !== undefined ? a$5 : undefined
        };
}

function makeVariables(after, before, filterBy, first, last, search, param) {
  return {
          after: after,
          before: before,
          filterBy: filterBy,
          first: first,
          last: last,
          search: search
        };
}

function makeInputObjectInputSuppliersQueryFilter(archived, shopIds, param) {
  return {
          archived: archived,
          shopIds: shopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          after: undefined,
          before: undefined,
          filterBy: undefined,
          first: undefined,
          last: undefined,
          search: undefined
        };
}

var SupplierListQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputSuppliersQueryFilter: serializeInputObjectInputSuppliersQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputSuppliersQueryFilter: makeInputObjectInputSuppliersQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var SupplierListQuery_refetchQueryDescription = include.refetchQueryDescription;

var SupplierListQuery_useLazy = include.useLazy;

var SupplierListQuery_useLazyWithVariables = include.useLazyWithVariables;

var SupplierListQuery = {
  SupplierListQuery_inner: SupplierListQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputSuppliersQueryFilter: serializeInputObjectInputSuppliersQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputSuppliersQueryFilter: makeInputObjectInputSuppliersQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: SupplierListQuery_refetchQueryDescription,
  use: use,
  useLazy: SupplierListQuery_useLazy,
  useLazyWithVariables: SupplierListQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["mutation unarchiveSupplier($id: ID!)  {\nunarchiveSupplier(id: $id)  {\n__typename  \nid  \narchivedAt  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.unarchiveSupplier;
  var value$2 = value$1.archivedAt;
  return {
          unarchiveSupplier: {
            __typename: value$1.__typename,
            id: value$1.id,
            archivedAt: !(value$2 == null) ? Caml_option.some(Scalar.Datetime.parse(value$2)) : undefined
          }
        };
}

function serialize$1(value) {
  var value$1 = value.unarchiveSupplier;
  var value$2 = value$1.archivedAt;
  var archivedAt = value$2 !== undefined ? Scalar.Datetime.serialize(Caml_option.valFromOption(value$2)) : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var unarchiveSupplier = {
    __typename: value$4,
    id: value$3,
    archivedAt: archivedAt
  };
  return {
          unarchiveSupplier: unarchiveSupplier
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$1(id, param) {
  return {
          id: id
        };
}

var UnarchiveSupplierMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var UnarchiveSupplierMutation_useWithVariables = include$1.useWithVariables;

var UnarchiveSupplierMutation = {
  UnarchiveSupplierMutation_inner: UnarchiveSupplierMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  use: use$1,
  useWithVariables: UnarchiveSupplierMutation_useWithVariables
};

function jsonCodec(shops) {
  return JsonCodec.object2((function (param) {
                return [
                        Belt_Option.map(param.shop, (function (shop) {
                                return shop.id;
                              })),
                        SupplierStatus.toString(param.status)
                      ];
              }), (function (param) {
                var shopId = param[0];
                return {
                        TAG: /* Ok */0,
                        _0: {
                          shop: Belt_Array.getBy(shops, (function (shop) {
                                  return shop.id === shopId;
                                })),
                          status: SupplierStatus.fromString(param[1])
                        }
                      };
              }), JsonCodec.optional(JsonCodec.field("shopId", JsonCodec.string)), JsonCodec.field("status", JsonCodec.string));
}

var SupplierListFilters = {
  jsonCodec: jsonCodec
};

function keyExtractor(value) {
  return value.id;
}

var SupplierTableRow = {
  keyExtractor: keyExtractor
};

function SupplierListPage$SupplierActionsCell(Props) {
  var id = Props.id;
  var status = Props.status;
  var supplierShowRoute = Props.supplierShowRoute;
  var supplierBaseRoute = Props.supplierBaseRoute;
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var unarchiveSupplier = match[0];
  var unarchiveAction = {
    TAG: /* Callback */0,
    _0: (function (param) {
        Curry._8(unarchiveSupplier, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
              id: id
            });
      })
  };
  var match$1 = React.useState(function () {
        return false;
      });
  var setArchiveModalOpened = match$1[1];
  var archiveAction = {
    TAG: /* Callback */0,
    _0: (function (param) {
        Curry._1(setArchiveModalOpened, (function (param) {
                return true;
              }));
      })
  };
  var seeAction = {
    TAG: /* OpenLink */1,
    _0: {
      TAG: /* Route */0,
      _0: Curry._1(supplierShowRoute, id)
    }
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Inline.make, {
                  children: React.createElement(Menu.make, {
                        children: null,
                        variation: "more_round"
                      }, React.createElement(MenuItem.make, {
                            content: {
                              TAG: /* Text */0,
                              _0: $$Intl.t("See")
                            },
                            action: seeAction
                          }), status === "UNARCHIVED" ? React.createElement(MenuItem.make, {
                              content: {
                                TAG: /* Text */0,
                                _0: $$Intl.t("Archive")
                              },
                              action: archiveAction
                            }) : React.createElement(MenuItem.make, {
                              content: {
                                TAG: /* Text */0,
                                _0: $$Intl.t("Unarchive")
                              },
                              action: unarchiveAction
                            })),
                  align: "end"
                }), React.createElement(SupplierArchiveModal.make, {
                  id: id,
                  opened: match$1[0],
                  onRequestClose: (function (param) {
                      Curry._1(setArchiveModalOpened, (function (param) {
                              return false;
                            }));
                    }),
                  supplierBaseRoute: supplierBaseRoute
                }));
}

var SupplierActionsCell = {
  make: SupplierListPage$SupplierActionsCell
};

function supplierTableRowsFromQueryResult(queryResult) {
  return Belt_Array.map(queryResult.suppliers.edges, (function (edge) {
                var match = edge.node.archivedAt;
                var match$1 = edge.node.contacts.edges;
                var tmp;
                if (match$1.length !== 1) {
                  tmp = undefined;
                } else {
                  var edge$1 = match$1[0];
                  tmp = edge$1.node.firstName;
                }
                var match$2 = edge.node.contacts.edges;
                var tmp$1;
                if (match$2.length !== 1) {
                  tmp$1 = "-";
                } else {
                  var edge$2 = match$2[0];
                  tmp$1 = edge$2.node.lastName;
                }
                var match$3 = edge.node.contacts.edges;
                var tmp$2;
                if (match$3.length !== 1) {
                  tmp$2 = undefined;
                } else {
                  var edge$3 = match$3[0];
                  tmp$2 = edge$3.node.civility;
                }
                return {
                        id: edge.node.id,
                        archivedAt: edge.node.archivedAt,
                        status: match !== undefined ? "ARCHIVED" : "UNARCHIVED",
                        companyName: edge.node.companyName,
                        shopName: edge.node.shop.name,
                        phoneNumber: edge.node.phoneNumber,
                        email: edge.node.email,
                        firstName: tmp,
                        lastName: tmp$1,
                        civility: tmp$2,
                        formattedOrdersTotalAmountIncludingTaxes: edge.node.formattedOrdersTotalAmountIncludingTaxes
                      };
              }));
}

function suppliersQueryVariablesFilterBy(param) {
  var shop = param.shop;
  return {
          archived: param.status === "UNARCHIVED" ? "EXCLUDED" : "ONLY",
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined
        };
}

function suppliersQueryVariables(param) {
  var connectionArguments = param.connectionArguments;
  return {
          after: connectionArguments.after,
          before: connectionArguments.before,
          filterBy: suppliersQueryVariablesFilterBy(param.filters),
          first: connectionArguments.first,
          last: connectionArguments.last,
          search: param.searchQuery
        };
}

function SupplierListPage(Props) {
  var supplierBaseRoute = Props.supplierBaseRoute;
  var supplierNewRoute = Props.supplierNewRoute;
  var supplierShowRoute = Props.supplierShowRoute;
  var shops = Auth.useShops(undefined);
  var activeShop = Auth.useActiveShop(undefined);
  var initialFilters = {
    shop: activeShop,
    status: "UNARCHIVED"
  };
  var initialState = LegacyResourceList.initialState(initialFilters);
  var filtersJsonCodec = jsonCodec(shops);
  var resourceListPropState = LegacyResourceList.use(initialState, filtersJsonCodec);
  var dispatch = resourceListPropState[1];
  var state = resourceListPropState[0];
  var queryAsyncResult = ApolloHelpers.queryResultToAsyncResult(Curry.app(use, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            suppliersQueryVariables(state)
          ]));
  var onRequestPaginate = React.useCallback((function (action) {
          var startCursor;
          var endCursor;
          var totalCount;
          if (typeof queryAsyncResult === "number") {
            return ;
          }
          if (queryAsyncResult.TAG === /* Reloading */0) {
            var match = queryAsyncResult._0;
            if (match.TAG !== /* Ok */0) {
              return ;
            }
            var match$1 = match._0.suppliers;
            var match$2 = match$1.pageInfo;
            startCursor = match$2.startCursor;
            endCursor = match$2.endCursor;
            totalCount = match$1.totalCount;
          } else {
            var match$3 = queryAsyncResult._0;
            if (match$3.TAG !== /* Ok */0) {
              return ;
            }
            var match$4 = match$3._0.suppliers;
            var match$5 = match$4.pageInfo;
            startCursor = match$5.startCursor;
            endCursor = match$5.endCursor;
            totalCount = match$4.totalCount;
          }
          var totalPages = LegacyResourceList.totalPages(totalCount, LegacyResourceList.edgesPerPage);
          var nextPage = LegacyResourceList.nextPage(state, action, totalPages);
          var cursors = [
            startCursor,
            endCursor
          ];
          if (nextPage !== undefined) {
            return Curry._1(dispatch, {
                        TAG: /* Navigated */0,
                        nextPage: nextPage,
                        totalCount: totalCount,
                        cursors: cursors
                      });
          }
          
        }), [
        state.currentPage,
        queryAsyncResult
      ]);
  var tableColumns = [
    {
      key: "company-name",
      name: $$Intl.t("Company name"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 200
        },
        width: {
          NAME: "pct",
          VAL: 25
        },
        sticky: true
      },
      render: (function (param) {
          var match = param.data;
          var shopName = shops.length > 1 ? match.shopName : undefined;
          return React.createElement(Box.make, Box.makeProps(Caml_option.some(React.createElement(Stack.make, {
                                  children: null,
                                  space: Belt_Option.isSome(shopName) ? "xxsmall" : "none"
                                }, React.createElement(TextLink.make, {
                                      text: match.companyName,
                                      to: {
                                        TAG: /* Route */0,
                                        _0: Curry._1(supplierShowRoute, match.id)
                                      }
                                    }), shopName !== undefined ? React.createElement(TextStyle.make, {
                                        children: shopName,
                                        variation: "normal",
                                        size: "xxsmall"
                                      }) : null)), undefined, "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        })
    },
    {
      key: "contact",
      name: $$Intl.t("Contact"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 115
        },
        width: {
          NAME: "fr",
          VAL: 1.2
        }
      },
      render: (function (param) {
          var match = param.data;
          var text = Belt_Array.joinWith(Belt_Array.keepMap([
                    Belt_Option.map(match.civility, SupplierCivility.toShortLabel),
                    match.firstName,
                    match.lastName
                  ], (function (maybeString) {
                      return maybeString;
                    })), " ", (function (a) {
                  return a;
                }));
          return React.createElement(TextStyle.make, {
                      children: text
                    });
        })
    },
    {
      key: "email",
      name: $$Intl.t("Email"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 160
        },
        width: {
          NAME: "fr",
          VAL: 1.5
        }
      },
      render: (function (param) {
          return React.createElement(TextStyle.make, {
                      children: Belt_Option.getWithDefault(param.data.email, "-")
                    });
        })
    },
    {
      key: "phone",
      name: $$Intl.t("Phone"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 135
        }
      },
      render: (function (param) {
          return React.createElement(TextStyle.make, {
                      children: Belt_Option.getWithDefault(param.data.phoneNumber, "-")
                    });
        })
    },
    {
      key: "total-purchases-incl",
      name: $$Intl.t("Total purchases incl."),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 135
        }
      },
      render: (function (param) {
          var amount = Belt_Float.fromString(param.data.formattedOrdersTotalAmountIncludingTaxes);
          return React.createElement(TextStyle.make, {
                      children: amount !== undefined ? $$Intl.currencyFormat("EUR", 3, 3, amount) : "0"
                    });
        })
    },
    {
      key: "actions",
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 70
        },
        width: {
          NAME: "px",
          VAL: 70
        }
      },
      render: (function (param) {
          var match = param.data;
          return React.createElement(SupplierListPage$SupplierActionsCell, {
                      id: match.id,
                      status: match.status,
                      supplierShowRoute: supplierShowRoute,
                      supplierBaseRoute: supplierBaseRoute
                    });
        })
    }
  ];
  var tmp;
  if (shops.length > 1) {
    var tmp$1 = {
      onChange: (function (shop) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */2,
                _0: (function (prev) {
                    return {
                            shop: shop,
                            status: prev.status
                          };
                  })
              });
        })
    };
    if (state.filters.shop !== undefined) {
      tmp$1.value = Caml_option.valFromOption(state.filters.shop);
    }
    tmp = React.createElement(Auth.SelectShopFilter.make, tmp$1);
  } else {
    tmp = null;
  }
  var items = Belt_Array.map(SupplierStatus.values, (function (value) {
          return {
                  key: SupplierStatus.toString(value),
                  value: value,
                  label: SupplierStatus.toLabel(value)
                };
        }));
  var match = state.filters.status;
  var filters = React.createElement(Inline.make, {
        children: null,
        space: "small"
      }, tmp, shops.length > 1 ? React.createElement(Separator.make, {}) : null, React.createElement(Select.make, {
            label: $$Intl.t("Status"),
            sections: [{
                items: items,
                title: $$Intl.t("Statuses")
              }],
            preset: "filter",
            value: state.filters.status,
            onChange: (function (status) {
                Curry._1(dispatch, {
                      TAG: /* FiltersUpdated */2,
                      _0: (function (prev) {
                          return {
                                  shop: prev.shop,
                                  status: Belt_Option.getExn(status)
                                };
                        })
                    });
              })
          }), match === "ARCHIVED" ? React.createElement(LegacyResourceListPage.ResetFiltersButton.make, {
              onPress: (function (param) {
                  Curry._1(dispatch, {
                        TAG: /* FiltersUpdated */2,
                        _0: (function (param) {
                            return initialState.filters;
                          })
                      });
                })
            }) : null);
  var actions = React.createElement(ButtonLink.make, {
        children: $$Intl.t("Create supplier"),
        variation: "primary",
        to: {
          TAG: /* Route */0,
          _0: supplierNewRoute
        }
      });
  var tmp$2 = {
    placeholder: $$Intl.t("Search a supplier"),
    onChange: (function (searchQuery) {
        Curry._1(dispatch, {
              TAG: /* Searched */1,
              _0: searchQuery
            });
      })
  };
  if (state.searchQuery !== undefined) {
    tmp$2.value = state.searchQuery;
  }
  var searchBar = React.createElement(SearchBar.make, tmp$2);
  var tableEmptyState;
  var exit = 0;
  if (state.currentPage !== 1) {
    exit = 1;
  } else {
    var match$1 = state.searchQuery;
    if (match$1 === "") {
      var match$2 = state.filters;
      if (match$2.status === "UNARCHIVED" && match$2.shop === activeShop) {
        tableEmptyState = React.createElement(EmptyState.make, {
              illustration: Illustration.create,
              title: $$Intl.t("Welcome to the suppliers space.")
            });
      } else {
        exit = 1;
      }
    } else {
      exit = 1;
    }
  }
  if (exit === 1) {
    tableEmptyState = React.createElement(EmptyState.make, {
          illustration: Illustration.notFound,
          title: $$Intl.t("No result were found."),
          text: $$Intl.t("Try again with another keyword/filter or:"),
          children: React.createElement(Button.make, Button.makeProps($$Intl.t("Clear search query and filters"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Reset */3,
                            _0: initialState
                          });
                    }), undefined, undefined, undefined))
        });
  }
  var paginationCurrentPage = state.currentPage;
  var paginationTotalPages;
  if (typeof queryAsyncResult === "number" || queryAsyncResult.TAG !== /* Done */1) {
    paginationTotalPages = 1;
  } else {
    var match$3 = queryAsyncResult._0;
    paginationTotalPages = match$3.TAG === /* Ok */0 ? LegacyResourceList.totalPages(match$3._0.suppliers.totalCount, LegacyResourceList.edgesPerPage) : 1;
  }
  var tableData = AsyncResult.mapError(AsyncResult.mapOk(queryAsyncResult, supplierTableRowsFromQueryResult), (function (param) {
          
        }));
  return React.createElement(LegacyResourceListPage.make, {
              title: $$Intl.t("Suppliers"),
              filters: filters,
              actions: actions,
              searchBar: searchBar,
              tableData: tableData,
              tableColumns: tableColumns,
              tableKeyExtractor: keyExtractor,
              tableEmptyState: tableEmptyState,
              paginationCurrentPage: paginationCurrentPage,
              paginationTotalPages: paginationTotalPages,
              onRequestPaginate: onRequestPaginate
            });
}

var make = SupplierListPage;

export {
  SupplierListQuery ,
  UnarchiveSupplierMutation ,
  SupplierListFilters ,
  SupplierTableRow ,
  SupplierActionsCell ,
  supplierTableRowsFromQueryResult ,
  suppliersQueryVariablesFilterBy ,
  suppliersQueryVariables ,
  make ,
}
/* query Not a pure module */
